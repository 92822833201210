import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import { ToggleChevron } from './ToggleChevron';
import { FilterIcon } from './FilterIcon';
import { ClearIcon } from './ClearIcon';
import FilterOption from './FilterOption';
import { FilterModel } from '../../../model/FilterModel';
import classnames from 'classnames';
import { IFilterGroup } from '../../../Interfaces/IFilterGroup';

interface IFilterRowProps {
  filterGroups: Array<IFilterGroup>;
  onFilterSelection: (
    filterState: Array<IFilterGroup>,
    hasActiveFilter: boolean
  ) => void;
}

const FilterSection = (props: IFilterRowProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hasActiveFilter, setHasActiveFilter] = useState<boolean>(
    props.filterGroups?.some(fg => fg.items.some(i => i.isSelected))
  );

  const getFilterGroupForSelectedFilter = (
    newFilterGroups: Array<IFilterGroup>,
    filter: FilterModel
  ): IFilterGroup | undefined => {
    return newFilterGroups.find(fg =>
      fg.items.some(i => i.label === filter.label)
    );
  };

  const setIsSelectedOnSelectedFilterItem = (
    filterGroup: IFilterGroup,
    filter: FilterModel
  ) => {
    filterGroup.items.forEach(
      i => i.label === filter.label && (i.isSelected = !i.isSelected)
    );
  };

  const onFilterSelected = (filter: FilterModel) => {
    const newFilterGroups = [...props.filterGroups];

    const filterGroup = getFilterGroupForSelectedFilter(
      newFilterGroups,
      filter
    );
    if (filterGroup) {
      setIsSelectedOnSelectedFilterItem(filterGroup, filter);
    }
    const hasActiveFilter = newFilterGroups.some(fg =>
      fg.items.some(i => i.isSelected)
    );
    setHasActiveFilter(hasActiveFilter);
    props.onFilterSelection(newFilterGroups, hasActiveFilter);
  };

  const clearFilterGroups = () => {
    setHasActiveFilter(false);
    var clearFilterGroups = props.filterGroups.map(deselectAllItems);
    props.onFilterSelection(clearFilterGroups, false);
  };

  const deselectAllItems = (filterGroup: IFilterGroup) => {
    return {
      title: filterGroup.title,
      expandToFillSpace: filterGroup.expandToFillSpace,
      items: filterGroup.items.map(i => ({ ...i, isSelected: false }))
    };
  };

  return (
    <section className="px-2">
      <div className="d-flex flex-nowrap border-bottom mb-2 pb-1">
        <div
          className="flex-fill btn p-0 text-left"
          onClick={() => setIsOpen(!isOpen)}
        >
          {ToggleChevron(isOpen)}
        </div>

        <span className="btn p-0 ml-auto" onClick={clearFilterGroups}>
          {hasActiveFilter && <>{ClearIcon()} &nbsp; Clear </>}
        </span>
        <span className="ml-4 btn p-0" onClick={() => setIsOpen(!isOpen)}>
          {FilterIcon(hasActiveFilter)} Filter
        </span>
      </div>

      <Collapse isOpen={isOpen}>
        <div
          id="filter-row"
          className="d-flex flex-row flex-nowrap justify-content-end sm-pad"
        >
          {props.filterGroups
            .filter(fg => fg.items.length > 1)
            .map((fg, i) => (
              <ul
                key={fg.title}
                className={classnames(
                  { 'pl-0': i === 0 },
                  { 'pl-5': i !== 0 },
                  {
                    'flex-grow-1': fg.expandToFillSpace
                  }
                )}
              >
                {fg.title}
                {fg.items.map(i => (
                  <FilterOption
                    optionName={i.label}
                    onSelected={onFilterSelected}
                    isSelected={i.isSelected}
                    key={`${i.label}-${i.isSelected}`}
                  />
                ))}
              </ul>
            ))}
        </div>
      </Collapse>
    </section>
  );
};

export default FilterSection;

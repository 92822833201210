import React from 'react';
import { PatientSupportSpecialistLabel } from '../PatientSupportSpecialistLabel';
import { isValidFilePath } from '../../../utilities/JsHelpers';
import { defaultCaseMngrImgName } from '../../../constants/StringConstants';
import ImgWithFallback from '../ImgWithFallback';

const CaseManager = props => {
  const basePath = process.env.PUBLIC_URL + '/case-managers/';
  const fallback = `${basePath}${defaultCaseMngrImgName}`;
  const imgPath = `${basePath}${props.caseManagerDetails.photoFileName}`;
  const acceptableExtensions = ['.jpg', '.jpeg'];
  const hasValidImgPath =
    props.caseManagerDetails !== null &&
    isValidFilePath(
      props.caseManagerDetails.photoFileName,
      acceptableExtensions
    );
  return (
    <div className="media">
      <div className="media-left">
        <div className="initial-bubble">
          <ImgWithFallback
            primaryImgSrc={hasValidImgPath ? imgPath : fallback}
            className="case-manager-pic"
            fallbackImgSrc={fallback}
            altText="CaseMangerPicture"
          />
        </div>
      </div>
      <div className="media-body">
        <h4 className="media-heading">
          {props.caseManagerDetails.firstName}{' '}
          {props.caseManagerDetails.lastName.charAt(0)}.
        </h4>
        <p>
          <PatientSupportSpecialistLabel />
          <br />
        </p>
      </div>
    </div>
  );
};

export default CaseManager;

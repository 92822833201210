import React from 'react';
import { Button, Input, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import TermsModalBody from './TermsModalBody';
import api from '../../api';
import { AuthServiceCognito } from '../../utilities/AuthServiceCognito';
import { error } from '../../utilities/ErrorHandler';

class TermsModal extends React.Component {
  static pointerStyle = {
    cursor: 'pointer'
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.isOpen,
      value: true
    };
    this.onChange = this.onChange.bind(this);
  }

  handleLogout() {
    const auth = new AuthServiceCognito();
    auth.logout().catch(err => error(err));
    window.location = window.location.origin;
  }

  acceptTerms = () => {
    api.vertex
      .acceptTerms()
      .then(res => {
        this.toggle();
        this.props.onAccept(true);
      })
      .catch(err => error(err));
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  onChange(e) {
    this.setState({ value: !e.target.checked });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.includeLink ? (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a href="#" onClick={this.toggle} style={TermsModal.pointerStyle}>
            Terms&nbsp;of&nbsp;Use
          </a>
        ) : null}

        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="terms-modal modal-lg"
          backdrop={this.props.backdrop}
        >
          {this.props.headerToggle ? (
            <ModalHeader toggle={this.toggle}>Terms of use</ModalHeader>
          ) : (
            <ModalHeader>Terms of use</ModalHeader>
          )}
          <TermsModalBody />
          {this.props.includeFooter ? (
            <ModalFooter>
              <div className="custom-control custom-checkbox">
                <Input
                  className="custom-control-input"
                  id="Acknowledgment"
                  name="Terms of USe"
                  component="input"
                  type="checkbox"
                  onChange={this.onChange}
                  value="true"
                />{' '}
                <label
                  className="custom-control-label"
                  htmlFor="Acknowledgment"
                >
                  I agree to the Terms of Use
                </label>
              </div>
              <div className="buttons">
                <Button color="link" onClick={this.handleLogout}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onClick={this.acceptTerms}
                  disabled={this.state.value}
                >
                  Continue
                </Button>{' '}
              </div>
            </ModalFooter>
          ) : null}
        </Modal>
      </React.Fragment>
    );
  }
}

export default TermsModal;

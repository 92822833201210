import React from 'react';
import { Form, Field } from 'react-final-form';
import { Accordion } from 'react-bootstrap';
import AccordionCard from './pinFormParts/AccordionCard';
import { isNullOrUndefined } from '../../utilities/JsHelpers';
import PrescriberPinOptionBody from './pinFormParts/PrescriberPinOptionBody';
import SignedEnrollmentFormOption from './pinFormParts/SignedEnrollmentFormOption';
import SignedFormOption from './pinFormParts/SignedFormOption';
import { pinForm as strings } from '../../constants/StringConstants';

const PinForm = props => {
  return (
    <Form
      onSubmit={props.submitHandler}
      render={({ submitError, handleSubmit, form }) => {
        const handleSetNewOptionSelected = (
          optionNumberClicked,
          optionName
        ) => {
          const { values } = form.getState();
          const patientConsent = values && values.excludePatientConsent;
          form.reset({
            excludePatientConsent: patientConsent
          }); // Vertex asked to reset the form fields whenever the selected accordion options change

          props.setNewOptionSelected(optionNumberClicked, optionName);
        };

        return (
          <form className="wizard nested col-12" onSubmit={handleSubmit}>
            <h1>
              Choose 1 of the 3 options for how the Prescription will be handled
            </h1>
            <br />
            <div className="prescription-options">
              <Accordion>
                <AccordionCard
                  data-testid="accordian-pin"
                  optionSelected={props.optionSelected}
                  cardOption={0}
                  cardName={'prescriberPin'}
                  optionHeader={strings.option1Header}
                  optionDescription={strings.option1Description}
                  cardBody={
                    <PrescriberPinOptionBody submitError={submitError} />
                  }
                  setOptionSelected={handleSetNewOptionSelected}
                />
                <AccordionCard
                  optionSelected={props.optionSelected}
                  cardOption={1}
                  cardName={'signedEnrollment'}
                  optionHeader={strings.option2Header}
                  optionDescription={strings.option2Description}
                  cardBody={
                    <SignedEnrollmentFormOption submitError={submitError} />
                  }
                  setOptionSelected={handleSetNewOptionSelected}
                />
                <AccordionCard
                  optionSelected={props.optionSelected}
                  cardOption={2}
                  cardName={'signedForm'}
                  optionHeader={strings.option3Header}
                  optionDescription={strings.option3Description}
                  cardBody={<SignedFormOption submitError={submitError} />}
                  setOptionSelected={handleSetNewOptionSelected}
                />
              </Accordion>
            </div>
            {props.patientEmail ? (
              <div>
                <h1 className="mt-5">Patient Consent</h1>
                <br />
                <div className="col-xs-12 col-lg-12 mt-3">
                  <div className="notify">
                    <div className="notify__item notify__item--aware">
                      <div className="custom-control custom-checkbox">
                        <Field
                          name="excludePatientConsent"
                          data-testid="patient-consent-chk"
                          className="custom-control-input"
                          type="checkbox"
                          id="excludePatientConsent"
                          component="input"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="excludePatientConsent"
                        >
                          Patient consent has been obtained and will be uploaded
                          to the patient summary screen or faxed into GPS. If
                          this box is left unchecked, an email will go to the
                          patient for their eSignature. Checking this box will
                          not send an email to the patient.
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="buttons col-12 text-right">
              <button
                data-testid="complete-enrollment"
                className="btn btn-primary"
                type="submit"
                disabled={isNullOrUndefined(props.optionSelected)}
              >
                {strings.submitBtn}
              </button>
            </div>
          </form>
        );
      }}
    />
  );
};

export default PinForm;

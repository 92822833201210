import React from 'react';
import PropTypes from 'prop-types';
import BubbleTrainItem from './BubbleTrainItem';

const propTypes = {
  items: PropTypes.array.isRequired
};

class BubbleTrain extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.items.map(function(v, i) {
          return (
            <BubbleTrainItem
              type={v.type}
              status={v.status}
              icon={v.icon}
              label={v.label}
              smallLabel={v.smallLabel}
              key={i}
            />
          );
        })}
      </React.Fragment>
    );
  }
}

BubbleTrain.propTypes = propTypes;

export default BubbleTrain;

import React from 'react';
import {
  CheckIcon,
  EllipsisHorizontalIcon,
  CloseIcon,
  CircleOutlineIcon,
  HelpIcon,
  AlertIcon
} from 'mdi-react';
import BubbleTrain from '../components/parts/BubbleTrain';
import { isTruthy, getFormattedDate } from '../utilities/JsHelpers';

const defaultBIStatus = 'BI in Process';
const errorBIStatus = 'BI Error';
const BIMosaicErrorLabel = 'unknown';
const PAStatusErrorLabel = 'unknown';
const errorPAStatus = 'PA Error';
const BIStatusMap = new Map([
  [
    'BI in Process',
    buildBubble(
      'processing',
      'BI Status',
      'BI in Process',
      '',
      <EllipsisHorizontalIcon size="2.625em" />
    )
  ],
  [
    'BI Complete',
    buildBubble(
      'approved',
      'BI Status',
      'BI Complete',
      '',
      <CheckIcon size="2.625em" />
    )
  ],
  [
    'BI Cancelled',
    buildBubble(
      'approved',
      'BI Status',
      'BI Cancelled',
      '',
      <CloseIcon size="2.625em" />
    )
  ],
  [
    'BI Error',
    buildBubble(
      'denied',
      'BI Status',
      BIMosaicErrorLabel,
      '',
      <AlertIcon size="2.625em" />
    )
  ]
]);

const PAStatusMap = new Map([
  [
    'PA in Process',
    buildBubble(
      'processing',
      'PA Status',
      'PA in Process',
      '',
      <EllipsisHorizontalIcon size="2.625em" />
    )
  ],
  [
    'PA Information Required',
    buildBubble(
      'denied',
      'PA Status',
      'PA Information Required',
      '',
      <HelpIcon className="help-icon" size="2em" />
    )
  ],
  [
    'PA Approved',
    buildBubble(
      'approved',
      'PA Status',
      'PA Approved',
      '',
      <CheckIcon size="2.625em" />
    )
  ],
  [
    'PA Denied',
    buildBubble(
      'approved',
      'PA Status',
      'PA Denied',
      '',
      <CloseIcon size="2.625em" />
    )
  ],
  [
    'PA Closed',
    buildBubble(
      'approved',
      'PA Status',
      'PA Closed',
      '',
      <CloseIcon size="2.625em" />
    )
  ],
  [
    'PA Cancellation in Process',
    buildBubble(
      'processing',
      'PA Status',
      'PA Cancellation in Process',
      '',
      <EllipsisHorizontalIcon size="2.625em" />
    )
  ],
  [
    'PA Cancelled',
    buildBubble(
      'approved',
      'PA Status',
      'PA Cancelled',
      '',
      <CloseIcon size="2.625em" />
    )
  ],
  [
    'PA Cancellation Denied',
    buildBubble(
      'approved',
      'PA Status',
      'PA Cancellation Denied',
      '',
      <CloseIcon size="2.625em" />
    )
  ],
  [
    'Appeal Information Required',
    buildBubble(
      'denied',
      'PA Status',
      'Appeal Information Required',
      '',
      <HelpIcon className="help-icon" size="2em" />
    )
  ],
  [
    'Appeal in Process',
    buildBubble(
      'processing',
      'PA Status',
      'Appeal in Process',
      '',
      <EllipsisHorizontalIcon size="2.625em" />
    )
  ],
  [
    'Appeal Cancellation in Process',
    buildBubble(
      'processing',
      'PA Status',
      'Appeal Cancellation in Process',
      '',
      <EllipsisHorizontalIcon size="2.625em" />
    )
  ],
  [
    'Appeal Approved',
    buildBubble(
      'approved',
      'PA Status',
      'Appeal Approved',
      '',
      <CheckIcon size="2.625em" />
    )
  ],
  [
    'Appeal Cancelled',
    buildBubble(
      'approved',
      'PA Status',
      'Appeal Cancelled',
      '',
      <CloseIcon size="2.625em" />
    )
  ],
  [
    'Appeal Denied',
    buildBubble(
      'approved',
      'PA Status',
      'Appeal Denied',
      '',
      <CloseIcon size="2.625em" />
    )
  ],
  [
    'Appeal Closed',
    buildBubble(
      'approved',
      'PA Status',
      'Appeal Closed',
      '',
      <CloseIcon size="2.625em" />
    )
  ],
  [
    'Appeal Cancellation Denied',
    buildBubble(
      'approved',
      'PA Status',
      'Appeal Cancellation Denied',
      '',
      <CloseIcon size="2.625em" />
    )
  ],
  [
    null,
    buildBubble(
      'default',
      'PA Status',
      '',
      '',
      <CircleOutlineIcon size="2.625em" />
    )
  ],
  [
    '',
    buildBubble(
      'default',
      'PA Status',
      '',
      '',
      <CircleOutlineIcon size="2.625em" />
    )
  ],
  [
    'PA Unknown',
    buildBubble(
      'default',
      'PA Status',
      '',
      '',
      <CircleOutlineIcon size="2.625em" />
    )
  ],
  [
    'PA Error',
    buildBubble(
      'default',
      'PA Status',
      PAStatusErrorLabel,
      '',
      <CircleOutlineIcon size="2.625em" />
    )
  ]
]);

export function buildBubble(
  sourceStatus,
  sourceType,
  sourceLabel,
  sourceDate,
  sourceIcon
) {
  return (
    <BubbleTrain
      items={[
        {
          status: sourceStatus,
          icon: sourceIcon,
          type: sourceType,
          label: sourceLabel,
          smallLabel: sourceDate
        }
      ]}
    />
  );
}

export function getShippingStatus(mosaicIntegration) {
  return isTruthy(mosaicIntegration) && isTruthy(mosaicIntegration.shippingDate)
    ? buildBubble(
        'submitted',
        'Shipping Status',
        `Shipped ${getFormattedDate(mosaicIntegration.shippingDate)}`,
        '',
        <CheckIcon size="2.625em" />
      )
    : buildBubble(
        'default',
        'Shipping Status',
        '',
        '',
        <EllipsisHorizontalIcon size="2.625em" />
      );
}

export function getPAStatus(priorAuthorization, mosaicIntegration) {
  return isTruthy(priorAuthorization)
    ? PAStatusMap.has(priorAuthorization.displayStatus)
      ? PAStatusMap.get(priorAuthorization.displayStatus)
      : PAStatusMap.get(errorPAStatus)
    : isTruthy(mosaicIntegration)
    ? PAStatusMap.has(mosaicIntegration.paRequestStatus)
      ? PAStatusMap.get(mosaicIntegration.paRequestStatus)
      : PAStatusMap.get(errorPAStatus)
    : PAStatusMap.get(null);
}

export function getBIStatus(mosaicIntegration) {
  return isTruthy(mosaicIntegration)
    ? BIStatusMap.has(mosaicIntegration.biStatus)
      ? BIStatusMap.get(mosaicIntegration.biStatus)
      : BIStatusMap.get(errorBIStatus)
    : BIStatusMap.get(defaultBIStatus);
}

export const hidePAMosaicStatuses = [
  'Appeal Approved',
  'Appeal Cancellation Denied',
  'Appeal Cancellation in Process',
  'Appeal Cancelled',
  'Appeal Closed',
  'Appeal Denied',
  'Appeal in Process',
  'Appeal Information Required',
  'PA Approved',
  'PA Cancellation Denied',
  'PA Cancellation in Process',
  'PA Cancelled',
  'PA Closed',
  'PA Denied',
  'PA in Process',
  'PA Information Required'
];

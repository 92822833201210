import React from 'react';
import { FormGroup, Label, FormText, Input } from 'reactstrap';
import { Field } from 'react-final-form';
import { pinForm } from '../../../constants/StringConstants';

const PrescriberPinOptionBody = ({ submitError }) => {
  return (
    <FormGroup>
      <Label className="text-center">
        Please enter your 4 digit PIN to continue.
      </Label>
      <div className="input-group mt-3 mx-auto col-lg-4 col-sm-8">
        <Field name="prescriberPin.pin">
          {({ input }) => (
            <>
              <Input
                {...input}
                type="password"
                data-testid="prescriber-pin"
                className="w-100 form-control-lg form-control text-center"
                id="pin"
                placeholder="e.g. 1234"
                maxLength="4"
                autoComplete="off"
              />
              {submitError ? (
                <div className="error mt-3 d-block text-center">
                  {pinForm.pinValidationContent.errorTextLine1}&nbsp;
                  <a
                    href={
                      'tel:' + pinForm.pinValidationContent.softwareSupportPhone
                    }
                  >
                    &nbsp;{pinForm.pinValidationContent.softwareSupportPhone}
                  </a>
                  &nbsp;{pinForm.pinValidationContent.errorTextLine2}
                </div>
              ) : null}
            </>
          )}
        </Field>
      </div>

      <FormText className="p-3 mb-3 mx-auto col-lg-4 col-sm-8 text-center">
        Please enter the PIN that was provided and you will be directed to sign
        the Patient Enrollment Form.
      </FormText>
    </FormGroup>
  );
};

export default PrescriberPinOptionBody;

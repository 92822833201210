import logo from '../../img/Vertex_GPS_logo.svg';
import React, { Component } from 'react';
import { Field } from 'react-final-form';
import {
  Container,
  Row,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  FormText
} from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
//import 'react-bootstrap-typeahead/css/Typeahead.css';
//import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import { styleguideCenterData as options } from '../../__mocks__/data/centerData';
import { Link } from 'react-router-dom';
import InputError from '../style_guide/parts/InputError';
//import RenderingExample from '../style_guide/parts/RenderingExample';

class Register extends Component {
  typeaheadProps = {
    bodyContainer: true,
    dropup: true
  };

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  }

  render() {
    return (
      <Container className="register col-xs-12 col-lg-6">
        <img
          src={logo}
          className="logo"
          alt="Vertex GPS | Guidaince and Patient Support"
        />
        <h1>
          <span>Register</span>
        </h1>
        <Form className="form" onSubmit="{submitForm}">
          <Row>
            <Col>
              <FormGroup>
                <label>First Name</label>
                <Input
                  type="text"
                  className="mb-4"
                  name="firstName"
                  id="firstName"
                  placeholder="First Name"
                  //value="{firstName}"
                  onChange="{e => {
                handleCodeInputChange(e);
              }}"
                />

                <InputError name="refills" />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <label>Last Name</label>
                <Input
                  type="text"
                  className="mb-4"
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name"
                  //value="{lastName}"
                  onChange="{e => {
                handleCodeInputChange(e);
              }}"
                />

                <InputError name="refills" />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label>Email</label>
                <Input
                  type="text"
                  className="mb-4"
                  name="email1"
                  id="email1"
                  placeholder="youremail@domain.com"
                  //value="{email1}"
                  onChange="{e => {
                handleCodeInputChange(e);
              }}"
                />
                <InputError name="refills" />
              </FormGroup>
              <FormGroup className="custom-control custom-checkbox" check>
                <Label className="custom-control-label" check>
                  <Input
                    className="custom-control-input"
                    type="checkbox"
                    onClick={this.toggle}
                  />{' '}
                  I am registering as a Prescriber.
                </Label>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Confirm</Label>
                <Input
                  type="text"
                  className="mb-4"
                  name="email2"
                  id="email2"
                  placeholder="youremail@domain.com"
                  //value="{email2}"
                  onChange="{e => {
                handleCodeInputChange(e);
              }}"
                />
                <InputError name="refills" />
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Collapse isOpen={this.state.collapse}>
            <h1>
              <span>Prescriber Information</span>
            </h1>
            <Row>
              <Col>
                <FormGroup className="mb-4">
                  <Label>NPI</Label>
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="10-digit number"
                    //value="{lastName}"
                    onChange="{e => {
                  handleCodeInputChange(e);
                }}"
                  />

                  <InputError name="npi" />
                  <span className="error inline-b">Required</span>
                </FormGroup>
              </Col>
              <Col>
                <Label>Phone</Label>
                <Row>
                  <div className="col-4">
                    <Field
                      className="form-control"
                      name="prescriberContactBy"
                      component="select"
                      validate="{required}"
                    >
                      <option disabled value="">
                        Select
                      </option>
                      <option value="Mobile">Mobile</option>
                      <option value="Home">Home</option>
                    </Field>
                    <InputError name="prescriberContactBy" />
                    <span className="error inline-b">Required</span>
                  </div>
                  <div className="col-8">
                    <Field
                      className="form-control"
                      name="prescriberPhone"
                      component="input"
                      type="text"
                      placeholder="{phoneMask.parse}"
                      parse="{formatString(phoneMask.parse)}"
                      validate="{primaryPhoneValid}"
                      maxLength={25}
                      id="prescriberPhone"
                    />
                    <InputError name="prescriberPhone" />
                    <span className="error inline-b">Required</span>
                  </div>
                </Row>
              </Col>
            </Row>
          </Collapse>
          <h1>
            <span>Center Information</span>
          </h1>
          <br />
          <Row>
            <Col>
              <Label>Center Location</Label>
              <Typeahead
                {...this.typeaheadProps}
                clearButton
                defaultSelected={options.slice(0, 3)}
                labelKey="name"
                multiple
                options={options}
                placeholder="Choose a center..."
                renderMenuItemChildren={this._renderMenuItemChildren}
              />
              <InputError name="npi" />
              <span className="error inline-b">Required</span>
              <FormText className="form-text text-muted mt-3 mb-3">
                {'For help contact Vertex GPS™ '}
                <a href="tel:877-752-5933">877-752-5933</a>
              </FormText>
              <FormText className="form-text text-muted mt-3 mb-3">
                {'Your center was not found. Please select the '}
                <strong>“My Center is not listed”</strong>
                {
                  ' option to continue your registration and then contact Vertex GPS™ at '
                }
                <a href="tel:877-752-5933">877-752-5933</a>
                {' to have your center added.'}
              </FormText>
            </Col>
          </Row>
          <FormText>
            <Link className="float-left mt-4 mb-4 ml-2 btn btn-link" to={'/'}>
              Back to Sign In
            </Link>
          </FormText>

          <Button
            color="primary"
            className="float-right mt-4 mb-4 mr-3"
            title="React comp"
            type="submit"
            disabled="{!validateForm()}"
          >
            Register
          </Button>
          <button
            className="btn btn-primary float-right mt-4 mb-4 mr-3"
            title="Html tag"
            type="submit"
            disabled="{!validateForm()}"
          >
            Register
          </button>
        </Form>
      </Container>
    );
  }

  _renderMenuItemChildren = (option, props) => {
    return [
      <span key={option.name} search={props.text}>
        {option.name}
      </span>,
      <div key="address1">
        <small>
          {option.address1.toLocaleString()}
          &nbsp;
          {option.address2.toLocaleString()}
          &nbsp;
          {option.zip.toLocaleString()}
        </small>
      </div>
    ];
  };
}

export default Register;

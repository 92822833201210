import React from 'react';

export const ToggleChevron = (isUp: boolean) =>
  isUp ? (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.7"
        d="M1.415 7.97166L6 3.36455L10.585 7.97166L12 6.54984L6 0.520905L0 6.54984L1.415 7.97166Z"
        fill="#5A6771"
      />
    </svg>
  ) : (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.6"
        d="M10.585 0.520921L6 5.12803L1.415 0.52092L1.23979e-06 1.94274L6 7.97168L12 1.94274L10.585 0.520921Z"
        fill="#5A6771"
      />
    </svg>
  );

import React, { useState } from 'react';
import {
  ModalHeader,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';
import ReportList from './ReportList';
import Loader from '../../parts/Loader';
import { ensureArray } from '../../../utilities/JsHelpers';
import api from '../../../api';
import classnames from 'classnames';

interface IProps {
  isOpen: boolean;
  toggle(): void;
}

const enrolledPatientReportTabId = 'EPR';
const patientStatusReportTabId = 'PSR';

const ReportsModal = ({ isOpen, toggle }: IProps) => {
  const [activeTab, setActiveTab] = useState(enrolledPatientReportTabId);

  const [
    enrolledPatientReportList,
    setEnrolledPatientReportList
  ] = React.useState(null);

  const [patientStatusReportList, setPatientStatusReportList] = React.useState(
    null
  );

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const getEnrolledPatientReportList = async () => {
      const enrolledPatientReportList = await api.vertex.getEnrolledPatientReports();
      setEnrolledPatientReportList(enrolledPatientReportList);
    };
    setIsLoading(true);
    getEnrolledPatientReportList().finally(() => setIsLoading(false));
  }, []);

  const getPatientStatusReportList = async () => {
    const patientStatusReportList = await api.vertex.getPatientStatusReports();
    setPatientStatusReportList(patientStatusReportList);
  };

  const showModal = async () => {
    toggle();
  };

  const toggleTabs = (tabs: string) => {
    if (activeTab !== tabs) setActiveTab(tabs);
  };

  const renderNoReportsAvailableContent = () => {
    return <p className="text-center">No reports are currently available.</p>;
  };

  const maybeRenderEnrolledPatientReportList = () => {
    return ensureArray(enrolledPatientReportList).length > 0 ? (
      <ReportList reportList={enrolledPatientReportList} />
    ) : (
      renderNoReportsAvailableContent()
    );
  };

  const maybeRenderPatientStatusReportList = () => {
    return ensureArray(patientStatusReportList).length > 0 ? (
      <ReportList reportList={patientStatusReportList} />
    ) : (
      renderNoReportsAvailableContent()
    );
  };

  const patientStatusReportOnClick = async () => {
    if (patientStatusReportList == null) {
      try {
        setIsLoading(true);
        await getPatientStatusReportList();
      } finally {
        setIsLoading(false);
      }
    }
    toggleTabs(patientStatusReportTabId);
  };

  return isLoading === true ? (
    <Loader isOpen={true} />
  ) : (
    <Modal size="lg" className="reports-modal" isOpen={isOpen}>
      <ModalHeader toggle={showModal} role="heading">
        Reports
      </ModalHeader>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === enrolledPatientReportTabId
            })}
            onClick={() => {
              toggleTabs(enrolledPatientReportTabId);
            }}
            role="NavLink"
          >
            Enrolled Patient Report
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === patientStatusReportTabId
            })}
            onClick={patientStatusReportOnClick}
            role="NavLink"
          >
            Patient Status Report
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={enrolledPatientReportTabId}>
          <ModalBody>{maybeRenderEnrolledPatientReportList()}</ModalBody>
        </TabPane>
        <TabPane tabId={patientStatusReportTabId}>
          <ModalBody>{maybeRenderPatientStatusReportList()}</ModalBody>
        </TabPane>
      </TabContent>
    </Modal>
  );
};

export default ReportsModal;

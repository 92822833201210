import React, { useState } from 'react';

const ImgWithFallback = (props: {
  primaryImgSrc: string;
  fallbackImgSrc: string;
  className: string;
  altText: string;
}) => {
  const onError = () => {
    setImgPath(props.fallbackImgSrc);
  };
  const [imgPath, setImgPath] = useState(props.primaryImgSrc);
  return (
    <img
      className={props.className}
      src={imgPath}
      alt={props.altText}
      onError={onError}
    />
  );
};

export default ImgWithFallback;

import React from 'react';
import { Col, Row } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { CloudUploadIcon } from 'mdi-react';
import * as PropTypes from 'prop-types';

const EmptyFileLibrary = props => {
  return (
    <Row className="file-library empty">
      <Col className="dropzone" lg={{ size: 'auto', order: 2 }}>
        <Dropzone
          multiple={false}
          className="dropzone__wrapper"
          accept={props.accept}
          onDrop={props.onDrop}
          onDropAccepted={props.onDropAccepted}
          onDropRejected={props.onDropRejected}
          disabled={!props.enableUpload || props.isUploading}
          disabledStyle={{ opacity: 0.5 }}
          maxSize={props.maxSize}
          rejectClassName="dropzone__input--reject"
          acceptClassName="dropzone__input--active"
        >
          <div className="dropzone__input">
            <div>
              <p className="mb-3">
                <CloudUploadIcon />
                <strong>
                  Drop files to attach or{' '}
                  <span className="dropzone__fake_link">browse</span>
                </strong>
              </p>
              <p className="text-center">
                Supported Formats: <br />
                BMP, DOC, DOCX, JPEG, PDF
                <br />
                <small>File size is limited to 25 MB</small>
              </p>
            </div>
          </div>
        </Dropzone>
      </Col>
    </Row>
  );
};

EmptyFileLibrary.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  onDrop: PropTypes.func,
  onDropAccepted: PropTypes.func,
  onDropRejected: PropTypes.any,
  enableUpload: PropTypes.any,
  isUploading: PropTypes.bool,
  maxSize: PropTypes.number
};

export default EmptyFileLibrary;

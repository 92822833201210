import React from 'react';
import {
  AlertCircleOutlineIcon,
  CheckCircleOutlineIcon,
  AlarmIcon
} from 'mdi-react';
import * as daysOverdueConstants from '../../../constants/DaysOverdueConstants';
import { dataNotAvailable } from '../../../constants/StringConstants';

const Overdue1To90DaysMessage = ' days Overdue';

export const isBuildMessageStatus = (status: string) => {
  return (
    status === daysOverdueConstants.overdueAtMostAWeek ||
    status === daysOverdueConstants.overdue8To30Days ||
    status === daysOverdueConstants.overdue31To90Days
  );
};

export const getIconContent = (status: string) => {
  return daysOverdueStatusItemMap.get(status)?.icon || null;
};
export const getLabelClass = (status: string) => {
  return daysOverdueStatusItemMap.get(status)?.className || null;
};
export const getErrorMessage = (status: string, daysOverdue: number) => {
  const isBuildable = isBuildMessageStatus(status);
  return isBuildable
    ? daysOverdue.toString() +
        (daysOverdueStatusItemMap.get(status)?.errorMessage || null)
    : daysOverdueStatusItemMap.get(status)?.errorMessage || null;
};

const daysOverdueStatusItemMap = new Map([
  [
    daysOverdueConstants.nullDateOrError,
    {
      className: null,
      errorMessage: dataNotAvailable,
      icon: null
    }
  ],
  [
    daysOverdueConstants.inSupply,
    {
      className: 'supply-valid',
      errorMessage: 'In Supply',
      icon: <CheckCircleOutlineIcon size={16} />
    }
  ],
  [
    daysOverdueConstants.overdueAtMostAWeek,
    {
      className: 'supply-warning',
      errorMessage: Overdue1To90DaysMessage,
      icon: <AlarmIcon size={16} />
    }
  ],
  [
    daysOverdueConstants.overdue8To30Days,
    {
      className: 'supply-alert',
      errorMessage: Overdue1To90DaysMessage,
      icon: <AlertCircleOutlineIcon size={16} />
    }
  ],
  [
    daysOverdueConstants.overdue31To90Days,
    {
      className: 'supply-alert',
      errorMessage: Overdue1To90DaysMessage,
      icon: <AlertCircleOutlineIcon size={16} />
    }
  ],
  [
    daysOverdueConstants.overdue91PlusDays,
    {
      className: null,
      errorMessage: '91+ days Overdue',
      icon: null
    }
  ]
]);

export const styleguideCenterData = [
  {
    name: 'Very Long Center Name',
    address1: '000 Very Long Address Line1',
    address2: 'Very Long Address Line2',
    zip: '00000-9999-00000'
  },
  {
    name: 'Long Center Name',
    address1: '000 Long Address Line1',
    address2: 'Long Address Line2',
    zip: 'XXXXX-9999-XXXXX'
  },
  {
    name: 'Center Name',
    address1: '000 Address Line1',
    address2: 'Address Line2',
    zip: '#####-9999-#####'
  },
  {
    name: 'Very Long Center Name',
    address1: '000 Very Long Address Line1',
    address2: 'Very Long Address Line2',
    zip: '00000-9999-00000'
  },
  {
    name: 'Long Center Name',
    address1: '000 Long Address Line1',
    address2: 'Long Address Line2',
    zip: 'XXXXX-9999-XXXXX'
  },
  {
    name: 'Center Name',
    address1: '000 Address Line1',
    address2: 'Address Line2',
    zip: '#####-9999-#####'
  },
  {
    name: 'Very Long Center Name',
    address1: '000 Very Long Address Line1',
    address2: 'Very Long Address Line2',
    zip: '00000-9999-00000'
  },
  {
    name: 'Long Center Name',
    address1: '000 Long Address Line1',
    address2: 'Long Address Line2',
    zip: 'XXXXX-9999-XXXXX'
  },
  {
    name: 'Center Name',
    address1: '000 Address Line1',
    address2: 'Address Line2',
    zip: '#####-9999-#####'
  },
  {
    name: 'Very Long Center Name',
    address1: '000 Very Long Address Line1',
    address2: 'Very Long Address Line2',
    zip: '00000-9999-00000'
  },
  {
    name: 'Long Center Name',
    address1: '000 Long Address Line1',
    address2: 'Long Address Line2',
    zip: 'XXXXX-9999-XXXXX'
  },
  {
    name: 'Center Name',
    address1: '000 Address Line1',
    address2: 'Address Line2',
    zip: '#####-9999-#####'
  },
  {
    name: 'Very Long Center Name',
    address1: '000 Very Long Address Line1',
    address2: 'Very Long Address Line2',
    zip: '00000-9999-00000'
  },
  {
    name: 'Long Center Name',
    address1: '000 Long Address Line1',
    address2: 'Long Address Line2',
    zip: 'XXXXX-9999-XXXXX'
  },
  {
    name: 'Center Name',
    address1: '000 Address Line1',
    address2: 'Address Line2',
    zip: '#####-9999-#####'
  }
];

export const testCenterData = [
  {
    id: 'f6d8adea-ae67-4b63-8b30-e3eaf1da5280',
    customOption: false,
    name: 'Software Support Center',
    address: '123 Vertex Street',
    city: 'VertexTown',
    state: 'MA',
    zip: '12345'
  },
  {
    id: 'd0ab7964-cd71-48b0-ba1b-84fcfa1a1b34',
    customOption: false,
    name: 'Test Center',
    address: '44 Abele Rd',
    city: 'Bridgeville',
    state: 'PA',
    zip: '15017'
  },
  {
    id: '0133f1a6-a6c8-4767-af2c-b9e0240772c0',
    customOption: false,
    name: 'Test Center',
    address: '123 Vertex Street',
    city: 'VertexTown',
    state: 'MA',
    zip: '12345'
  }
];

import React from 'react';
import { Col, Row } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { CloudUploadIcon } from 'mdi-react';
import FileDetail from './FileDetail';
import * as PropTypes from 'prop-types';

const UserUploads = 'UserUploads';
const SignedPEF = 'SignedPEF';
const UnsignedPEF = 'UnsignedPEF';
const NotAPrescriptionPEF = 'NotAPrescriptionPEF';
const maxNumberOfUploads = 5;

const FileLibrary = props => {
  let userUploadsFileList = props.fileList.filter(
    x => x.attachment_type === UserUploads
  );
  let systemGeneratedFileList = props.fileList.filter(
    x =>
      x.attachment_type === SignedPEF ||
      x.attachment_type === UnsignedPEF ||
      x.attachment_type === NotAPrescriptionPEF
  );
  return (
    <React.Fragment>
      <Row className="mt-4">
        <Col>
          <h1>Patient Enrollment and Consent Upload</h1>
        </Col>
      </Row>
      <Row className="file-library">
        <Col
          className="dropzone"
          lg={{ size: '3', order: 2 }}
          md={{ size: '12', order: 2 }}
        >
          <Dropzone
            multiple={false}
            className="dropzone__wrapper"
            accept={props.accept}
            onDrop={props.onDrop}
            onDropAccepted={props.onDropAccepted}
            onDropRejected={props.onDropRejected}
            disabled={
              !props.enableUpload ||
              props.isUploading ||
              userUploadsFileList.length >= maxNumberOfUploads
            }
            disabledStyle={{ opacity: 0.5 }}
            maxSize={props.maxSize}
            rejectClassName="dropzone__input--reject"
            acceptClassName="dropzone__input--active"
          >
            <div className="dropzone__input">
              <div>
                <p className="mb-3">
                  <CloudUploadIcon />
                  <strong>
                    Drop files to attach or{' '}
                    <span className="dropzone__fake_link">browse</span>
                  </strong>
                </p>
                <p className="text-center">
                  Supported Formats: <br />
                  BMP, DOC, DOCX, JPEG, PDF
                  <br />
                  <small>File size is limited to 25 MB</small>
                </p>
              </div>
            </div>
          </Dropzone>
        </Col>
        <Col lg={{ size: 9, order: 1 }} md={{ size: 12, order: 1 }}>
          <Row className="file-list">
            {userUploadsFileList.length > 0 ? (
              <span className="section-header__title default">
                Uploaded Files
              </span>
            ) : (
              ''
            )}
            {userUploadsFileList.map(file => (
              <FileDetail
                key={file.id}
                file={file}
                onClickDownloadAttachment={props.onClickDownloadAttachment}
                onDelete={props.onDelete}
              />
            ))}
          </Row>
          <Row className="file-list">
            {systemGeneratedFileList.length > 0 ? (
              <span className="section-header__title status-3">
                System Generated Documents
              </span>
            ) : (
              ''
            )}
            {systemGeneratedFileList.map(file => (
              <FileDetail
                key={file.id}
                file={file}
                onClickDownloadAttachment={props.onClickDownloadAttachment}
                onDelete={props.onDelete}
              />
            ))}
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

FileLibrary.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  onDrop: PropTypes.func,
  onDropAccepted: PropTypes.func,
  onDropRejected: PropTypes.any,
  onDelete: PropTypes.func,
  onClickDownloadAttachment: PropTypes.func,
  enableUpload: PropTypes.any,
  isUploading: PropTypes.bool,
  maxSize: PropTypes.number
};

export default FileLibrary;

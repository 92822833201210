export const CodeToBeDelivered = 'CodeToBeDelivered';
export const CodeDelivered = 'CodeDelivered';
export const AttemptLimitExceeded = 'AttemptLimitExceeded';
export const PasswordResetRequired = 'PasswordResetRequired';
export const UsernameRequired = 'UsernameRequired';
export const AccountLocked = 'AccountLocked';
export const CodeMismatchException = 'CodeMismatchException';
export const InvalidParameterException = 'InvalidParameterException';
export const PasswordsDoNotMatch = 'PasswordsDoNotMatch';
export const PasswordResetSuccess = 'PasswordResetSuccess';
export const PasswordResetRequiredException = 'PasswordResetRequiredException';
export const UserNotFoundException = 'UserNotFoundException';
export const LimitExceededException = 'LimitExceededException';
export const InvalidPasswordException = 'InvalidPasswordException';
export const ExpiredCodeException = 'ExpiredCodeException';
export const RegistrationReceived = 'RegistrationReceived';
export const AlreadyRegistered = 'AlreadyRegistered';
export const EmailsDoNotMatch = 'EmailsDoNotMatch';
export const NotAuthorizedException = 'NotAuthorizedException';

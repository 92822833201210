import React from 'react';
import Script from 'react-load-script';
import Toastr from './Toastr';

class SnapEngageWrapper extends React.Component {
  toast = React.createRef();

  handleScriptLoad = function() {
    // This is a required function which can be used to call API
    // functions such as startChat -- i.e.,
    // eslint-disable-next-line no-undef
    // SnapEngage.startChat();
  };

  handleScriptError = () => {
    this.toast.current.error('There has been an error loading the chat agent.');
  };

  render() {
    return (
      <>
        <Script
          url="//storage.googleapis.com/code.snapengage.com/js/4893719e-d2b6-4fee-9892-6ca333fdd715.js"
          onError={this.handleScriptError}
          onLoad={this.handleScriptLoad}
        />
        <Toastr toastRef={this.toast} />
      </>
    );
  }
}

export default SnapEngageWrapper;

import history from './History';

/* eslint-disable no-console */

// This file serves as a wrapper for all logging concerns, such
// that changes to logging can be made in a centralized location.
// All logging should be performed by using this class.

export function log(msg) {
  if (process.env.NODE_ENV !== 'production') {
    console.log(msg);
  }
}

export function dir(object) {
  if (process.env.NODE_ENV !== 'production') {
    console.dir(object);
  }
}

export function error(error) {
  if (
    process.env.NODE_ENV !== 'production' &&
    process.env.NODE_ENV !== 'test'
  ) {
    console.error(error);
  }

  history.replace('/error', error?.message?.errorId);
}

/* eslint-enable no-console */

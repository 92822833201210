import React from 'react';
import Wizard from './Wizard';
import { withRouter, RouteComponentProps } from 'react-router';
import PrimaryInsuranceForm from './manualFormParts/primaryInsuranceForm';
import SecondaryInsuranceForm from './manualFormParts/secondaryInsuranceForm';
import PrescriptionInsuranceForm from './manualFormParts/prescriptionInsuranceForm';
import Loader from './Loader';

interface IManualFormProps extends RouteComponentProps {
  patientEmail: string;
  enrollmentAttemptId: string;
  prescriberId: string;
}

class ManualForm extends React.Component<IManualFormProps> {
  state = {
    isLoaderOpen: false
  };

  onSubmit = (updateEnrollmentInfo: any) => {
    this.setState({ isLoaderOpen: true }, () => {
      const {
        enrollmentAttemptId,
        patientEmail,
        prescriberId
      } = this.props.location.state;

      this.props.history.push({
        pathname: `/Enrollment/${enrollmentAttemptId}/SignaturePin`,
        state: {
          enrollmentAttemptId,
          prescriberId,
          patientEmail,
          updateEnrollmentInfo
        }
      });
    });
  };

  render() {
    return (
      <React.Fragment>
        <Wizard onSubmit={this.onSubmit} initialValues={undefined}>
          <PrimaryInsuranceForm />
          <SecondaryInsuranceForm />
          <PrescriptionInsuranceForm />
        </Wizard>
        <Loader isOpen={this.state.isLoaderOpen} />
      </React.Fragment>
    );
  }
}

export default withRouter(ManualForm);

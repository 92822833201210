import React from 'react';
import FileDetail from '../caseSummaryParts/FileDetail';
import { saveAs } from 'file-saver';
import { ensureArray } from '../../../utilities/JsHelpers';
import { log, error } from '../../../utilities/ErrorHandler';
import api from '../../../api';

type ReportInfo = {
  fileName: string;
  size: number;
  fileId: string;
  fileAddedOn: Date;
  locationName: string;
};

interface IProps {
  reportList: Array<ReportInfo> | null;
}

const ReportList = ({ reportList }: IProps) => {
  const renderFileDetails = (reportList: Array<ReportInfo> | null) => {
    return ensureArray(reportList).map((report: ReportInfo) => {
      const file = {
        id: report.fileId,
        name: report.locationName,
        file_size: report.size,
        timestamp: report.fileAddedOn
      };
      const onClickDownloadAttachment = () => {
        api.vertex
          .downloadReport(file.id)
          .then(response => {
            log(response);
            saveAs(response, report.fileName);
          })
          .catch(err => {
            error(err);
          });
      };

      return (
        <FileDetail
          key={report.fileId}
          file={file}
          onClickDownloadAttachment={onClickDownloadAttachment}
          onDelete={null}
          timestampVerbiage={'Added'}
        />
      );
    });
  };

  return renderFileDetails(reportList);
};

export default ReportList;

import React from 'react';
import { Field } from 'react-final-form';
import InputError from '../../InputError';
import { required } from '../../../../utilities/Validators';

interface IProps {
  label: string;
  name: string;
  maxLength: number;
}

const MutationInput = ({ label, name, maxLength }: IProps) => {
  return (
    <div className="col-xs-12 col-lg-6 mt-2 float-lg-left">
      <label>{label}</label>
      <Field
        className="form-control form-control-sm"
        name={name}
        component="input"
        type="text"
        maxLength={maxLength}
        validate={required()}
      />
      <InputError name={name} />
    </div>
  );
};

export default MutationInput;

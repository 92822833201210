import React from 'react';
import NavBar from '../parts/NavBar';
import NewCaseBtn from '../parts/NewCaseBtn';
import FileLibraryStyleGuide from './FileLibraryStyleGuide';
import CaseSummaryNotificationArea from '../parts/caseSummaryParts/CaseSummaryNotificationArea';
// import MedicationCard from '../enrollmentFormParts/MedicationCard';
import symdekoImage from '../../img/Symdeko_logo.png';
// import CaseManagerPic from '../../img/profile-silho.png';
// import orkambiImage from '../../../img/Orkambi_logo.png';
// import kalydecoImage from '../../../img/Kalydeco_logo.png';
import BubbleTrain from '../parts/BubbleTrain';
import {
  Card,
  CardBody,
  CardSubtitle,
  Button,
  CardTitle,
  CardImg,
  CardHeader,
  CardFooter,
  CardDeck,
  CardText,
  Tooltip,
  UncontrolledCollapse
} from 'reactstrap';

import {
  CheckIcon,
  ContentSaveIcon,
  CloseIcon,
  ExclamationIcon,
  EllipsisHorizontalIcon,
  HelpIcon
} from 'mdi-react';
import { PatientSupportSpecialistLabel } from '../parts/PatientSupportSpecialistLabel';

const bubbleItems = [
  {
    type: 'Type',
    status: 'default',
    icon: <EllipsisHorizontalIcon size="2.625em" />,
    label: 'Default Message',
    smallLabel: '11/7'
  },
  {
    type: 'Type',
    status: 'submitted',
    icon: <EllipsisHorizontalIcon size="2.625em" />,
    label: 'Success Message',
    smallLabel: '11/7'
  },
  {
    type: 'Type',
    status: 'approved',
    icon: <CheckIcon size="2.625em" />,
    label: 'Approved Message',
    smallLabel: '11/7'
  },
  {
    type: 'Type',
    status: 'aware',
    icon: <ExclamationIcon size="2.625em" />,
    label: 'Aware Message',
    smallLabel: '11/7'
  },
  {
    type: 'Type',
    status: 'denied',
    icon: <CloseIcon size="2.625em" />,
    label: 'Denied Message',
    smallLabel: '11/7'
  },
  {
    type: 'Type',
    status: 'denied',
    icon: <HelpIcon className="help-icon" size="2em" />,
    label: 'PA Information Required',
    smallLabel: '11/7'
  }
];

const notifyItems = [
  {
    status: 'submitted',
    icon: <ContentSaveIcon size="2.625em" />,
    alert: 'Success Message',
    message: 'Thank you, your results are being proccessed.'
  },
  {
    status: 'approved',
    icon: <CheckIcon size="2.625em" />,
    alert: 'Approved Message',
    message: 'The submission returned with an "Approved" status.'
  },
  {
    status: 'aware',
    icon: <ExclamationIcon size="2.625em" />,
    alert: 'Aware Message',
    message: 'Please be aware, there are changes/updates.'
  },
  {
    status: 'action',
    icon: <HelpIcon size="2.125em" />,
    alert: 'Action Required',
    message: 'There are items that need attention.'
  }
];

class Summary extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    return (
      <React.Fragment>
        <NavBar />
        <div className="container mt-6 mb-6">
          <div className="row">
            <div className="main col-md-12">
              <div className="row summary">
                <div className="summary-header">
                  <h1>
                    <span>Patient Enrollment: </span>
                    John Splechek
                  </h1>

                  <NewCaseBtn id="newCase" width="20" title="New Case" />
                </div>
                <div className="col-12">
                  <h4>Current Status</h4>
                  <p>
                    This is a paragraph of text that can be added under a main
                    div. This is a paragraph of text that can be added under a
                    main div. This is a paragraph of text that can be added
                    under a main div. This is a paragraph of text that can be
                    added under a main div.This is a paragraph of text that can
                    be added under a main div. This is a paragraph of text that
                    can be added under a main div. This is a paragraph of text
                    that can be added under a main div.
                  </p>
                </div>
                <CaseSummaryNotificationArea items={notifyItems} />
                <div className="bubble-train col-12">
                  <BubbleTrain items={bubbleItems} />
                </div>

                <CardDeck className="summary-body">
                  <Card className="summary-content medication-card">
                    <CardHeader>Current Medication</CardHeader>
                    <div className="img-wrapper">
                      <CardImg
                        top
                        width="100%"
                        src={symdekoImage}
                        alt="Card image cap"
                      />
                    </div>
                    <CardBody>
                      <div className="card-status status-2">
                        <p> Dosage Supply Low</p>
                      </div>
                      <CardTitle>SYMDEKO</CardTitle>
                      <CardSubtitle>Drug Summary</CardSubtitle>
                      <dl>
                        <dt>Dose</dt>
                        <dd>09-04-1988</dd>
                      </dl>
                      <dl>
                        <dt>Supply</dt>
                        <dd>56 tablets (28-day supply)</dd>
                      </dl>
                      <dl>
                        <dt>Special Instructions</dt>
                        <dd>
                          Long-form prescriber notes specifying how to
                          administer or possible allergies.
                        </dd>
                      </dl>
                      <dl>
                        <dt>Previous Treatment</dt>
                        <dd>Orkambi</dd>
                      </dl>
                    </CardBody>
                  </Card>
                  <Card className="summary-content insurance-card">
                    <CardHeader>Current Insurance</CardHeader>

                    <div className="insurance-text">
                      <h1 className="display mb-0 text-primary">
                        Insurance Name Text
                      </h1>
                    </div>

                    <CardBody>
                      <div className="card-status status-3">
                        <p> No Prior Authorization Required</p>
                      </div>
                      <CardTitle>CVS Caremark</CardTitle>
                      <CardSubtitle>Insurance Summary</CardSubtitle>
                      <dl>
                        <dt>Payer Name</dt>
                        <dd>PBMF</dd>
                      </dl>
                      <dl>
                        <dt>Plan Name:</dt>
                        <dd>unknown</dd>
                      </dl>
                      <dl>
                        <dt>Plan ID</dt>
                        <dd>unknown</dd>
                      </dl>
                      <dl>
                        <dt>Group Name</dt>
                        <dd>HONEYWELL</dd>
                      </dl>
                      <dl>
                        <dt>Group ID</dt>
                        <dd>D000000</dd>
                      </dl>
                      <dl>
                        <dt>Member ID</dt>
                        <dd>ZZQ-88-10011-245</dd>
                      </dl>
                      <dl>
                        <dt>Coverage Start Date:</dt>
                        <dd>2010-08-01</dd>
                      </dl>
                      <dl>
                        <dt>Coverage End Date:</dt>
                        <dd>2099-12-31</dd>
                      </dl>
                      <dl>
                        <dt>Pharmacy Type:</dt>
                        <dd>Retail</dd>
                      </dl>
                      <dl>
                        <dt>Prior Authorization:</dt>
                        <dd>--</dd>
                      </dl>
                      <dl>
                        <dt>PA Expired Date</dt>
                        <dd>2019-12-01</dd>
                      </dl>
                    </CardBody>
                    <CardFooter>
                      <Button color="primary" className="btn-block">
                        ePA Initiate
                      </Button>
                      <Button color="disabled" className="btn-block">
                        ePA Initiate
                      </Button>
                      <Button color="primary" className="btn-block">
                        ePA Information
                      </Button>
                      <div className="card-status status-3">
                        <p>Closed Status</p>
                      </div>
                      <CardText className="note">
                        <strong>Attention:{'   '}</strong>
                        Please re-initiate the ePA associated with this case,
                        your previous question set has expired.
                      </CardText>
                      <CardText className="note">
                        This is a closed note passed from Mosaic. This can be
                        any length. This will appear when data is retireved from
                        a case status that has more information that just the
                        status.
                      </CardText>
                      <Button className="btn-block" color="link" id="toggler">
                        More Information
                      </Button>
                    </CardFooter>
                    <UncontrolledCollapse toggler="#toggler">
                      <div>
                        <dl>
                          <dt>Pharmacy Type:</dt>
                          <dd>default</dd>
                        </dl>
                        <dl>
                          <dt>Drug Status:</dt>
                          <dd>default</dd>
                        </dl>
                        <dl>
                          <dt>Coverage Alerts:</dt>
                          <dd>default</dd>
                        </dl>
                        <dl>
                          <dt>Quantity Priced:</dt>
                          <dd>default</dd>
                        </dl>
                        <dl>
                          <dt>Day's Supply Priced:</dt>
                          <dd>default</dd>
                        </dl>
                        <dl>
                          <dt>Plan Pay Amount:</dt>
                          <dd>default</dd>
                        </dl>
                        <br />
                      </div>
                    </UncontrolledCollapse>
                  </Card>
                  <Card className="summary-content patient-card">
                    <CardHeader>Patient Details</CardHeader>
                    <CardBody>
                      <dl>
                        <dt>First Name</dt>
                        <dd>John</dd>
                      </dl>
                      <dl>
                        <dt>Last Name</dt>
                        <dd>Splechek</dd>
                      </dl>
                      <dl>
                        <dt>DOB</dt>
                        <dd>10/31/1999</dd>
                      </dl>
                      <dl>
                        <dt>Gender</dt>
                        <dd>Male</dd>
                      </dl>
                      <dl>
                        <dt>Primary Contact</dt>
                        <dd>-contact info-</dd>
                      </dl>
                      <dl>
                        <dt>Primary Phone</dt>
                        <dd>(555) 777-9999</dd>
                      </dl>
                      <dl>
                        <dt>Primary Email</dt>
                        <dd>john777@yahoo.com</dd>
                      </dl>
                      <dl>
                        <dt>Preferred Pharmacy</dt>
                        <dd>123 Big Pharmacy Rd. Pittsburgh PA, 15220-2200</dd>
                      </dl>
                      <dl>
                        <dt>Copay Assistance</dt>
                        <dd>Enrolled</dd>
                      </dl>
                      <dl>
                        <dt>Copay ID</dt>
                        <dd>1234567890</dd>
                      </dl>
                    </CardBody>
                    <CardHeader>Refill Activity</CardHeader>
                    <CardBody>
                      <dl>
                        <dt>Treatment Status</dt>
                        <dd>9/1/2019</dd>
                      </dl>
                      <dl>
                        <dt>Last Name</dt>
                        <dd>Splechek</dd>
                      </dl>
                      <dl>
                        <dt>Compliance Rate</dt>
                        <dd>50% (6 months)</dd>
                      </dl>
                      <dl>
                        <dt>Last 3 Shipment Dates</dt>
                        <dd>&nbsp;</dd>
                      </dl>
                      <dl>
                        <dt>Most Recent</dt>
                        <dd>2019-08-01</dd>
                      </dl>
                      <dl>
                        <dt>2nd Prior</dt>
                        <dd>2019-08-01</dd>
                      </dl>
                      <dl>
                        <dt>3rd Prior</dt>
                        <dd>2019-08-01</dd>
                      </dl>
                    </CardBody>
                    <div className="media">
                      <div className="mr-3 media-left">
                        <div className="initial-bubble">
                          <img
                            //src={CaseManagerPic}
                            src=""
                            className="case-manager-pic"
                            alt="Name"
                          />
                          {/* <div className="initial-bubble--text">KM</div> */}
                        </div>
                      </div>
                      <div className="media-body">
                        <h4 className="media-heading">Ancey Varghese</h4>
                        <p>
                          <PatientSupportSpecialistLabel />
                          <br />
                          Call <a href="tel:0000000000">(412) 777-8899</a>
                        </p>
                      </div>
                    </div>
                  </Card>
                </CardDeck>
              </div>
            </div>
          </div>
          <FileLibraryStyleGuide />
        </div>
        <Tooltip
          placement="top"
          isOpen={this.state.tooltipNewCase}
          target="actionNewCase"
          toggle={() => {
            this.setState({ tooltipNewCase: !this.state.tooltipNewCase });
          }}
        >
          New Case
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default Summary;

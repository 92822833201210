import React from 'react';
import { Field } from 'react-final-form';
import InputError from '../InputError';
import { required } from '../../../utilities/Validators';

class LocationSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      state: '',
      city: ' ',
      zip: '',
      address: ''
    };
  }

  render() {
    const {
      props: { locations }
    } = this;

    return (
      <React.Fragment>
        <div className="col-xs-12 col-lg-6">
          <label>
            Center:
            <Field
              className="form-control form-control-sm"
              name="locationId"
              component="select"
              validate={required()}
            >
              <option value="">Select</option>
              {locations.map(location => (
                <option key={location.locationId} value={location.locationId}>
                  {location.locationName}
                </option>
              ))}
            </Field>
          </label>
          <InputError name="locationId" />
        </div>
        {/*This DIV is to align under the center but could be reomved if Presciber output is added */}
        <div className="col-xs-12 col-lg-6" />
        <div className="col-xs-12 col-lg-4 pull-lg-8">
          <Field
            name="locationId"
            render={locationProps => {
              const location = locations.find(
                loc => loc.locationId === locationProps.input.value
              );
              if (!location) return <div />;

              return (
                <div className="card location-card">
                  <div className="card-body">
                    <h5 className="card-title">{location.locationName}</h5>
                    <p className="card-text">
                      {location.address}
                      <br />
                      {location.city}, {location.state}
                      <br />
                      {location.zip}
                      <br />
                    </p>
                  </div>
                </div>
              );
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default LocationSelect;

import Amplify from 'aws-amplify';

const config = window.vertexConfig;

export function configure() {
  Amplify.configure({
    Auth: {
      region: 'us-east-1',
      userPoolId: config.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: config.REACT_APP_USER_POOL_WEB_CLIENT_ID
    },
    Analytics: {
      disabled: true
    }
  });
}

import React from 'react';
import NavBar from '../parts/NavBar';
//import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { Button } from 'reactstrap';

class Container extends React.Component {
  state = {
    patients: [
      {
        firstName: 'jake',
        lastName: 'gordon',
        caseId: '12345',
        status: 'status-1'
      },
      {
        firstName: 'joel',
        lastName: 'dom',
        caseId: '123456',
        status: 'status-2'
      },
      {
        firstName: 'david',
        lastName: 'lambl',
        caseId: '123456',
        status: 'status-3'
      }
    ]
  };

  render() {
    return (
      <div>
        <NavBar />
        <div className="container mt-6">
          <div className="row">
            <div className="col-md-12">
              <div className="row sm-pad">
                {this.state.patients.map(patient => (
                  <div className="col-lg-4 col-md-6 col-xs-12">
                    <div className={`card ${patient.status} mb-3`}>
                      <div className="card-header">
                        <h5 className="card-title" key={patient.lastName}>
                          {patient.firstName}{' '}
                          <strong>{patient.lastName}</strong>
                        </h5>
                        <dl>
                          <dt>DOB</dt>
                          <dd>09-04-1988</dd>
                        </dl>
                        <dl>
                          <dt>MRN</dt>
                          <dd>0123456789</dd>
                        </dl>
                        <dl>
                          <dt>CASE </dt>
                          <dd>{patient.caseId} </dd>
                        </dl>
                      </div>
                      <div className="card-status">
                        <p>Status: {patient.status}</p>
                      </div>
                      <div className="card-body">
                        <p className="card-text">
                          Some quick example text to build on the card title and
                          make up the bulk of the card's content.
                        </p>
                      </div>
                      <div className="card-footer">
                        <Button>Fullfill PA</Button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Container;

import * as React from 'react';
import { Redirect } from 'react-router';
import Timeout from './Timeout';
import Loader from './Loader';
import { withRouter } from 'react-router';
import useRouteErrorHandler from '../hooks/useRouteErrorHandler';

/**
 * Parent component which wraps a child component with a Timeout component, and redirects unauthenticated users to the Login Page.
 */
class Authenticator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: null
    };
  }

  componentDidMount() {
    const routeErrorHandlerFunc = useRouteErrorHandler(
      this.props.location.pathname,
      this.props.location.state,
      this.props.history
    );

    this.props.authSvc
      .loggedIn()
      .then(isLoggedIn => this.setState({ isLoggedIn }))
      .catch(err => {
        routeErrorHandlerFunc(err);
      });
  }

  render() {
    // Return a loading component until we figure out whether or not the user is currently logged in
    if (this.state.isLoggedIn === null) {
      return <Loader isOpen={true} />;
    }

    if (this.state.isLoggedIn === true) {
      return <Timeout>{this.props.component}</Timeout>;
    }

    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: this.props.location }
        }}
      />
    );
  }
}

export default withRouter(Authenticator);

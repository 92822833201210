import React from 'react';
import { isTruthy } from '../../../utilities/JsHelpers';
import { dataNotAvailable } from '../../../constants/StringConstants';
import { EligibilityResult } from '../../../model/EligibilityResult';

interface ICoverageResultsMainProps {
  result: EligibilityResult;
}

const CoverageResultsMain: React.FunctionComponent<ICoverageResultsMainProps> = ({
  result
}) => {
  return (
    <>
      <h6>Plan Details</h6>
      <hr />
      <dl>
        <dt>Payer Name:</dt>
        <dd>{result.rx_insurance.pbm_name}</dd>
      </dl>
      <dl>
        <dt>Plan Name:</dt>
        <dd>
          {isTruthy(result.rx_insurance?.plan_name)
            ? result.rx_insurance.plan_name
            : dataNotAvailable}
        </dd>
      </dl>
      <dl>
        <dt>Plan ID:</dt>
        <dd>
          {isTruthy(result.rx_insurance?.plan_id)
            ? result.rx_insurance.plan_id
            : dataNotAvailable}
        </dd>
      </dl>
      <dl>
        <dt>Group Name:</dt>
        <dd>
          {isTruthy(result.rx_insurance?.group_name)
            ? result.rx_insurance.group_name
            : dataNotAvailable}
        </dd>
      </dl>
      <dl>
        <dt>Group ID:</dt>
        <dd>
          {isTruthy(result.rx_insurance?.group_id)
            ? result.rx_insurance.group_id
            : dataNotAvailable}
        </dd>
      </dl>
      <dl>
        <dt>Member ID:</dt>
        <dd>
          {isTruthy(result.rx_insurance?.pbm_member_id)
            ? result.rx_insurance.pbm_member_id
            : dataNotAvailable}
        </dd>
      </dl>
      <dl>
        <dt>Coverage Start Date:</dt>
        <dd>
          {isTruthy(result.rx_insurance?.coverage_date_start)
            ? result.rx_insurance.coverage_date_start
            : dataNotAvailable}
        </dd>
      </dl>
      <dl>
        <dt>Coverage End Date:</dt>
        <dd>
          {isTruthy(result.rx_insurance?.coverage_date_end)
            ? result.rx_insurance.coverage_date_end
            : dataNotAvailable}
        </dd>
      </dl>
    </>
  );
};

export default CoverageResultsMain;

export interface IEnrollmentFormData {
  patientFirstName: string;
  patientLastName: string;
  patientZipCode: string;
  patientDateOfBirthYear: string;
  patientDateOfBirthMonth: string;
  patientDateOfBirthDay: string;
  patientGender: string;
  laguage: number;
  additionalContactLanguage: number;
  primaryPhone: string;
  secondaryPhone: string;
  additionalContactPhone: string;
  prescriberId: string;
  locationId: string;
  mutation1: string;
  mutation2: string;
  refills: string;
  drugDosageId: string;
  drugDaysSupplyId: string;
  shouldDispenseAsWritten: boolean;
  specialInstructions: string;
  patientEmail: string;
}

/**
 * The enrollment form keeps the patient dob information separated
 * out by field.  Also, it treats month as a zero-based index whereas
 * the backend C# api expects a one-based index for month.  This class
 * centralizes the logic for converting the month offset and also provides
 * strong-typing for this data.
 */
export class EnrollmentInfo {
  public readonly patientFirstName: string;
  public readonly patientLastName: string;
  public readonly patientZipCode: string;
  public readonly patientDateOfBirth: string;
  public readonly patientGender: string;
  public readonly language: number;
  public readonly additionalContactLanguage: number;
  public readonly primaryPhone: string;
  public readonly secondaryPhone: string;
  public readonly additionalContactPhone: string;
  public readonly prescriberId: string;
  public readonly locationId: string;
  public readonly mutation1: string;
  public readonly mutation2: string;
  public readonly refills: string;
  public readonly drugDosageId: string;
  public readonly drugDaysSupplyId: string;
  public readonly shouldDispenseAsWritten: boolean;
  public readonly specialInstructions: string;
  public readonly patientEmail: string;
  public readonly patientId: string | null;

  constructor(
    formData: IEnrollmentFormData | undefined,
    patientId: string | null
  ) {
    if (formData) {
      this.patientFirstName = formData.patientFirstName;
      this.patientLastName = formData.patientLastName;
      this.patientZipCode = formData.patientZipCode;
      this.patientDateOfBirth = `${formData.patientDateOfBirthYear}-${Number(
        formData.patientDateOfBirthMonth
      ) + 1}-${formData.patientDateOfBirthDay}`;
      this.patientGender = formData.patientGender;
      this.language = formData.laguage;
      this.additionalContactLanguage = formData.additionalContactLanguage;
      this.primaryPhone = formData.primaryPhone;
      this.secondaryPhone = formData.secondaryPhone;
      this.additionalContactPhone = formData.additionalContactPhone;
      this.mutation1 = formData.mutation1;
      this.mutation2 = formData.mutation2;
      this.refills = formData.refills;
      this.drugDosageId = formData.drugDosageId;
      this.drugDaysSupplyId = formData.drugDaysSupplyId;
      this.shouldDispenseAsWritten = formData.shouldDispenseAsWritten;
      this.specialInstructions = formData.specialInstructions;
      this.prescriberId = formData.prescriberId;
      this.locationId = formData.locationId;
      this.patientEmail = formData.patientEmail;
    } else {
      this.patientFirstName = '';
      this.patientLastName = '';
      this.patientZipCode = '';
      this.patientDateOfBirth = '';
      this.patientGender = '';
      this.language = 0;
      this.additionalContactLanguage = 0;
      this.primaryPhone = '';
      this.secondaryPhone = '';
      this.additionalContactPhone = '';
      this.mutation1 = '';
      this.mutation2 = '';
      this.refills = '';
      this.drugDosageId = '';
      this.drugDaysSupplyId = '';
      this.shouldDispenseAsWritten = false;
      this.specialInstructions = '';
      this.prescriberId = '';
      this.locationId = '';
      this.patientEmail = '';
    }
    this.patientId = patientId;
  }
}

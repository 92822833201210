import React, { useState } from 'react';
import logo from '../../img/Vertex_GPS_logo.svg';
import { Form, Field } from 'react-final-form';
import {
  Container,
  Row,
  Col,
  Collapse,
  FormGroup,
  Label,
  FormText
} from 'reactstrap';
import Loader from '../parts/Loader';
import CenterTypeahead from '../parts/CenterTypeahead';
import InputError from '../parts/InputError';
import LoginNotification from '../parts/loginParts/LoginNotification';
import { LoginNotificationItemMap } from '../parts/loginParts/LoginNotifcationItemMap';
import { phoneMask } from '../../constants/InputMasks';
import { error } from '../../utilities/ErrorHandler';
import {
  required,
  validEmail,
  validNpi,
  validPhone,
  composeValidators
} from '../../utilities/Validators';
import { isTruthy } from '../../utilities/JsHelpers';
import { EmailsDoNotMatch } from '../../constants/LoginNotificationCodes';
import formatString from 'format-string-by-pattern';
import { Link } from 'react-router-dom';
import api from '../../api';
import { TypeaheadCenter } from '../../model/TypeaheadCenter';

interface IRegistrationPage {
  history: {
    replace(path: object, state: object): void;
  };
}

type RegistrationInfo = {
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  isPrescriber: boolean;
  npi: string;
  prescriberPhone: string;
  centers: TypeaheadCenter[];
};

const RegistrationPage = ({ history }: IRegistrationPage) => {
  const [collapsed, setCollapsed] = useState(true);
  const [typeaheadCenters, setTypeaheadCenters] = useState([]);
  const [typeaheadCentersLoading, setTypeaheadCentersLoading] = useState(true);
  const [customOptionSelected, setCustomOptionSelected] = useState(false);
  const [emailMismatch, setEmailMismatch] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const onSubmit = async (registrationInfo: RegistrationInfo) => {
    try {
      const response: {
        newUserRegistrationEmailSent: boolean;
      } = await api.vertex.submitRegistration(registrationInfo);

      history.replace(
        {
          pathname: '/'
        },
        {
          newUserRegistrationEmailSent: response.newUserRegistrationEmailSent
        }
      );
    } catch (err) {
      error(err);
    }
  };

  const maybeRenderLoader = (
    typeaheadCentersLoading: boolean,
    submitting: boolean
  ) => {
    return typeaheadCentersLoading || submitting ? (
      <Loader isOpen={true} />
    ) : null;
  };

  const maybeRenderEmailsDoNotMatchNotification = () => {
    return emailMismatch ? (
      <Col xs="12">
        <LoginNotification
          item={LoginNotificationItemMap.get(EmailsDoNotMatch)}
        />
      </Col>
    ) : null;
  };

  const maybeRenderContactGps = () => {
    return customOptionSelected ? null : (
      <FormText className="form-text text-muted mt-3 mb-3">
        {'For help contact Vertex GPS '}
        <span>&trade;</span>
        {' at '}
        <a href="tel:877-752-5933">877-752-5933</a>
      </FormText>
    );
  };

  const maybeRenderMyCenterIsNotListed = () => {
    return customOptionSelected ? (
      <FormText className="form-text text-muted mt-3 mb-3">
        {
          'Your center was not found. Please continue your registration and then contact Vertex GPS™ at '
        }
        <a href="tel:877-752-5933">877-752-5933</a>
        {' to have your center added.'}
      </FormText>
    ) : null;
  };

  const handleTypeaheadChange = (selectedCenters: {
    findIndex: (arg0: (center: TypeaheadCenter) => boolean) => number;
  }) => {
    const customOptionSelected: boolean =
      selectedCenters.findIndex(
        (center: { customOption: boolean }) => center.customOption === true
      ) > -1;
    setCustomOptionSelected(customOptionSelected);
  };

  React.useEffect(() => {
    (async () => {
      const typeaheadCenters = await api.vertex
        .getTypeaheadCenters()
        .catch(err => error(err));
      setTypeaheadCenters(typeaheadCenters);
      setTypeaheadCentersLoading(false);
    })().catch(err => error(err));
  }, []);

  return (
    <Container className="register col-xs-12 col-lg-6">
      <img
        src={logo}
        className="logo"
        alt="Vertex GPS | Guidaince and Patient Support"
      />
      <h1>
        <span>Register</span>
      </h1>
      <Form
        onSubmit={onSubmit}
        validate={values => {
          if (
            isTruthy(values.confirmEmail) &&
            values.email !== values.confirmEmail
          ) {
            setEmailMismatch(true);
          } else {
            setEmailMismatch(false);
          }

          // The validate prop requires an errors object to be returned,
          // but in this case, we are using a custom validation component
          // and tapping into the prop only for triggering validation, without
          // using the built in validation, so we always return an empty object
          return {};
        }}
        render={({ handleSubmit, submitting, values }) => (
          <form className="form" onSubmit={handleSubmit}>
            {maybeRenderLoader(typeaheadCentersLoading, submitting)}
            <Row>
              <Col>
                <FormGroup className="mb-4">
                  <label>First Name</label>
                  <Field
                    type="text"
                    component="input"
                    className="form-control"
                    name="firstName"
                    placeholder="First Name"
                    validate={required()}
                    maxLength={25}
                  />
                  <InputError name="firstName" />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="mb-4">
                  <label>Last Name</label>
                  <Field
                    type="text"
                    component="input"
                    className="form-control"
                    name="lastName"
                    placeholder="Last Name"
                    validate={required()}
                    maxLength={25}
                  />
                  <InputError name="lastName" />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label>Email</label>
                  <Field
                    type="text"
                    component="input"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="youremail@domain.com"
                    validate={composeValidators(required(), validEmail)}
                    maxLength={100}
                  />
                  <InputError name="email" />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Confirm</Label>
                  <Field
                    component="input"
                    type="text"
                    className="form-control"
                    name="confirmEmail"
                    placeholder="youremail@domain.com"
                    validate={composeValidators(required(), validEmail)}
                    maxLength={100}
                  />
                  <InputError name="confirmEmail" />
                </FormGroup>
              </Col>
              {maybeRenderEmailsDoNotMatchNotification()}
            </Row>
            <Row>
              <Col>
                <div className="custom-control custom-checkbox">
                  <Field
                    data-testid="is-prescriber"
                    id="isPrescriber"
                    className="custom-control-input"
                    name="isPrescriber"
                    component="input"
                    type="checkbox"
                    onClick={toggle}
                  />{' '}
                  <label
                    className="custom-control-label"
                    htmlFor="isPrescriber"
                  >
                    I am registering as a Prescriber.
                  </label>
                </div>
              </Col>
            </Row>

            <br />
            <Collapse isOpen={!collapsed} data-testid="prescriber-information">
              <h1>
                <span>Prescriber Information</span>
              </h1>
              {values.isPrescriber && (
                <Row>
                  <Col>
                    <FormGroup className="mb-4">
                      <Label>NPI</Label>
                      <Field
                        className="form-control"
                        component="input"
                        type="text"
                        name="npi"
                        id="npi"
                        placeholder="10-digit number"
                        validate={composeValidators(required(), validNpi)}
                      />
                      <InputError name="npi" />
                    </FormGroup>
                  </Col>
                  <Col>
                    <Label>Phone</Label>
                    <Row>
                      <div className="col-12">
                        <Field
                          className="form-control"
                          name="prescriberPhone"
                          component="input"
                          type="text"
                          placeholder={phoneMask.parse}
                          parse={formatString(phoneMask.parse)}
                          maxLength={25}
                          validate={composeValidators(required(), validPhone)}
                        />
                        <InputError name="prescriberPhone" />
                      </div>
                    </Row>
                  </Col>
                </Row>
              )}
            </Collapse>
            <h1>
              <span>Center Information</span>
            </h1>
            <br />
            <Row>
              <Col>
                <Label>Center Location</Label>
                <Field
                  name="centers"
                  component={CenterTypeahead as React.FunctionComponent}
                  typeaheadCenters={typeaheadCenters}
                  handleTypeaheadChange={handleTypeaheadChange}
                  validate={required()}
                />
                <InputError name="centers" />
                {maybeRenderContactGps()}
                {maybeRenderMyCenterIsNotListed()}
              </Col>
            </Row>
            <FormText>
              <Link className="float-left mt-4 mb-4 ml-2 btn btn-link" to={'/'}>
                Back to Sign In
              </Link>
            </FormText>

            <button
              className="btn btn-primary float-right mt-4 mb-4 mr-3"
              type="submit"
              disabled={submitting}
            >
              Register
            </button>
          </form>
        )}
      />
    </Container>
  );
};

export default RegistrationPage;

import React from 'react';
import {
  CardHeader,
  CardBody,
  Card,
  Button,
  UncontrolledCollapse
} from 'reactstrap';
import {
  getTruthyValueOrDefault,
  isFalsey,
  isTruthy,
  isNonEmptyArray
} from '../../../utilities/JsHelpers';
import * as FlagTypes from '../../../constants/FlagTypes';
import { dataNotAvailable } from '../../../constants/StringConstants';
import PAFooter from '../caseSummaryParts/PAFooter';
import { format } from 'date-fns';

const required = 'Required';
const notRequired = 'Not Required';
let doesPharmacyExists = false;

const CaseSummaryInsuranceCard = props => (
  <Card className="summary-content insurance-card">
    <CardHeader>Primary Insurance</CardHeader>
    <CardBody>
      <dl>
        <dt>Payer Name:</dt>
        <dd>
          {getTruthyValueOrDefault(
            props.coverageDetails.payerName,
            dataNotAvailable
          )}
        </dd>
      </dl>
      <dl>
        <dt>Plan Name:</dt>
        <dd>
          {getTruthyValueOrDefault(
            props.coverageDetails.planName,
            dataNotAvailable
          )}
        </dd>
      </dl>
      <dl>
        <dt>Plan ID</dt>
        <dd>
          {getTruthyValueOrDefault(
            props.coverageDetails.planId,
            dataNotAvailable
          )}
        </dd>
      </dl>
      <dl>
        <dt>Group Name</dt>
        <dd>
          {getTruthyValueOrDefault(
            props.coverageDetails.groupName,
            dataNotAvailable
          )}
        </dd>
      </dl>
      <dl>
        <dt>Group ID</dt>
        <dd>
          {getTruthyValueOrDefault(
            props.coverageDetails.groupId,
            dataNotAvailable
          )}
        </dd>
      </dl>
      <dl>
        <dt>Member ID</dt>
        <dd>
          {getTruthyValueOrDefault(
            props.coverageDetails.memberId,
            dataNotAvailable
          )}
        </dd>
      </dl>
      <dl>
        <dt>Coverage Start Date:</dt>
        <dd>
          {getTruthyValueOrDefault(
            props.coverageDetails.coverageStartDate,
            dataNotAvailable
          )}
        </dd>
      </dl>
      <dl>
        <dt>Coverage End Date:</dt>
        <dd>
          {getTruthyValueOrDefault(
            props.coverageDetails.coverageEndDate,
            dataNotAvailable
          )}
        </dd>
      </dl>
      {isTruthy(props.priorAuthorization?.authorizationEndDate) ? (
        <dl>
          <dt>PA Expired Date:</dt>
          <dd>
            {format(
              Date.parse(props.priorAuthorization.authorizationEndDate),
              'YYYY-MM-DD'
            )}
          </dd>
        </dl>
      ) : null}
      {isTruthy(props.coverageDetails.pharmacyInformation)
        ? props.coverageDetails.pharmacyInformation.map(result =>
            isTruthy(result) && isTruthy(result.pharmacy) ? (
              <React.Fragment key={result.result_id}>
                <dl>
                  <dt>Pharmacy Type:</dt>
                  <dd>
                    {isTruthy(result) &&
                    isTruthy(result.pharmacy) &&
                    isTruthy(result.pharmacy.type)
                      ? result.pharmacy.type
                      : dataNotAvailable}
                  </dd>
                </dl>
                <dl>
                  <dt>Prior Authorization:</dt>
                  <dd>
                    {isTruthy(result) && isTruthy(result.rxinsurance)
                      ? result.rxinsurance.pa_required === FlagTypes.trueFlag
                        ? required
                        : result.rxinsurance.pa_required === FlagTypes.falseFlag
                        ? notRequired
                        : dataNotAvailable
                      : dataNotAvailable}
                  </dd>
                </dl>
              </React.Fragment>
            ) : null
          )
        : null}
    </CardBody>
    {isFalsey(props.isManualInsurance) ? (
      <React.Fragment>
        {isTruthy(props.coverageDetails.pharmacyInformation)
          ? props.coverageDetails.pharmacyInformation.map(result =>
              isTruthy(result) && isTruthy(result.pharmacy)
                ? (doesPharmacyExists = true)
                : (doesPharmacyExists = false)
            )
          : (doesPharmacyExists = false)}
        {doesPharmacyExists ? (
          <Button className="btn-block" color="link" id="toggler">
            More Information
          </Button>
        ) : (
          ''
        )}

        {isTruthy(props.coverageDetails.pharmacyInformation)
          ? props.coverageDetails.pharmacyInformation.map(result =>
              isTruthy(result) && isTruthy(result.pharmacy) ? (
                <UncontrolledCollapse key={result.result_id} toggler="#toggler">
                  <div className="more-info">
                    <h6>Plan Details</h6>
                    <hr />
                    <dl>
                      <dt>Pharmacy Type:</dt>
                      <dd>
                        {isTruthy(result) &&
                        isTruthy(result.pharmacy) &&
                        isTruthy(result.pharmacy.type)
                          ? result.pharmacy.type
                          : dataNotAvailable}
                      </dd>
                    </dl>
                    <dl>
                      <dt>Drug Status:</dt>
                      <dd>
                        {isTruthy(result) &&
                        isTruthy(result.drug) &&
                        isTruthy(result.drug.status)
                          ? result.drug.status
                          : dataNotAvailable}
                      </dd>
                    </dl>
                    <dl>
                      <dt>Coverage Alerts:</dt>
                      <dd>
                        {isTruthy(result.rxinsurance) &&
                        isTruthy(result.rxinsurance.coverage_alerts) &&
                        isNonEmptyArray(result.rxinsurance.coverage_alerts)
                          ? result.rxinsurance.coverage_alerts.map((alert, i) =>
                              isTruthy(alert.reference_text) ? (
                                <span key={i}>{alert.reference_text}</span>
                              ) : (
                                dataNotAvailable
                              )
                            )
                          : dataNotAvailable}
                      </dd>
                    </dl>
                    <dl>
                      <dt>Quantity Priced:</dt>
                      <dd>
                        {isTruthy(result) &&
                        isTruthy(result.drug) &&
                        isTruthy(result.drug.quantity)
                          ? result.drug.quantity
                          : dataNotAvailable}
                      </dd>
                    </dl>
                    <dl>
                      <dt>Day's Supply Priced:</dt>
                      <dd>
                        {isTruthy(result) &&
                        isTruthy(result.drug) &&
                        isTruthy(result.drug.days_supply)
                          ? result.drug.days_supply
                          : dataNotAvailable}
                      </dd>
                    </dl>
                  </div>
                </UncontrolledCollapse>
              ) : null
            )
          : null}
      </React.Fragment>
    ) : null}
    <PAFooter {...props} />
  </Card>
);

export default CaseSummaryInsuranceCard;

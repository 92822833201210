import React from 'react';
import NavBar from '../parts/NavBar';
import CircleArrow from '../parts/CircleArrow';
import NewCaseBtn from '../parts/NewCaseBtn';
import { Field } from 'react-final-form';
import CaseSummaryNotificationArea from '../parts/caseSummaryParts/CaseSummaryNotificationArea';
// import InputError from '/InputError';
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback
} from 'reactstrap';
import { isNullOrUndefined } from '../../utilities/JsHelpers';
import { Accordion, Card } from 'react-bootstrap';

import {
  ExclamationIcon,
  ContentSaveIcon,
  CheckIcon,
  CloseIcon
} from 'mdi-react';

import {
  required,
  acknowledgementRequiredError
} from '../../utilities/Validators';

const acknowledgementRequired = required(acknowledgementRequiredError);

const acknowledgmentText =
  'I have obtained all consents and authorizations required under federal and state law for the release of the patient’s information through this platform to [Careform] on behalf of Vertex Pharmaceuticals Incorporated (“Vertex”) and for the use and disclosure of this information by Vertex and its contractors and business partners for benefits verification and coordination of dispensing Vertex medicine.';

const notifyItems = [
  {
    status: 'aware',
    icon: <ExclamationIcon size="2.625em" />,
    alert: 'Aware Message',
    message:
      'Please be aware you will need to upload the signed document separately  to the Summary Page.'
  },
  {
    status: 'submitted',
    icon: <ContentSaveIcon />,
    alert: 'Success Message',
    message: 'Thank you, your results are being proccessed.'
  },
  {
    status: 'approved',
    icon: <CheckIcon />,
    alert: 'Approved Message',
    message: 'The submission returned with an "Approved" status.'
  },
  {
    status: 'aware',
    icon: <ExclamationIcon />,
    alert: 'Aware Message',
    message: 'Please be aware, there are changes/updates.'
  },
  {
    status: 'action',
    icon: <CloseIcon />,
    alert: 'Action Required',
    message: 'There are items that need attention.'
  }
];

class Prescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = { optionSelected: null };
  }

  setOptionSelected = optionSelected => {
    const {
      state: { optionSelected: previousOptionSelected }
    } = this;
    const newOptionSelected =
      optionSelected === previousOptionSelected ? null : optionSelected;

    this.setState({ optionSelected: newOptionSelected });
  };

  getIsNextDisabled = () => {
    const {
      state: { optionSelected }
    } = this;

    return isNullOrUndefined(optionSelected) ? 1 : 0;
  };

  render() {
    const {
      state: { optionSelected }
    } = this;

    return (
      <React.Fragment>
        <NavBar />
        <div className="container mt-6 mb-6">
          <div className="row">
            <div className="main col-md-12">
              <div className="row summary">
                <div className="summary-header">
                  <h1>
                    <span>Prescription Options</span>
                  </h1>

                  <NewCaseBtn id="newCase" width="28" title="New Case" />
                </div>

                <div className="prescription-options">
                  <Accordion>
                    <Card
                      className={this.setState}
                      aria-expanded={optionSelected === 0}
                    >
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey="0"
                        onClick={() => {
                          this.setOptionSelected(0);
                        }}
                      >
                        <h2>I will enter the Prescriber PIN</h2>
                        <span>
                          I want the Vertex GPS &trade; enrollment form to be a
                          prescription.
                        </span>
                        <CircleArrow isOptionSelected={optionSelected === 0} />
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <Form
                            className="form wizard text-center col-12"
                            onSubmit={e => this.submitForm(e)}
                          >
                            <div className="mt-4 text-center col-12">
                              <FormGroup>
                                <Label>
                                  Please enter your 4 digit PIN to continue.
                                </Label>
                                <div className="input-group mt-3 mx-auto col-4">
                                  <Input
                                    className="w-25 form-control-lg form-control"
                                    type="text"
                                    name="pin"
                                    id="pin"
                                    placeholder="e.g. 1234"
                                  />
                                </div>

                                <FormText className="mt-3 mx-auto col-8">
                                  Please enter the PIN that was provided, and
                                  you will be provided to sign the patient
                                  enrollment form. You then be taken to the
                                  patient summary screen.
                                </FormText>

                                <FormFeedback className="mt-3 mx-auto col-12">
                                  Please enter a valid PIN.
                                  <br />
                                  Otherwise, please Software Support,{' '}
                                  <a href="tel:1-XXX-XXX-XXXX">
                                    XXX-XXX-XXXX
                                  </a>{' '}
                                  assistance.
                                </FormFeedback>
                              </FormGroup>
                            </div>
                          </Form>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                    <Card
                      className={this.setState}
                      aria-expanded={optionSelected === 1}
                    >
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey="1"
                        onClick={() => {
                          this.setOptionSelected(1);
                        }}
                      >
                        <h2>
                          I will upload the Prescriber signed enrollment form
                        </h2>
                        <span>
                          I want the Vertex GPS &trade; enrollment form to be a
                          presciption.
                        </span>
                        <CircleArrow isOptionSelected={optionSelected === 1} />
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <div className="col-12 mx-3 my-4">
                            <div className="custom-control custom-checkbox">
                              <Field
                                id="isAllowedLeaveMessages"
                                className="custom-control-input"
                                name="isAllowedLeaveMessages"
                                component="input"
                                type="checkbox"
                              />{' '}
                              <label
                                className="custom-control-label"
                                htmlFor="isAllowedLeaveMessages"
                              >
                                I will download the completed PEF/Enrollment
                                Form from the Patient Summary page for physician
                                signature and upload to the Patient Document(s)
                                section when signed.
                              </label>
                            </div>
                          </div>
                          <CaseSummaryNotificationArea items={notifyItems} />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                    <Card
                      className={this.setState}
                      aria-expanded={optionSelected === 2}
                    >
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey="2"
                        onClick={() => {
                          this.setOptionSelected(2);
                        }}
                      >
                        <h2>I will upload the signed form</h2>
                        <span>
                          I want the Vertex GPS &trade; enrollment form to be a
                          presciption.
                        </span>
                        <CircleArrow isOptionSelected={optionSelected === 2} />
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="2">
                        <Card.Body>
                          <article>
                            <h4>More awesome stuff!</h4>
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Suspendisse placerat in mi a eleifend. Sed
                              feugiat vel magna id suscipit. Sed vulputate quam
                              et sem tincidunt ultrices. Ut accumsan volutpat
                              erat malesuada ultricies. Cras ac dui ultrices,
                              lacinia eros nec, tristique lorem. Suspendisse
                              lobortis metus condimentum metus ullamcorper, non
                              iaculis ante feugiat. Vestibulum efficitur nulla
                              at risus vehicula laoreet. Nullam sit amet ipsum
                              varius, sodales est feugiat, tristique quam. Cras
                              libero enim, placerat vitae tempus vitae, accumsan
                              et nunc. Etiam nec eleifend tellus. Nullam at
                              pharetra sem, eu ullamcorper orci. Sed non
                              elementum dolor, vestibulum vehicula magna.
                              Praesent tempor ipsum magna, vel malesuada sem
                              consequat tristique. Vestibulum pellentesque,
                              felis vel bibendum pharetra, elit lacus dapibus
                              mi, ut suscipit lectus erat ut augue. Orci varius
                              natoque penatibus et magnis dis parturient montes,
                              nascetur ridiculus mus. Etiam dignissim laoreet
                              volutpat.{' '}
                            </p>
                          </article>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>

                <h1 className="divider mt-5 mb-3">Patient Consent</h1>
                <div className="col-xs-12 col-lg-12 mt-3">
                  <div className="notify">
                    <div className="notify__item notify__item--aware">
                      <div className="custom-control custom-checkbox">
                        <Field
                          className="custom-control-input"
                          id="Acknowledgment"
                          name="Acknowledgment"
                          component="input"
                          type="checkbox"
                          validate={acknowledgementRequired}
                        />{' '}
                        <label
                          className="custom-control-label"
                          htmlFor="Acknowledgment"
                        >
                          {acknowledgmentText}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="buttons col-12">
                  <button className="btn btn-secondary" type="submit">
                    Previous
                  </button>
                  <button
                    className="btn btn-primary float-right"
                    type="submit"
                    disabled={this.getIsNextDisabled()}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Prescription;

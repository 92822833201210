import logo from '../../img/Vertex_GPS_logo.svg';
import React, { useState } from 'react';
import LoginNotification from '../parts/loginParts/LoginNotification';
import { LoginNotificationItemMap } from '../parts/loginParts/LoginNotifcationItemMap';
import {
  Container,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  FormText
} from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  CodeMismatchException,
  InvalidParameterException,
  PasswordsDoNotMatch,
  InvalidPasswordException,
  ExpiredCodeException
} from '../../constants/LoginNotificationCodes';
import { isTruthy } from '../../utilities/JsHelpers';
import { error } from '../../utilities/ErrorHandler';
import api from '../../api';

const SetPasswordPage = ({ history, location, authSvc }) => {
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [codeMismatch, setCodeMismatch] = useState(false);
  const [passwordComplexityNotMet, setPasswordComplexityNotMet] = useState(
    false
  );

  const passwordsEntered = newPassword.length > 0 && confirmPassword.length > 0;
  const passwordsMatch = newPassword === confirmPassword;
  const isValidUsername = isTruthy(location.state);

  const handleCodeMismatchErrorFunc = () => {
    setCodeMismatch(true);
    setCode('');
  };
  const handlePasswordComplexityNotMetErrorsFunc = () => {
    setPasswordComplexityNotMet(true);
    clearPasswordFields();
  };
  const errorHandlerFuncByErrorCode = new Map([
    [CodeMismatchException, handleCodeMismatchErrorFunc],
    [ExpiredCodeException, handleCodeMismatchErrorFunc],
    [InvalidParameterException, handlePasswordComplexityNotMetErrorsFunc],
    [InvalidPasswordException, handlePasswordComplexityNotMetErrorsFunc],
    [
      undefined,
      err => {
        error(err);
      }
    ]
  ]);

  const clearPasswordFields = () => {
    setNewPassword('');
    setConfirmPassword('');
  };

  const validateForm = () => {
    return code.length > 0 && passwordsEntered && passwordsMatch;
  };

  const submitForm = e => {
    e.preventDefault();
    setCodeMismatch(false);

    if (isValidUsername) {
      const email = location.state.email;

      authSvc
        .forgotPasswordSubmit(email, code, confirmPassword)
        .then(async () => {
          // temporarily login to retrieve token to reset login attempts
          await authSvc.login(email, confirmPassword);
          await api.vertex.resetAllFailedLoginAttempts(email);
          // log back out so user is properly returned to login page after
          // resetting login attempts with token
          await authSvc.logout();
          history.replace(
            {
              pathname: '/'
            },
            { isPasswordReset: true, email: location.state.email }
          );
        })
        .catch(err => {
          errorHandlerFuncByErrorCode.has(err.code)
            ? errorHandlerFuncByErrorCode.get(err.code)(err)
            : errorHandlerFuncByErrorCode.get(undefined)(err);
        });

      return;
    }

    // in order to prevent revealing invalid usernames, simply show an invalid code message
    // rather than telling the user that the username is invalid
    handleCodeMismatchErrorFunc();
  };

  const maybeRenderCodeMismatchNotification = () => {
    return codeMismatch ? (
      <LoginNotification
        item={LoginNotificationItemMap.get(CodeMismatchException)}
      />
    ) : null;
  };

  const maybeRenderPasswordComplexityNotMetNotification = () => {
    return passwordComplexityNotMet ? (
      <LoginNotification
        item={LoginNotificationItemMap.get(InvalidPasswordException)}
      />
    ) : null;
  };

  const maybeRenderPasswordsDoNotMatchNotification = () => {
    return passwordsMatch || !passwordsEntered ? null : (
      <LoginNotification
        item={LoginNotificationItemMap.get(PasswordsDoNotMatch)}
      />
    );
  };

  const handleCodeInputChange = e => {
    setCode(e.target.value);
    return maybeClearCodeMismatch();
  };

  const maybeClearCodeMismatch = () => {
    return codeMismatch ? setCodeMismatch(false) : null;
  };

  const maybeClearPasswordComplexityNotMet = () => {
    return passwordComplexityNotMet ? setPasswordComplexityNotMet(false) : null;
  };

  const handlePasswordInputChange = e => {
    e.target.name === 'newPassword'
      ? setNewPassword(e.target.value)
      : setConfirmPassword(e.target.value);
    return maybeClearPasswordComplexityNotMet();
  };

  return (
    <Container className="main login col-xs-12 col-lg-4">
      <img
        src={logo}
        className="logo"
        alt="Vertex GPS | Guidaince and Patient Support"
      />
      <h1>
        <span>Reset Password</span>
      </h1>
      <Form className="form" onSubmit={submitForm}>
        <Col>
          <FormGroup>
            <Input
              type="text"
              className="mb-4"
              name="code"
              id="code"
              placeholder="Reset code"
              value={code}
              onChange={e => {
                handleCodeInputChange(e);
              }}
            />
            <small className="form-text text-muted mb-2">
              {
                'Use 8 or more upper or lowercase characters with a mix of letters, numbers, and these symbols ^ $ * . [ ] { } ( ) ? - " ! @ #'
              }
            </small>
            <Input
              type="password"
              className="mb-2"
              name="newPassword"
              id="newPassword"
              placeholder="New Password"
              value={newPassword}
              onChange={e => {
                handlePasswordInputChange(e);
              }}
            />
            <Input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={e => {
                handlePasswordInputChange(e);
              }}
            />
          </FormGroup>
          <small className="form-text text-muted mb-2">
            {'If you did not receive a code, contact Vertex GPS™ '}
            <a href="tel:877-752-5933">877-752-5933</a>
          </small>
          {maybeRenderCodeMismatchNotification()}
          {maybeRenderPasswordComplexityNotMetNotification()}
          {maybeRenderPasswordsDoNotMatchNotification()}
        </Col>

        <FormText>
          <Link className="float-left mt-4 mb-4 ml-2 btn btn-link" to={'/'}>
            Back to Sign In
          </Link>
        </FormText>

        <FormText>
          <Link
            className="float-left mt-4 mb-4 ml-2 btn btn-link"
            to={'/forgot-password'}
          >
            Resend Code
          </Link>
        </FormText>

        <Button
          color="primary"
          className="float-right mt-4 mb-4 mr-3"
          type="submit"
          disabled={!validateForm()}
        >
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default SetPasswordPage;

import React from 'react';
import { Field } from 'react-final-form';

import InputError from '../../InputError';
import {
  required,
  validNumberRange,
  composeValidators
} from '../../../../utilities/Validators';

const requiredValidator = required();
const validRange = validNumberRange(0, 999);

const RefillsInput = () => {
  return (
    <div className="col-lg-4 col-xs-12 mt-4">
      <label>Refills</label>
      <Field
        className="form-control form-control-sm"
        name="refills"
        component="input"
        type="text"
        maxLength={3}
        placeholder="Number of refills"
        validate={composeValidators(requiredValidator, validRange)}
      />

      <InputError name="refills" />
    </div>
  );
};

export default RefillsInput;

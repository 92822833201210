declare global {
  interface Window {
    pendo: any;
  }
}
const pendo = window.pendo;

export const initializeAnalytics = (
  id: string,
  email: string,
  firstName: string,
  lastName: string
) => {
  if (pendo) {
    pendo.initialize({
      visitor: {
        id: id ? id : '', // Required if user is logged in
        email: email ? email : '', // Recommended if using Pendo Feedback, or NPS Email
        full_name:
          firstName && lastName
            ? `${firstName ? firstName : ''} ${lastName ? lastName : ''}`
            : '' // Recommended if using Pendo Feedback
        // role:         // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },

      account: {
        id: 'Vertexhub', // Highly recommended
        name: 'Vertex Hub' // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      }
    });
  }
};

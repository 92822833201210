import React from 'react';
import NavBar from '../parts/NavBar';
import { Button } from 'reactstrap';
import { HomeIcon } from 'mdi-react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  const fourZeroFourStyle1 = {
    fontSize: '7em',
    fontWeight: '900',
    color: '#492365',
    textAlign: 'center',
    display: 'block',
    width: '100%',
    margin: '0 auto'
  };

  const fourZeroFourStyle2 = {
    fontSize: '2em',
    color: 'rgb(170, 170, 170)',
    textAlign: 'center',
    display: 'block',
    width: '100%',
    margin: '0px auto 20px auto'
  };

  const fourZeroFourStyle3 = {
    display: 'block',
    textAlign: 'center',
    width: '70%',
    margin: '0px auto 20px auto'
  };

  const fourZeroFourText1 = 'We were unable to find that page.';

  const fourZeroFourText2 =
    'Please go to the Vertex GPS homepage by clicking the button below.';

  return (
    <>
      <NavBar />
      <div className="container mt-6 mb-6">
        <div className="row">
          <div className="main col-md-12">
            <div className="row summary">
              <h1 style={fourZeroFourStyle1}>404</h1>
              <h2 style={fourZeroFourStyle2}>Page not found</h2>
              <h5 style={fourZeroFourStyle3}>{fourZeroFourText1}</h5>
              <h5 style={fourZeroFourStyle3}>{fourZeroFourText2}</h5>
              <div className="col-12 text-center">
                <Button
                  tag={Link}
                  to="/homepage"
                  color="primary"
                  className="mb-5"
                >
                  Return <HomeIcon size={18} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;

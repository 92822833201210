import React from 'react';
import { CardText } from 'reactstrap';
import { isNonEmptyArray, isTruthy } from '../../../utilities/JsHelpers';

class PAStatusNote extends React.Component {
  maybeRenderClosedReasons() {
    return isNonEmptyArray(this.props.priorAuthorization.closedReasons) ? (
      <React.Fragment>
        <br />
        <br />
        <ul>
          {this.props.priorAuthorization.closedReasons.map(reason => (
            <li key={reason}>{reason}</li>
          ))}
        </ul>
      </React.Fragment>
    ) : null;
  }

  render() {
    return (
      <CardText className="note" tag={'div'}>
        <strong>Note:</strong>{' '}
        {isTruthy(this.props.epaNotSupported)
          ? 'ePA Not Supported'
          : this.props.priorAuthorization.note}
        {this.maybeRenderClosedReasons()}
      </CardText>
    );
  }
}

export default PAStatusNote;

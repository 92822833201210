import React from 'react';
import { Row, Col, Button, Tooltip } from 'reactstrap';
import { CloseIcon, DownloadIcon, CloudUploadIcon } from 'mdi-react';
import Dropzone from 'react-dropzone';

class FileLibary extends React.Component {
  onDrop = (accepted, rejected) => {
    this.setState({
      accepted,
      rejected
    });
  };
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: true
    };
    this.state = {
      accepted: [],
      rejected: []
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    return (
      <React.Fragment>
        <Row className="mt-4">
          <Col>
            <h1>Patient File Library</h1>
          </Col>
        </Row>
        <Row className="file-library">
          <Col className="dropzone" lg={{ size: 'auto', order: 2 }}>
            <Dropzone
              multiple={false}
              className="dropzone__wrapper"
              disabled={false}
              rejectClassName="dropzone__input--reject"
              acceptClassName="dropzone__input--active"
            >
              <div className="dropzone__input">
                <div>
                  <p className="mb-3">
                    <CloudUploadIcon />
                    <strong>
                      Drop files to attach or{' '}
                      <span className="dropzone__fake_link">browse</span>
                    </strong>
                  </p>
                  <p className="text-center">
                    <strong>Supported Formats:</strong> <br />
                    TIFF, PNG, JPEG, PDF
                    <br />
                    <small>File size is limited to 25mb</small>
                  </p>
                </div>
              </div>
            </Dropzone>
          </Col>
          <Col lg={{ size: 9, order: 1 }}>
            <Row className="file-list">
              <span className="section-header__title default">
                Uploaded Files
              </span>
              <Col className="file-list__file default" xs="12">
                <div className="file-list__file__info">
                  <div className="file-list__file__info__name">
                    File-Name.pdf
                  </div>
                  <div className="file-list__file__info__details">
                    Size: 6mb&nbsp;&nbsp;&nbsp;Modified: 12-27-18
                  </div>
                </div>
                <div className="file-list__file__actions">
                  <Button
                    color="link"
                    size="lg"
                    aria-label="Delete"
                    id="actionDelete"
                  >
                    <CloseIcon />
                  </Button>

                  <Button
                    color="link"
                    size="lg"
                    aria-label="Download"
                    id="actionDownload"
                  >
                    <DownloadIcon />
                  </Button>
                </div>
              </Col>
              <Col className="file-list__file default" xs="12">
                <div className="file-list__file__info">
                  <div className="file-list__file__info__name">
                    File-Name.pdf
                  </div>
                  <div className="file-list__file__info__details">
                    Size: 6mb&nbsp;&nbsp;&nbsp;Modified: 12-27-18
                  </div>
                </div>
                <div className="file-list__file__actions">
                  <Button
                    color="link"
                    size="lg"
                    aria-label="Delete"
                    id="actionDelete"
                  >
                    <CloseIcon />
                  </Button>

                  <Button
                    color="link"
                    size="lg"
                    aria-label="Download"
                    id="actionDownload"
                  >
                    <DownloadIcon />
                  </Button>
                </div>
              </Col>
              <Col className="file-list__file default" xs="12">
                <div className="file-list__file__info">
                  <div className="file-list__file__info__name">
                    File-Name.pdf
                  </div>
                  <div className="file-list__file__info__details">
                    Size: 6mb&nbsp;&nbsp;&nbsp;Modified: 12-27-18
                  </div>
                </div>
                <div className="file-list__file__actions">
                  <Button
                    color="link"
                    size="lg"
                    aria-label="Delete"
                    id="actionDelete"
                  >
                    <CloseIcon />
                  </Button>

                  <Button
                    color="link"
                    size="lg"
                    aria-label="Download"
                    id="actionDownload"
                  >
                    <DownloadIcon />
                  </Button>
                </div>
              </Col>
              <Col className="file-list__file default" xs="12">
                <div className="file-list__file__info">
                  <div className="file-list__file__info__name">
                    File-Name.pdf
                  </div>
                  <div className="file-list__file__info__details">
                    Size: 6mb&nbsp;&nbsp;&nbsp;Modified: 12-27-18
                  </div>
                </div>
                <div className="file-list__file__actions">
                  <Button
                    color="link"
                    size="lg"
                    aria-label="Delete"
                    id="actionDelete"
                  >
                    <CloseIcon />
                  </Button>

                  <Button
                    color="link"
                    size="lg"
                    aria-label="Download"
                    id="actionDownload"
                  >
                    <DownloadIcon />
                  </Button>
                </div>
              </Col>
              <span className="section-header__title status-3">
                System Generated Documents
              </span>
              <Col className="file-list__file status-3" xs="12">
                <div className="file-list__file__info">
                  <div className="file-list__file__info__name">
                    File-Name.pdf
                  </div>
                  <div className="file-list__file__info__details">
                    Size: 6mb&nbsp;&nbsp;&nbsp;Modified: 12-27-18
                  </div>
                </div>
                <div className="file-list__file__actions">
                  <Button
                    color="link"
                    size="lg"
                    aria-label="Delete"
                    id="actionDelete"
                  >
                    <CloseIcon />
                  </Button>

                  <Button
                    color="link"
                    size="lg"
                    aria-label="Download"
                    id="actionDownload"
                  >
                    <DownloadIcon />
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <h1>Patient File Library w/ empty class</h1>
          </Col>
        </Row>
        <Row className="file-library empty">
          <Col className="dropzone" lg={{ size: 'auto', order: 2 }}>
            <Dropzone
              multiple={false}
              className="dropzone__wrapper"
              disabled={false}
              rejectClassName="dropzone__input--reject"
              acceptClassName="dropzone__input--active"
            >
              <div className="dropzone__input">
                <div className="default">
                  <p className="mb-3">
                    <CloudUploadIcon />
                    <strong>
                      Drop files to attach or{' '}
                      <span className="dropzone__fake_link">browse</span>
                    </strong>
                  </p>
                  <p className="text-center">
                    <strong>Supported Formats:</strong> <br />
                    TIFF, PNG, JPEG, PDF
                    <br />
                    <small>File size is limited to 25mb</small>
                  </p>
                </div>
              </div>
            </Dropzone>
          </Col>
          <Col className="file-list-col" lg={{ size: 'auto', order: 1 }}>
            <Row className="file-list">
              {/* each File is a <Col className="file-list__file" xs="12"> */}
            </Row>
          </Col>
        </Row>
        <Tooltip
          placement="top"
          isOpen={this.state.tooltipDelete}
          target="actionDelete"
          toggle={() => {
            this.setState({ tooltipDelete: !this.state.tooltipDelete });
          }}
        >
          Delete
        </Tooltip>
        <Tooltip
          placement="top"
          isOpen={this.state.tooltipDownload}
          target="actionDownload"
          toggle={() => {
            this.setState({ tooltipDownload: !this.state.tooltipDownload });
          }}
        >
          Download
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default FileLibary;

import React from 'react';
import { Field } from 'react-final-form';
import { CardFooter } from 'reactstrap';

type IProps = {
  name: string;
};

const MedicationCardError = ({ name }: IProps) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? (
        <CardFooter>
          <div className="card-status status-1">
            <p>{error}</p>
          </div>
        </CardFooter>
      ) : null
    }
  />
);

export default MedicationCardError;

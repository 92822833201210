import * as JsHelpers from '../utilities/JsHelpers';
import { AuthServiceCognito } from '../utilities/AuthServiceCognito';
import CustomError from '../utilities/CustomError';
import { noContent, forbidden } from '../constants/HttpStatusCodes';

/* eslint-disable require-atomic-updates */
// Note:  I think the eslint error has something to do with switching the eslint parser.  More investigation needed.
export default function http(fetchMode) {
  async function callFetch(url, options, isRefreshToken = false) {
    const authService = new AuthServiceCognito();
    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    const isLoggedIn = await authService.loggedIn();
    if (!isRefreshToken && isLoggedIn) {
      if (JsHelpers.isFalsey(options.headers)) {
        options.headers = [];
      }

      const token = await authService.getIdToken();
      options.headers['Authorization'] = 'Bearer ' + token;
    }

    const response = await fetch(url, {
      mode: fetchMode,
      ...options,
      headers: {
        Accept: 'application/json',
        ...options.headers
      }
    });

    if (response.status === noContent) {
      return null;
    } else if (response.ok) {
      return await response.json();
    } else {
      if (response.status === forbidden) {
        throw new CustomError(response.status, response.statusText);
      }

      throw new CustomError(response.status, await response.json());
    }
  }

  async function callFetchFile(url, options, isRefreshToken = false) {
    const authService = new AuthServiceCognito();
    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    const isLoggedIn = await authService.loggedIn();
    if (!isRefreshToken && isLoggedIn) {
      if (JsHelpers.isFalsey(options.headers)) {
        options.headers = [];
      }

      const token = await authService.getIdToken();

      options.headers['Authorization'] = 'Bearer ' + token;
    }

    const response = await fetch(url, {
      mode: fetchMode,
      ...options,
      headers: {
        Accept: 'application/json',
        ...options.headers
      }
    });

    if (response.ok) return response.blob();
    else throw new CustomError(response.status, await response.json());
  }

  return {
    get(url) {
      return callFetch(url, {
        method: 'GET',
        headers: {
          pragma: 'no-cache',
          'cache-control': 'no-cache'
        }
      });
    },

    getFile(url) {
      return callFetchFile(url, {
        method: 'GET',
        headers: {
          pragma: 'no-cache',
          'cache-control': 'no-cache'
        }
      });
    },

    post(url, body) {
      return callFetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
    },

    postFormData(url, formData, isRefreshToken = false) {
      return callFetch(
        url,
        {
          method: 'POST',
          body: formData
        },
        isRefreshToken
      );
    },

    patch(url, body) {
      return callFetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json;'
        },
        body: JSON.stringify(body)
      });
    },
    patchFormData(url, body) {
      return callFetch(url, {
        method: 'PATCH',
        body: body
      });
    },
    put(url, body) {
      return callFetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
    },

    delete(url, body) {
      return callFetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
    }
  };
}
/* eslint-enable require-atomic-updates */

import { EnrollmentInfo } from './EnrollmentInfo';
import { EligibilityAndCoverageResponse } from './EligibilityAndCoverageResponse';

export class EnrollmentAttempt {
  public enrollmentInfo: EnrollmentInfo;
  public enrollmentAttemptId: string;
  public eligibilityAndCoverageResponse: EligibilityAndCoverageResponse;

  constructor(
    enrollmentInfo: EnrollmentInfo,
    enrollmentAttemptId: string,
    eligiblityAndCoverageResponse: EligibilityAndCoverageResponse
  ) {
    this.enrollmentInfo = enrollmentInfo;
    this.enrollmentAttemptId = enrollmentAttemptId;
    this.eligibilityAndCoverageResponse = eligiblityAndCoverageResponse;
  }
}

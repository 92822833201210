import React from 'react';
import NavBar from '../parts/NavBar';
// import Notify from '../parts/Notify';
// import MedicationCard from '../enrollmentFormParts/MedicationCard';
// import symdekoImage from '../../img/Symdeko_logo.png';
// import orkambiImage from '../../../img/Orkambi_logo.png';
// import kalydecoImage from '../../../img/Kalydeco_logo.png';
// import BubbleTrain from '../parts/BubbleTrain';
import {
  // Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormText,
  FormFeedback
} from 'reactstrap';

import {
  HomeIcon
  // CheckIcon,
  // ContentSaveIcon,
  // CloseIcon,
  // ExclamationIcon
} from 'mdi-react';

class Summary extends React.Component {
  render() {
    return (
      <React.Fragment>
        <NavBar />
        <div className="container mt-6 mb-6">
          <div className="row">
            <div className="main col-md-12">
              <div className="row summary">
                <div className="summary-header">
                  <h1>
                    <span>PIN Entry</span>
                  </h1>

                  <button className="btn float-right" type="submit">
                    Return <HomeIcon size={18} />
                  </button>
                </div>

                <Form
                  className="form wizard text-center col-12"
                  onSubmit={e => this.submitForm(e)}
                >
                  <div className="mt-4 text-center col-12">
                    <FormGroup>
                      <Label>Please enter your 4 digit PIN to continue.</Label>
                      <div className="input-group mt-3 mx-auto col-4">
                        <Input
                          className="w-25 form-control-lg form-control"
                          type="text"
                          name="pin"
                          id="pin"
                          placeholder="e.g. 1234"
                        />
                        <div className="input-group-append">
                          <Button
                            color="primary"
                            className="btn btn-outline-primary"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </div>
                      </div>

                      <FormFeedback className="mt-3 mx-auto col-4">
                        Please enter a valid PIN. Otherwise, please call
                        Software Support,{' '}
                        <a href="tel:1-XXX-XXX-XXXX">XXX-XXX-XXXX</a> for
                        assistance.
                      </FormFeedback>

                      <FormText className="mt-3 mx-auto col-4">
                        Please enter the PIN that was provided, and you will be
                        provided to sign the patient enrollment form. You then
                        be taken to the patient summary screen.
                      </FormText>
                    </FormGroup>
                  </div>
                </Form>

                <div className="buttons col-12">
                  <button className="btn btn-secondary" type="submit">
                    Previous
                  </button>
                  <button className="btn btn-primary float-right" type="submit">
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Summary;

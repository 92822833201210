import React from 'react';
import {
  ArrowRightBoldBoxOutlineIcon,
  CheckIcon,
  AlarmLightIcon
} from 'mdi-react';

export const initiateEpa = 'Initiate ePA';
export const renewEpa = 'Renew ePA';
export const epaErrorOccured = 'error';

export const epaAlertMap = new Map([
  [
    initiateEpa,
    {
      status: 'submitted',
      icon: <ArrowRightBoldBoxOutlineIcon size="3em" />,
      alert: 'Prior Authorization',
      message:
        'Thank you, we will return your Prior Authorization questions shortly.'
    }
  ],
  [
    renewEpa,
    {
      status: 'approved',
      icon: <CheckIcon size="3em" />,
      alert: 'Prior Authorization',
      message: 'Thank you for renewing your ePA.'
    }
  ],
  [
    null,
    {
      status: 'action',
      icon: <AlarmLightIcon size="3em" />,
      alert: 'Prior Authorization',
      message:
        'Something went wrong. Please re-initiate ePA or call the support team.'
    }
  ]
]);

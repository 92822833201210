import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { ensureArray, isFalsey } from '../../utilities/JsHelpers';
import { TypeaheadCenter } from '../../model/TypeaheadCenter';
import { FieldInputProps } from 'react-final-form';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';

interface IProps {
  input: FieldInputProps<HTMLInputElement>;
  typeaheadCenters: TypeaheadCenter[];
  handleTypeaheadChange: (selected: TypeaheadCenter[]) => void;
}

const CenterTypeahead = (props: IProps) => {
  const maxCenters: number = 5;
  const [selected, setSelected] = React.useState([]);

  const isCenterBeingRemoved = (selectedCenters: TypeaheadCenter[]) => {
    return selectedCenters.length < maxCenters;
  };

  const handleTypeaheadChange = (selectedCenters: TypeaheadCenter[]) => {
    if (
      selected.length < maxCenters ||
      (selected.length === maxCenters && isCenterBeingRemoved(selectedCenters))
    ) {
      setSelected(selectedCenters as React.SetStateAction<never[]>);
    } else if (selectedCenters.length === maxCenters + 1) {
      selectedCenters.pop();
    }

    const transformedCenters = selectedCenters.map(transformCenter);
    props.input.onChange(transformedCenters);
    props.handleTypeaheadChange(transformedCenters);
  };

  const transformCenter = (center: TypeaheadCenter) => {
    if (isFalsey(center.customOption)) {
      center.customOption = false;
    } else {
      center.name = 'My Center is not listed';
      center.address = '';
      center.city = '';
      center.state = '';
      center.zip = '';
    }

    return center;
  };

  const renderMenuItemChildren = (center: TypeaheadCenter) => {
    return (
      <React.Fragment key={center.id}>
        <span>{center.name}</span>,
        <div>
          <small>
            {center.address}
            &nbsp;
            {center.city}
            &nbsp;
            {`${center.state}, `}
            &nbsp;
            {center.zip}
          </small>
        </div>
      </React.Fragment>
    );
  };

  return (
    <Typeahead
      id={'centers'}
      positionFixed={true}
      dropup={true}
      clearButton={true}
      labelKey={'name'}
      multiple={true}
      options={ensureArray(props.typeaheadCenters)}
      placeholder="Choose a center..."
      allowNew={true}
      newSelectionPrefix={'My Center is not listed '}
      onChange={handleTypeaheadChange}
      renderMenuItemChildren={renderMenuItemChildren}
      selected={selected}
    />
  );
};

export default CenterTypeahead;

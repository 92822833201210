import React from 'react';
import { Container, Row, Col, FormGroup } from 'reactstrap';
import { Field } from 'react-final-form';
import Wizard from '../Wizard';
import CaseSummaryNotificationArea from '../caseSummaryParts/CaseSummaryNotificationArea';
import { ExclamationIcon } from 'mdi-react';

const notifyItems = [
  {
    status: 'aware',
    icon: <ExclamationIcon size="2.625em" />,
    alert: '',
    message:
      'These sections are not required if you upload a face sheet or copies of the insurance and prescription card to the Patient Enrollment and Consent Upload on the Summary page.'
  }
];

class PrescriptionInsuranceForm extends React.Component {
  render() {
    return (
      <Wizard.Page>
        <h1>
          <span>Prescription Insurance</span>
        </h1>
        <Container>
          <Row>
            <div className="col-12 col-lg-4">
              <FormGroup>
                <label>Prescription Drug Insurance:</label>
                <Field
                  className="form-control form-control-sm"
                  name="PrescriptionInsurancePayer"
                  component="input"
                  type="text"
                />
                <label>Group Number:</label>
                <Field
                  className="form-control form-control-sm"
                  name="PrescriptionInsuranceGroupNumber"
                  component="input"
                  type="text"
                />
                <label>ID #:</label>
                <Field
                  className="form-control form-control-sm"
                  name="PrescriptionInsuranceId"
                  component="input"
                  type="text"
                />
                <label>BIN #:</label>
                <Field
                  className="form-control form-control-sm"
                  name="PrescriptionInsuranceBinNumber"
                  component="input"
                  type="text"
                />
                <label>Insurance Phone Number:</label>
                <Field
                  className="form-control form-control-sm"
                  name="PrescriptionInsurancePhoneNumber"
                  component="input"
                  type="text"
                />
                <label>Employer Name:</label>
                <Field
                  className="form-control form-control-sm"
                  name="PrescriptionInsuranceEmployerName"
                  component="input"
                  type="text"
                />
              </FormGroup>
            </div>
            <Col>
              <CaseSummaryNotificationArea items={notifyItems} />
            </Col>
          </Row>
        </Container>
      </Wizard.Page>
    );
  }
}

export default PrescriptionInsuranceForm;

import React from 'react';
import {
  getBIStatus,
  getShippingStatus
} from '../../../utilities/StatusHelpers';

class CaseSummaryStatusSection extends React.Component {
  renderBIStatusBubble = () => {
    return getBIStatus(this.props.mosaicIntegration);
  };

  renderShippingStatusBubble = () => {
    return getShippingStatus(this.props.mosaicIntegration);
  };

  render() {
    return (
      <React.Fragment>
        <h4>Current Status</h4>
        <div className="bubble-train col-12">
          {this.renderBIStatusBubble()}
          {this.props.renderPAStatusBubble()}
          {this.renderShippingStatusBubble()}
        </div>
      </React.Fragment>
    );
  }
}

export default CaseSummaryStatusSection;

import React from 'react';
import { CheckIcon, ExclamationIcon, CloseIcon } from 'mdi-react';
import * as LoginNotificationCodes from '../../../constants/LoginNotificationCodes';

export const LoginNotificationItemMap = new Map([
  [
    LoginNotificationCodes.CodeToBeDelivered,
    {
      status: 'static',
      icon: <ExclamationIcon size="16" />,
      message: 'A code will be sent to the email registered with this account.'
    }
  ],
  [
    LoginNotificationCodes.CodeDelivered,
    {
      status: 'approved',
      icon: <CheckIcon size="16" />,
      message: 'A code has been sent to the registered account'
    }
  ],
  [
    LoginNotificationCodes.PasswordResetSuccess,
    {
      status: 'approved',
      icon: <CheckIcon size="16" />,
      message: 'Your password has been successfully reset'
    }
  ],
  [
    LoginNotificationCodes.AttemptLimitExceeded,
    {
      status: 'aware',
      icon: <ExclamationIcon size="16" />,
      message: 'Attempt limit exceeded, please try after some time'
    }
  ],
  [
    LoginNotificationCodes.PasswordResetRequired,
    {
      status: 'action',
      icon: <ExclamationIcon size="16" />,
      message: 'You must reset your password to proceed'
    }
  ],
  [
    LoginNotificationCodes.UsernameRequired,
    {
      status: 'action',
      icon: <CloseIcon size="16" />,
      message: 'You must provide a username'
    }
  ],
  [
    LoginNotificationCodes.AccountLocked,
    {
      status: 'action',
      icon: <CloseIcon size="16" />,
      message: (
        <>
          {'Your account has been locked, too many failed attempts.'}
          <br />
          {'You must reset your password to proceed.'}
        </>
      )
    }
  ],
  [
    LoginNotificationCodes.CodeMismatchException,
    {
      status: 'action',
      icon: <CloseIcon size="16" />,
      message: 'The code that was entered is invalid or expired'
    }
  ],
  [
    LoginNotificationCodes.InvalidParameterException,
    {
      status: 'action',
      icon: <CloseIcon size="16" />,
      message: 'Your password does not meet the complexty requirements'
    }
  ],
  [
    LoginNotificationCodes.InvalidPasswordException,
    {
      status: 'action',
      icon: <CloseIcon size="16" />,
      message: 'Your password does not meet the complexty requirements'
    }
  ],
  [
    LoginNotificationCodes.PasswordsDoNotMatch,
    {
      status: 'action',
      icon: <CloseIcon size="16" />,
      message: 'The passwords entered do not match'
    }
  ],
  [
    LoginNotificationCodes.RegistrationReceived,
    {
      status: 'approved',
      icon: <CheckIcon size="16" />,
      message:
        'Your registration has been received. You will be contacted shortly with instructions on how to log in, as well as information on getting started.'
    }
  ],
  [
    LoginNotificationCodes.AlreadyRegistered,
    {
      status: 'aware',
      icon: <ExclamationIcon size="16" />,
      message:
        'This user already exists. Reset your password to proceed, or contact support if you are still not able to log in.'
    }
  ],
  [
    LoginNotificationCodes.EmailsDoNotMatch,
    {
      status: 'action',
      icon: <ExclamationIcon size="16" />,
      message: 'The email addresses entered do not match.'
    }
  ]
]);

import React from 'react';
import NavBar from '../parts/NavBar';
import { ReactElement, useState, useEffect, useRef, useCallback } from 'react';
import api from '../../api';
import { IFilterGroup } from '../../Interfaces/IFilterGroup';
import { IHomePageFilter } from '../../Interfaces/IHomePageFilter';
import { IPatientCard } from '../../Interfaces/IPatientCard';
import * as ErrorHandler from '../../utilities/ErrorHandler';
import { isNullOrUndefined, isNonEmptyArray } from '../../utilities/JsHelpers';
import TermsModal from '../parts/TermsModal';
import FilterSection from '../parts/homepageFilterParts/FilterSection';
import Loader from '../parts/Loader';
import * as HomePageFilterTypes from '../../constants/HomePageFilterTypes';
import EnrollmentCardSection from '../parts/homepageParts/EnrollmentCardSection';
import { localStorageFilterName } from '../../constants/StringConstants';

const HomePage = (): ReactElement => {
  const [actionCards, setActionCards] = useState<IPatientCard[]>();
  const [statusCards, setStatusCards] = useState<IPatientCard[]>();
  const [completedCards, setCompletedCards] = useState<IPatientCard[]>();
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const [filterGroups, setFilterGroups] = useState<IFilterGroup[]>([]);
  const [searchVal, setSearchVal] = useState<string>('');
  const [hasActiveFilter, setHasActiveFilter] = useState<boolean>(false);

  const unfilteredActionCards = useRef<IPatientCard[]>([]);
  const unfilteredStatusCards = useRef<IPatientCard[]>([]);
  const unfilteredCompletedCards = useRef<IPatientCard[]>([]);

  const filterCards = useCallback(
    (
      patientCards: any[],
      searchVal: string,
      hasActiveFilter: boolean,
      filterSelection: IFilterGroup[]
    ) => {
      const patientCardsFilteredByName = patientCards.filter(card =>
        card.patientName.toLowerCase().includes(searchVal.toLowerCase())
      );

      const createFilterMap = (
        filterType: number,
        filterSelection: IFilterGroup[]
      ) =>
        filterSelection
          .filter(fg => fg.items.some(i => i.filterType === filterType))
          .map(fg => fg.items)
          .flat(1);

      if (filterSelection && hasActiveFilter) {
        const centerFilterItems = createFilterMap(
          HomePageFilterTypes.center,
          filterSelection
        );
        const daysOverdueFilterItems = createFilterMap(
          HomePageFilterTypes.daysOverdue,
          filterSelection
        );
        const medicationFilterItems = createFilterMap(
          HomePageFilterTypes.medication,
          filterSelection
        );
        const therapyStatusFilterItems = createFilterMap(
          HomePageFilterTypes.therapyStatus,
          filterSelection
        );

        const hasMedicationFilter = medicationFilterItems.some(
          i => i.isSelected
        );
        const hasDaysOverdueFilter = daysOverdueFilterItems.some(
          i => i.isSelected
        );
        const hasCenterFilter = centerFilterItems.some(i => i.isSelected);
        const hasTreatmentStatusFilter = therapyStatusFilterItems.some(
          i => i.isSelected
        );

        return patientCardsFilteredByName.filter(
          card =>
            (!hasMedicationFilter ||
              medicationFilterItems.some(
                i =>
                  i.isSelected === true &&
                  i.label.toLowerCase() === card.medicationName.toLowerCase()
              )) &&
            (!hasCenterFilter ||
              centerFilterItems.some(
                i =>
                  i.isSelected === true &&
                  i.label.toLowerCase() === card.centerName.toLowerCase()
              )) &&
            (!hasTreatmentStatusFilter ||
              therapyStatusFilterItems.some(
                i =>
                  i.isSelected === true &&
                  i.label.toLowerCase() === card.therapyStatus.toLowerCase()
              )) &&
            (!hasDaysOverdueFilter ||
              daysOverdueFilterItems.some(
                i =>
                  i.isSelected === true &&
                  i.key.toLowerCase() ===
                    card.daysOverdueForRefillStatus.toLowerCase()
              ))
        );
      }

      return patientCardsFilteredByName;
    },
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      const acceptedTerms = await api.vertex.checkTermsOfUse();
      const enrollments = await api.vertex.getEnrollments();
      const filters = await api.vertex.getHomePageFilters();

      try {
        setAcceptedTerms(acceptedTerms);

        unfilteredActionCards.current = enrollments.filter(
          (enrollment: any) => enrollment.caseActionStage === 0
        );
        unfilteredStatusCards.current = enrollments.filter(
          (enrollment: any) => enrollment.caseActionStage === 1
        );
        unfilteredCompletedCards.current = enrollments.filter(
          (enrollment: any) => enrollment.caseActionStage === 2
        );

        setActionCards(unfilteredActionCards.current);
        setStatusCards(unfilteredStatusCards.current);
        setCompletedCards(unfilteredCompletedCards.current);

        const filter = loadFilters(filters);
        const cachedFilter = JSON.parse(
          window.sessionStorage.getItem(localStorageFilterName) || '[]'
        );
        if (isNonEmptyArray(cachedFilter)) {
          setFilterGroups(cachedFilter);
          const hasActiveFilter = cachedFilter.some((fg: IFilterGroup) =>
            fg.items.some(i => i.isSelected)
          );
          setActionCards(
            filterCards(
              unfilteredActionCards.current,
              '',
              hasActiveFilter,
              cachedFilter
            )
          );
          setStatusCards(
            filterCards(
              unfilteredStatusCards.current,
              '',
              hasActiveFilter,
              cachedFilter
            )
          );
          setCompletedCards(
            filterCards(
              unfilteredCompletedCards.current,
              '',
              hasActiveFilter,
              cachedFilter
            )
          );
        } else {
          setFilterGroups(filter);
        }
      } catch (err) {
        ErrorHandler.error(err);
      }
    };
    fetchData().finally(() => {});
  }, [filterCards]);

  const loadFilters = (filters: IHomePageFilter[] | undefined) => {
    if (filters === undefined) {
      return [];
    }
    const filterGroups = [
      {
        title: 'Center',
        items: filters.filter(
          filter => filter.filterType === HomePageFilterTypes.center
        ),
        expandToFillSpace: true
      },
      {
        title: 'Days Overdue for Refill',
        items: filters.filter(
          filter => filter.filterType === HomePageFilterTypes.daysOverdue
        )
      },
      {
        title: 'Medication',
        items: filters.filter(
          filter => filter.filterType === HomePageFilterTypes.medication
        )
      },
      {
        title: 'Treatment Status',
        items: filters.filter(
          filter => filter.filterType === HomePageFilterTypes.therapyStatus
        )
      }
    ];

    return filterGroups;
  };

  const filterPatientCards = (
    searchVal: string,
    hasActiveFilterValue: boolean,
    filterSelection: IFilterGroup[]
  ) => {
    let actionCards = filterCards(
      unfilteredActionCards.current,
      searchVal,
      hasActiveFilterValue,
      filterSelection
    );
    let statusCards = filterCards(
      unfilteredStatusCards.current,
      searchVal,
      hasActiveFilterValue,
      filterSelection
    );
    let completedCards = filterCards(
      unfilteredCompletedCards.current,
      searchVal,
      hasActiveFilterValue,
      filterSelection
    );
    setHomePageState(
      filterSelection,
      searchVal,
      hasActiveFilterValue,
      actionCards,
      statusCards,
      completedCards
    );
  };

  const onClearSearch = () => {
    filterPatientCards('', hasActiveFilter, filterGroups);
    setFilterLocalVariable(filterGroups);
  };

  const onHomepageSearch = (searchVal: string) => {
    filterPatientCards(searchVal, hasActiveFilter, filterGroups);
  };

  const setHomePageState = (
    filterGroups: IFilterGroup[],
    searchVal: string,
    hasActiveFilter: boolean,
    actionCards: IPatientCard[],
    statusCards: IPatientCard[],
    completedCards: IPatientCard[]
  ) => {
    setFilterGroups(filterGroups);
    setSearchVal(searchVal);
    setHasActiveFilter(hasActiveFilter);
    setActionCards(actionCards);
    setStatusCards(statusCards);
    setCompletedCards(completedCards);
  };

  const setFilterLocalVariable = (filterSelectionState: IFilterGroup[]) => {
    window.sessionStorage.setItem(
      'filters',
      JSON.stringify(filterSelectionState)
    );
  };
  const onFilterSelection = (
    filterSelectionState: IFilterGroup[],
    hasActiveFilter: boolean
  ) => {
    filterPatientCards(searchVal, hasActiveFilter, filterSelectionState);
    setFilterLocalVariable(filterSelectionState);
  };

  const checkTermsOfUse = (acceptedTerms: boolean) => {
    setAcceptedTerms(acceptedTerms);
  };

  return isNullOrUndefined(actionCards) ||
    isNullOrUndefined(statusCards) ||
    isNullOrUndefined(completedCards) ||
    !isNonEmptyArray(filterGroups) ? (
    <Loader isOpen={true} />
  ) : (
    <>
      <NavBar
        includeSearch={true}
        filterPatientCards={onHomepageSearch}
        setClearPatientCardSearchState={onClearSearch}
      />
      <div className="container mt-6 mb-4">
        <div className="row no-gutters"></div>
        <div className="col-md-12">
          <FilterSection
            filterGroups={filterGroups || []}
            onFilterSelection={onFilterSelection}
          />
          <EnrollmentCardSection
            actionCards={actionCards}
            statusCards={statusCards}
            completedCards={completedCards}
          />
        </div>
      </div>
      <TermsModal
        isOpen={!acceptedTerms}
        includeLink={false}
        backdrop="static"
        headerToggle={false}
        includeFooter={true}
        onAccept={checkTermsOfUse}
      />
    </>
  );
};

export default HomePage;

import React from 'react';
import NavBar from '../parts/NavBar';
import { Form, Field } from 'react-final-form';
import {
  // Col,
  FormGroup,
  Label,
  Button
} from 'reactstrap';
import api from '../../api';
import * as ErrorHandler from '../../utilities/ErrorHandler';
import { required } from '../../utilities/Validators';

const Error = ({ name }) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? <span className="error">{error}</span> : null
    }
  />
);

class AdminPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      prescribers: [],
      values: {}
    };
  }
  submitting = () => this.setState({ submitting: true });

  onSubmit(values) {
    let pin = values.pin;
    let prescriberId = values.prescriberId;
    let formData = new FormData();
    formData.append('pin', pin);
    formData.append('prescriberId', prescriberId);
    api.vertex
      .setPin(formData)
      .then(result => {
        // eslint-disable-next-line no-alert
        window.alert('successfully updated prescriber pin');
      })
      .catch(e => {
        // eslint-disable-next-line no-alert
        window.alert();
        ErrorHandler.error(e);
      });
  }

  componentDidMount() {
    api.vertex
      .getPrescribers()
      .then(prescribers => this.setState({ prescribers }))
      .catch(er => ErrorHandler.error(er));
  }

  render() {
    return (
      <React.Fragment>
        <NavBar />
        <div className="container mt-6 mb-6">
          <div className="row">
            <div className="main col-md-12">
              <div className="row summary">
                <div className="summary-header">
                  <h1>
                    <span>PIN Editor</span>
                  </h1>
                </div>
                <Form
                  onSubmit={this.onSubmit}
                  render={({ handleSubmit, values }) => (
                    <form
                      className="wizard form text-center col-12"
                      onSubmit={handleSubmit}
                    >
                      <div className="mt-4 text-center col-12">
                        <FormGroup>
                          <Label>Prescriber ID:</Label>
                          <div className="input-group mt-3 mx-auto col-4">
                            <Field
                              name="prescriberId"
                              className="'w-25 form-control-lg form-control"
                              type="text"
                              component="input"
                              validate={required()}
                            />
                            <Error name="prescriberId" />
                          </div>
                          <Label>Pin</Label>
                          <div className="input-group mt-3 mx-auto col-4">
                            <Field
                              name="pin"
                              className="'w-25 form-control-lg form-control"
                              type="password"
                              component="input"
                              validate={required()}
                              maxLength={4}
                              autoComplete="off"
                            />
                            <Error name="pin" />
                          </div>
                          <div className="" style={{ marginTop: '10px' }}>
                            <Button
                              color="primary"
                              className="btn btn-outline-primary"
                              type="submit"
                              disabled={this.state.submitting}
                            >
                              Set New Pin
                            </Button>
                          </div>
                        </FormGroup>
                      </div>
                      <pre>{JSON.stringify(values, 0, 2)}</pre>
                    </form>
                  )}
                />
              </div>
              <div>
                <ul>
                  {this.state.prescribers.map(prescriber => {
                    return (
                      <li key={prescriber.prescriberId}>
                        <p>{prescriber.prescriberName}</p>
                        <p>{prescriber.prescriberId}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AdminPage;

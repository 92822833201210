import React from 'react';
import NavBar from '../parts/NavBar';
import EnrollmentForm from '../parts/EnrollmentForm';
import { isTruthy } from '../../utilities/JsHelpers';

const NewCasePage = props => (
  <>
    <NavBar />
    <div className="container mt-6 mb-6">
      <div className="row">
        <div className="main col-md-12">
          <EnrollmentForm
            initialValues={
              isTruthy(props) &&
              isTruthy(props.location) &&
              isTruthy(props.location.state) &&
              isTruthy(props.location.state.enrollmentInformation)
                ? props.location.state.enrollmentInformation
                : undefined
            }
          />
        </div>
      </div>
    </div>
  </>
);

export default NewCasePage;

import React from 'react';
import { Field } from 'react-final-form';

const SpecialInstructionsInput = () => {
  return (
    <div className="col-xs-12 col-lg-12 mt-4 float-lg-left">
      <label>Special Instructions</label>
      <Field
        className="form-control form-control-sm"
        name="specialInstructions"
        component="textarea"
        rows="3"
        placeholder="Special instructions if any"
        maxLength={100}
      />
    </div>
  );
};

export default SpecialInstructionsInput;

import React from 'react';
import {
  isNullOrUndefined,
  isNonEmptyArray,
  isTruthy
} from '../../../utilities/JsHelpers';
import { log, error } from '../../../utilities/ErrorHandler';
import EmptyFileLibrary from './EmptyFileLibrary';
import FileLibrary from './FileLibrary';
import Loader from '../Loader';
import Toastr from '../../parts/Toastr';
import api from '../../../api';
import { saveAs } from 'file-saver';
import DeleteConfirmationModal from './DeleteConfirmationModal';

class FileLibaryContainer extends React.Component {
  state = {
    accepted: [],
    rejected: [],
    fileList: [],
    isLoading: false,
    confirmationModalIsOpen: false,
    attachmentId: null
  };

  toast = React.createRef();

  supportedMimeTypes = [
    'application/pdf',
    'image/jpeg',
    'image/bmp',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword'
  ];

  maxFileSize = 26214400; // 25MB

  onDrop = (accepted, rejected) => {
    this.setState({
      accepted,
      rejected
    });
  };

  handleOnDropAccepted = acceptedFiles => {
    this.uploadAttachment(acceptedFiles[0]);
  };

  uploadAttachment = attachment => {
    this.setState({ isUploading: true }, () => {
      api.vertex
        .uploadAttachment(this.props.enrollmentRequestId, attachment)
        .then(uploadResponse => {
          this.setState({ isUploading: false }, () => {
            if (!isNullOrUndefined(uploadResponse.errors)) {
              const errors = JSON.stringify(uploadResponse.errors);
              this.toast.current.error(errors);
              return;
            }

            this.setFileList().catch(err => error(err));
          });
        })
        .catch(() => {
          this.toast.current.error('Please try again. An error has occurred.');
        });
    });
  };

  onDelete = attachmentId => {
    this.setState({
      confirmationModalIsOpen: true,
      attachmentId: attachmentId
    });
  };

  closeConfirmationModal = async (isDeleteConfirmed, attachmentId) => {
    this.setState(
      {
        confirmationModalIsOpen: false,
        isLoading: isDeleteConfirmed
      },
      async () => {
        if (isDeleteConfirmed) {
          await api.vertex.deleteAttachment(
            attachmentId,
            this.props.enrollmentRequestId
          );
          this.setState(
            {
              confirmationModalIsOpen: false,
              isLoading: false,
              attachmentId: null
            },
            () => {
              this.setFileList().catch(err => error(err));
            }
          );
        }
      }
    );
  };

  onClickDownloadAttachment = (attachmentId, attachmentName) => {
    api.vertex
      .getAttachment(attachmentId, this.props.enrollmentRequestId)
      .then(response => {
        log(response);
        const filename = attachmentName;
        saveAs(response, filename);
      })
      .catch(err => {
        error(err);
      });
  };

  renderFileLibrary = () => {
    return isNonEmptyArray(this.state.fileList) ? (
      <FileLibrary
        accept={this.supportedMimeTypes}
        onDrop={this.onDrop}
        onDropAccepted={this.handleOnDropAccepted}
        onDropRejected={this.handleOnDropRejected}
        enableUpload={this.props.enableUpload}
        isUploading={this.state.isLoading}
        maxSize={this.maxFileSize}
        fileList={this.state.fileList}
        onDelete={this.onDelete}
        onClickDownloadAttachment={this.onClickDownloadAttachment}
      />
    ) : (
      <EmptyFileLibrary
        accept={this.supportedMimeTypes}
        onDrop={this.onDrop}
        onDropAccepted={this.handleOnDropAccepted}
        onDropRejected={this.handleOnDropRejected}
        enableUpload={this.props.enableUpload}
        uploading={this.state.isLoading}
        maxSize={this.maxFileSize}
      />
    );
  };

  getFileList = async () => {
    try {
      const result = await api.vertex.getAttachmentList(
        this.props.enrollmentRequestId
      );
      return result.response;
    } catch (err) {
      error(err);
    }
  };

  setFileList = async () => {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        const fileList = await this.getFileList();
        this.setState({
          fileList,
          isLoading: false
        });
      }
    );
  };

  componentDidMount() {
    this.setFileList().catch(err => error(err));
  }

  render() {
    return isTruthy(this.state.isLoading) ? (
      <Loader isOpen={true} />
    ) : (
      <>
        {this.renderFileLibrary()}
        <DeleteConfirmationModal
          confirmationModalIsOpen={this.state.confirmationModalIsOpen}
          closeConfirmationModal={this.closeConfirmationModal}
          attachmentId={this.state.attachmentId}
        />
        <Toastr toastRef={this.toast} />
      </>
    );
  }
}

export default FileLibaryContainer;

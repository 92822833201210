import React from 'react';
import { RouteComponentProps } from 'react-router';
import qs from 'qs';
import api from '../../api';
import { error } from '../../utilities/ErrorHandler';
import Loader from '../parts/Loader';

interface IEnrollmentCompletePageProps
  extends RouteComponentProps<{ enrollmentAttemptId: string }> {
  signingEvent: string;
}

class EnrollmentCompletePage extends React.Component<
  IEnrollmentCompletePageProps
> {
  private readonly enrollmentAttemptId: string;

  async componentDidMount() {
    const signatureEvent = this.getSignatureEvent(this.props.location.search);
    await api.vertex
      .saveSignatureEvent(this.enrollmentAttemptId, signatureEvent)
      .then(patientId => this.finishCompletion(patientId))
      .catch(e => error(e));
  }

  constructor(props: IEnrollmentCompletePageProps) {
    super(props);
    this.enrollmentAttemptId = this.props.match.params.enrollmentAttemptId;
  }

  finishCompletion = async (patientId: string) => {
    this.props.history.push(`/enrollment/${patientId}`);
  };

  getSignatureEvent = (queryString: string): string => {
    const queryStringVals = qs.parse(queryString, {
      ignoreQueryPrefix: true
    });
    return (queryStringVals?.event as string) ?? '';
  };

  render() {
    return <Loader isOpen={true} />;
  }
}

export default EnrollmentCompletePage;

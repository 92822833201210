import React from 'react';
import NavBar from '../parts/NavBar';
import ManualForm from '../parts/ManualForm';

const ManualProcessPage = () => (
  <>
    <NavBar />
    <div className="container mt-6 mb-6">
      <div className="row">
        <div className="main col-md-12">
          <ManualForm />
        </div>
      </div>
    </div>
  </>
);

export default ManualProcessPage;

// This file contains JavaScript helper methods.
import format from 'date-fns/format';

// Helper function to ensure that truthy tests actually return a boolean value and not
// unintentionally return the value itself.
export function isTruthy(value) {
  return value ? true : false;
}

// Helper function to ensure that falsey tests actually return a boolean value and not
// unintentionally return the value itself.
export function isFalsey(value) {
  return !MockableJsHelpers.isTruthy(value);
}

export function isNullOrUndefined(value) {
  return value === null || value === undefined ? true : false;
}

export function isArray(maybeArray) {
  return Array.isArray(maybeArray);
}

export function isNonEmptyArray(maybeArray) {
  return isArray(maybeArray) && maybeArray.length > 0;
}

export function isFunction(maybeFunc) {
  return MockableJsHelpers.isTruthy(
    maybeFunc && maybeFunc.constructor && maybeFunc.call && maybeFunc.apply
  );
}

export function ensureArray(maybeArray) {
  if (isFalsey(maybeArray)) {
    return [];
  }

  return isArray(maybeArray) ? maybeArray : [maybeArray];
}

export function getTruthyValueOrDefault(value, defaultValue) {
  if (MockableJsHelpers.isTruthy(value)) {
    return value;
  }

  return defaultValue;
}

export function getNonNullValueOrDefault(value, defaultValue) {
  if (value !== null && value !== undefined) {
    return value;
  }

  return defaultValue;
}

export function isString(maybeString) {
  return typeof maybeString === 'string';
}

export function toTitleCase(str) {
  return isTruthy(str) && isString(str)
    ? str.length > 1
      ? `${str.charAt(0).toUpperCase()}${str
          .substr(1, str.length - 1)
          .toLowerCase()}`
      : str.charAt(0).toUpperCase()
    : '';
}
export function formatPhoneNumber(str) {
  return isTruthy(str) && isString(str)
    ? str.length > 1
      ? `(${str.substr(0, 3)}) ${str.substr(3, 3)}-${str.substr(6, str.length)}`
      : ''
    : '';
}
export function formatZipCode(str) {
  return isTruthy(str) && isString(str)
    ? str.length > 1
      ? str.length > 5
        ? `${str.substr(0, 5)}-${str.substr(5, str.length)}`
        : str
      : ''
    : '';
}

export function getFormattedDate(date) {
  var shippingDate = new Date(date);
  var month = shippingDate.getUTCMonth() + 1;
  var day = shippingDate.getUTCDate();
  var year = shippingDate.getUTCFullYear();
  return `${month}/${day}/${year}`;
}
export function formatPatientDob(
  patientDateOfBirthDay,
  patientDateOfBirthMonth,
  patientDateOfBirthYear
) {
  return format(
    Date.parse(
      `${patientDateOfBirthMonth}/${patientDateOfBirthDay}/${patientDateOfBirthYear}`
    ),
    'MM/DD/YYYY'
  );
}

//if acceptableExtensions is excluded or empty, all file types are accepted
export function isValidFilePath(path, acceptableExtensions) {
  return (
    isTruthy(path) &&
    (!(acceptableExtensions && acceptableExtensions.length > 0) ||
      acceptableExtensions.some(ext => path.endsWith(ext)))
  );
}

export function areZipCodesEqual(zipCode1, zipCode2) {
  return zipCode1.length === 9 && zipCode2.length === 9
    ? zipCode1 === zipCode2
    : zipCode1.substring(0, 5) === zipCode2.substring(0, 5);
}

export const currencyUsdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});

const MockableJsHelpers = {
  isTruthy
};

export default MockableJsHelpers;

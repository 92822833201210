import React from 'react';
import { isNonEmptyArray, isTruthy } from '../../../utilities/JsHelpers';
import * as FlagTypes from '../../../constants/FlagTypes';
import { dataNotAvailable } from '../../../constants/StringConstants';
import { CoverageResult } from '../../../model/CoverageResult';

const required = 'Required';
const notRequired = 'Not Required';

interface ICoverageResultsMoreInformatonProps {
  coverageResult: CoverageResult;
}

const CoverageResultsMoreInformation: React.FunctionComponent<ICoverageResultsMoreInformatonProps> = ({
  coverageResult
}) => (
  <div className="more-info" key={coverageResult.result_id}>
    <h6>Plan Details</h6>
    <hr />
    <dl>
      <dt>Pharmacy Type:</dt>
      <dd>
        {isTruthy(coverageResult.pharmacy)
          ? coverageResult.pharmacy.type
          : dataNotAvailable}
      </dd>
    </dl>
    <dl>
      <dt>Prior Authorization:</dt>
      <dd>
        {isTruthy(coverageResult) && isTruthy(coverageResult.rx_insurance)
          ? coverageResult.rx_insurance.pa_required === FlagTypes.trueFlag
            ? required
            : coverageResult.rx_insurance.pa_required === FlagTypes.falseFlag
            ? notRequired
            : dataNotAvailable
          : dataNotAvailable}
      </dd>
    </dl>
    <dl>
      <dt>Drug Status:</dt>
      <dd>
        {isTruthy(coverageResult.coverage_drug)
          ? coverageResult.coverage_drug.status
          : dataNotAvailable}
      </dd>
    </dl>
    <dl>
      <dt>Coverage Alerts:</dt>
      <dd>
        {isTruthy(coverageResult.rx_insurance) &&
        isTruthy(coverageResult.rx_insurance.coverage_alerts) &&
        isNonEmptyArray(coverageResult.rx_insurance.coverage_alerts)
          ? coverageResult.rx_insurance.coverage_alerts?.map((alert, i) =>
              isTruthy(alert.reference_text) ? (
                <span key={i}>
                  {alert.reference_text}
                  <br />
                </span>
              ) : (
                dataNotAvailable
              )
            )
          : dataNotAvailable}
      </dd>
    </dl>
    <dl>
      <dt>Quantity Priced:</dt>
      <dd>
        {isTruthy(coverageResult.coverage_drug?.quantity)
          ? coverageResult.coverage_drug.quantity
          : dataNotAvailable}
      </dd>
    </dl>
    <dl>
      <dt>Day's Supply Priced:</dt>
      <dd>
        {isTruthy(coverageResult.coverage_drug?.days_supply)
          ? coverageResult.coverage_drug.days_supply
          : dataNotAvailable}
      </dd>
    </dl>
  </div>
);

export default CoverageResultsMoreInformation;

import React from 'react';
import PropTypes from 'prop-types';
import { isTruthy } from '../../../utilities/JsHelpers';

const LoginNotification = ({ item }) =>
  isTruthy(item) ? (
    <>
      <div className="notify-small">
        <div className={`notify__item notify__item--${item.status}`}>
          {item.icon}
          <div className="content">
            <p>{item.message}</p>
          </div>
        </div>
      </div>
    </>
  ) : null;

LoginNotification.propTypes = {
  item: PropTypes.shape({
    status: PropTypes.string,
    icon: PropTypes.element,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
  })
};

export default LoginNotification;

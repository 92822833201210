import React from 'react';
import { Form, FormSpy } from 'react-final-form';
import { EnrollmentInfo } from '../../model/EnrollmentInfo';
import { FormApi } from 'final-form';

interface IWizardState {
  page: number;
  values: any;
}

interface IWizardProps {
  onSubmit: (formData: EnrollmentInfo) => void;
  children: Array<JSX.Element>;
  initialValues: EnrollmentInfo | undefined | null;
}

interface IWizardChildProps {
  validate: (values: any) => boolean;
}

export interface IWizardPageProps {
  form: FormApi;
  values: EnrollmentInfo;
}

class Wizard extends React.Component<IWizardProps, IWizardState> {
  static Page: ({ children }: { children: Array<JSX.Element> }) => any = ({
    children
  }) => children;

  next = (values: EnrollmentInfo) =>
    this.setState((state: IWizardState) => ({
      page: Math.min(state.page + 1, this.props?.children?.length - 1),
      values
    }));

  previous = () =>
    this.setState(state => ({
      page: Math.max(state.page - 1, 0)
    }));

  isLastPage(page: Number, children: Array<JSX.Element>) {
    return page === React.Children.count(children) - 1;
  }

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */
  validate = (values: EnrollmentInfo) => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ];
    return (activePage as React.Component)?.props?.hasOwnProperty('validate')
      ? (activePage as React.Component<IWizardChildProps>).props.validate(
          values
        )
      : {};
  };

  handleSubmit = (values: EnrollmentInfo) => {
    const {
      props: { children, onSubmit },
      state: { page }
    } = this;

    const formattedValues: EnrollmentInfo = {
      ...values,
      language: Number(values.language),
      additionalContactLanguage: Number(values.additionalContactLanguage)
    };

    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) {
      return onSubmit(formattedValues);
    } else {
      this.next(formattedValues);
    }
  };

  formatPhoneNumber = (phoneNumberString: string) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return '';
  };

  formatZip = (zipString: string) => {
    var cleaned = ('' + zipString).replace(/\D/g, '');
    var matchNine = cleaned.match(/^(\d{5})(\d{4})$/);
    var matchFive = cleaned.match(/^(\d{5})$/);
    if (matchNine) {
      return matchNine[1] + '-' + matchNine[2];
    }
    if (matchFive) {
      return matchFive[1];
    }
    return '';
  };

  constructor(props: IWizardProps) {
    super(props);
    const formattedInitialValues = {
      ...props.initialValues,
      patientZipCode: this.formatZip(
        props.initialValues ? props.initialValues.patientZipCode : ''
      ),
      primaryPhone: this.formatPhoneNumber(
        props.initialValues ? props.initialValues.primaryPhone : ''
      ),
      secondaryPhone: this.formatPhoneNumber(
        props.initialValues ? props.initialValues.secondaryPhone : ''
      ),
      additionalContactPhone: this.formatPhoneNumber(
        props.initialValues ? props.initialValues.additionalContactPhone : ''
      )
    };

    this.state = {
      page: 0,
      values: formattedInitialValues || {}
    };
  }
  render() {
    const {
      props: { children },
      state: { page, values }
    } = this;

    const ActivePage = (React.Children.toArray(children)[page] as JSX.Element)
      .type;
    const isLastPage = this.isLastPage(page, children);
    return (
      <React.Fragment>
        <Form
          initialValues={values}
          validate={this.validate}
          onSubmit={this.handleSubmit}
        >
          {({ handleSubmit, submitting, values, form }) => {
            const onMedicationSelectedChanged = () => {
              const valuesDrugDaysSupplyIdReset = {
                ...values,
                drugDaysSupplyId: ''
              };
              form.reset(valuesDrugDaysSupplyIdReset);
            };
            return (
              <form className="row wizard" onSubmit={handleSubmit}>
                <FormSpy
                  render={({ form }) => (
                    <ActivePage
                      onMedicationSelectedChanged={onMedicationSelectedChanged}
                      form={form}
                      {...values}
                    />
                  )}
                />
                <div className="buttons col-12">
                  {page > 0 && (
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={this.previous}
                    >
                      Previous
                    </button>
                  )}
                  {!isLastPage && (
                    <button
                      className="btn btn-primary float-right"
                      type="submit"
                    >
                      Next
                    </button>
                  )}
                  {isLastPage && (
                    <button
                      className="btn btn-primary float-right"
                      type="submit"
                      disabled={submitting}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </form>
            );
          }}
        </Form>
      </React.Fragment>
    );
  }
}

export default Wizard;

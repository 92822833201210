import React from 'react';
import { Field } from 'react-final-form';
import { ExclamationIcon } from 'mdi-react';
import CaseSummaryNotificationArea from '../../parts/caseSummaryParts/CaseSummaryNotificationArea';
import { pinForm } from '../../../constants/StringConstants';

const notifyItems = [
  {
    status: 'aware',
    icon: <ExclamationIcon size="2.625em" />,
    alert: '',
    message:
      'Please be aware you will need to upload the signed document separately  to the Summary Page.'
  }
];

const SignedEnrollmentFormOption = ({
  submitError,
  optionSelected,
  cardOption
}) => {
  return (
    <div className="mx-3 my-4 p-3">
      <div className="custom-control custom-checkbox">
        <Field
          name="signedEnrollment.willUploadPEF"
          id="willUploadPEF"
          data-testid="upload-enrollment"
          className="custom-control-input"
          type="checkbox"
          component="input"
        />{' '}
        <label className="custom-control-label" htmlFor="willUploadPEF">
          I will download the pre-populated Enrollment form from the Patient
          Summary Page for physician signature and upload to the Patient
          Document(s) section when signed.
        </label>
        {submitError ? (
          <div
            className="error mt-3 d-block text-center"
            data-testid="upload-enrollment-error"
          >
            {pinForm.uploadValidationContent.errorText}
          </div>
        ) : null}
      </div>
      <CaseSummaryNotificationArea items={notifyItems} />
    </div>
  );
};

export default SignedEnrollmentFormOption;

import React from 'react';
import { FormGroup } from 'reactstrap';
import { Field } from 'react-final-form';
import { YearPicker, MonthPicker, DayPicker } from 'react-dropdown-date';
import InputError from './InputError';
import { required } from '../../utilities/Validators';

class BirthDate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      year: props.year === undefined ? '' : props.year,
      month: props.month === undefined ? '' : props.month,
      day: props.day === undefined ? '' : props.day
    };
  }
  render() {
    return (
      <FormGroup>
        <label>Patient DOB:</label>

        <div className="row">
          <div className="col-4">
            <Field
              name={'patientDateOfBirthYear'}
              validate={required()}
              render={({ input: { onChange, value }, meta, ...rest }) => (
                <YearPicker
                  defaultValue={'Year'} // default is 1900
                  start={1900} // default is ASCENDING
                  reverse // default is false
                  value={this.state.year} // mandatory
                  onChange={year => {
                    this.setState({ year });
                    onChange(year);
                  }}
                  classes={'form-control form-control-sm'}
                  name={'patientDateOfBirthYear'}
                  id={'patientDateOfBirthYear'}
                />
              )}
            />
            <InputError name="patientDateOfBirthYear" />
          </div>
          <div className="col-4">
            <Field
              name={'patientDateOfBirthMonth'}
              classes="form-control form-control-sm"
              validate={required()}
              render={({ input: { onChange, value }, meta, ...rest }) => (
                <MonthPicker
                  classes={'form-control form-control-sm'}
                  defaultValue={'Month'} // default is 1900
                  start={1} // default is ASCENDING
                  reverse // default is false
                  value={this.state.month} // mandatory
                  onChange={month => {
                    this.setState({ month });
                    onChange(month);
                  }}
                  name={'patientDateOfBirthMonth'}
                />
              )}
            />
            <InputError name="patientDateOfBirthMonth" />
          </div>
          <div className="col-4">
            <Field
              name={'patientDateOfBirthDay'}
              classes="form-control form-control-sm"
              validate={required()}
              render={({ input: { onChange, value }, meta, ...rest }) => (
                <DayPicker
                  classes={'form-control form-control-sm'}
                  year={this.state.year}
                  month={this.state.month}
                  defaultValue={'Day'} // default is 1900
                  start={1} // default is ASCENDING
                  reverse // default is false
                  value={this.state.day} // mandatory
                  onChange={day => {
                    this.setState({ day });
                    onChange(day);
                  }}
                  name={'patientDateOfBirthDay'}
                />
              )}
            />
            <InputError name="patientDateOfBirthDay" />
          </div>
        </div>
      </FormGroup>
    );
  }
}
export default BirthDate;

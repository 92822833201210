import React from 'react';
import { isTruthy } from '../../../utilities/JsHelpers';

const maybeRenderSelectCoverage = (resultsExist, selectedCoverageName) => {
  return resultsExist
    ? renderSelectCoverageOrSelectedCoverage(selectedCoverageName)
    : null;
};

const renderSelectCoverageOrSelectedCoverage = selectedCoverageName => {
  return isTruthy(selectedCoverageName) ? (
    <h4>
      You've selected the coverage option:{' '}
      <strong>{selectedCoverageName}</strong>
    </h4>
  ) : (
    <h4>Please select the coverage option you would like to use.</h4>
  );
};

const CoverageResultsHeader = ({
  patientName,
  resultsExist,
  selectedCoverageName
}) => (
  <div className="row summary">
    <div className="summary-header">
      <h1>
        <span>Coverage Results for: </span> {patientName}
      </h1>
    </div>

    <div className="col-12">
      {maybeRenderSelectCoverage(resultsExist, selectedCoverageName)}
    </div>
  </div>
);

export default CoverageResultsHeader;

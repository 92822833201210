import React from 'react';

const CircleArrow = ({
  style = {},
  fill = 'none',
  width = '100%',
  className = 'CircleArrow',
  viewBox = '0 0 37 37',
  isOptionSelected
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    transform={isOptionSelected ? 'rotate(90)' : 'rotate(0)'}
  >
    <ellipse
      cx="18.1738"
      cy="18.4092"
      rx="18"
      ry="18"
      transform="rotate(0 18 18)"
      fill={isOptionSelected ? '#EDDCFA' : '#ECECEC'}
      stroke={isOptionSelected ? '#8D48C0' : '#D1C8D9'} //#D1C8D9
      strokeOpacity="1"
    />
    <path
      d="M14.3713 25.7366L21.2413 18.8516L14.3713 11.9666L16.4863 9.85156L25.4863 18.8516L16.4863 27.8516L14.3713 25.7366Z"
      fillOpacity={isOptionSelected ? '.85' : '.5'}
      fill={isOptionSelected ? '#492365' : 'black'}
    />
  </svg>
);

export default CircleArrow;

import React from 'react';
import MedicationCardError from './MedicationCardError';
import { Field } from 'react-final-form';
import { Card, CardBody, CardImg } from 'reactstrap';
import {
  required,
  daySupplyRequiredError,
  dosageIsRequiredError
} from '../../../../utilities/Validators';

type Dosage = {
  id: string;
  ndc: string;
  unit_of_measure: string;
  description: string;
  days_supplies: Array<{
    id: string;
    number_of_days: number;
    description: string;
  }>;
  dosage_details: Array<{
    id: string;
    name: string;
    description: string;
    sort_order: number;
  }>;
};

type Medication = {
  id: string;
  name: string;
  imagePath: string;
  dosages: Array<Dosage> | null;
};

interface IProps {
  onMedicationClickFunc(value: Medication): void;
  onDosageClickFunc(value: any): void;
  medication: Medication;
  selectedDosageId?: string | null;
  medicationSelected?: Medication | null;
}

const MedicationCard = ({
  onMedicationClickFunc,
  onDosageClickFunc,
  medication,
  selectedDosageId,
  medicationSelected
}: IProps) => {
  const activeStyles = {
    borderRadius: '3px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#8d48c0',
    boxShadow: '0 0 0 0.2rem rgba(73, 35, 101, 0.25)'
  };

  const inActiveStyles = {
    borderRadius: '3px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightgrey'
  };

  const getStyles = (id: string) => {
    return medicationSelected && medicationSelected.id === id
      ? activeStyles
      : inActiveStyles;
  };

  const onMedicationClick = () => {
    onMedicationClickFunc(medication);
  };

  const onDosageClick = (e: any) => {
    onDosageClickFunc(e.target.value);
  };

  const isMedicationSelected = (dosage: Dosage) => {
    return (
      medicationSelected &&
      medicationSelected.id === medication.id &&
      selectedDosageId &&
      selectedDosageId === dosage.id
    );
  };

  const daySupplyRequired = required(daySupplyRequiredError);
  const dosageRequired = required(dosageIsRequiredError);

  const maybeRenderDaySupply = (index: number) => {
    return index === 0 ? <strong>DAYS SUPPLY</strong> : null;
  };

  const maybeRenderDrugDaysSupply = (
    dosage: Dosage,
    supply: { id: string; number_of_days: number; description: string },
    index: number
  ): false | '' | JSX.Element | null | undefined => {
    return isMedicationSelected(dosage) ? (
      <React.Fragment key={supply.id}>
        {maybeRenderDaySupply(index)}
        <div className="custom-control custom-radio">
          <Field
            id={supply.id}
            className="custom-control-input"
            name="drugDaysSupplyId"
            component="input"
            type="radio"
            value={supply.id as any}
            validate={daySupplyRequired}
          />{' '}
          <label
            className="custom-control-label"
            htmlFor={supply.id}
            key={supply.id}
          >
            <strong>{supply.description}</strong>
          </label>
        </div>
      </React.Fragment>
    ) : null;
  };

  const renderDosages = () => {
    return medication.dosages!.map(dosage => (
      <div className="custom-control custom-radio" key={dosage.id}>
        <Field
          id={dosage.id}
          className="custom-control-input"
          name="drugDosageId"
          component="input"
          type="radio"
          value={dosage.id as any}
          onClick={onDosageClick}
          validate={dosageRequired}
        />{' '}
        <label className="custom-control-label" htmlFor={dosage.id}>
          {dosage.dosage_details.map(detail => (
            <span key={detail.id}>
              <strong>{detail.name}</strong>
              <br />
              {detail.description}
              <br />
            </span>
          ))}
        </label>
      </div>
    ));
  };

  return (
    <Card
      style={getStyles(medication.id)}
      onClick={onMedicationClick}
      className="medication-card"
    >
      <div className="img-wrapper">
        <CardImg
          src={require(`../../../../img/${medication.imagePath}`)}
          alt={medication.name}
        />
      </div>
      <CardBody>
        <strong>DOSE</strong>
        {renderDosages()}
        <br />

        {medication
          .dosages!.filter(dosage => dosage.id === selectedDosageId)
          .map(dosage =>
            dosage.days_supplies.map((supply, index) =>
              maybeRenderDrugDaysSupply(dosage, supply, index)
            )
          )}
      </CardBody>
      <MedicationCardError name="drugDosageId" />
      <MedicationCardError name="drugDaysSupplyId" />
    </Card>
  );
};

export default MedicationCard;

import React from 'react';
import Wizard from './Wizard';
import PrescriberLocationInfo from './enrollmentFormParts/PrescriberLocationInfo';
import PatientInfo from './enrollmentFormParts/PatientInfo';
import MedicationInfo from './enrollmentFormParts/MedicationInfo';
import Loader from './Loader';
import * as ErrorHandler from '../../utilities/ErrorHandler';
import api from '../../api';
import { withRouter, RouteComponentProps } from 'react-router';
import { EnrollmentInfo } from '../../model/EnrollmentInfo';
import { EligibilityAndCoverageResponse } from '../../model/EligibilityAndCoverageResponse';
import { EnrollmentAttempt } from '../../model/EnrollmentAttempt';

interface IEnrollmentFormProps extends RouteComponentProps {
  initialValues: EnrollmentInfo;
}

class EnrollmentForm extends React.Component<IEnrollmentFormProps> {
  state = {
    isLoaderOpen: false
  };

  onSubmit = (enrollmentInfo: EnrollmentInfo) => {
    this.setState({ isLoaderOpen: true }, () => {
      api.vertex
        .submitEnrollment(enrollmentInfo)
        .then(result => this.formComplete(enrollmentInfo, result))
        .catch(error => {
          this.setState({ isLoaderOpen: false });
          ErrorHandler.error(error);
        });
    });
  };

  formComplete = (
    enrollmentInfo: EnrollmentInfo,
    result: EligibilityAndCoverageResponse
  ) => {
    this.setState({ isLoaderOpen: false }, () => {
      const enrollmentAttempt = new EnrollmentAttempt(
        enrollmentInfo,
        result.enrollmentAttemptId,
        result
      );
      this.redirectToCoverageResultsPage(enrollmentAttempt);
    });
  };

  redirectToCoverageResultsPage = (enrollmentAttempt: EnrollmentAttempt) => {
    this.props.history.replace('/coverage-results', {
      enrollmentAttempt
    });
  };

  render() {
    return (
      <React.Fragment>
        <Wizard
          key={this.props.history.location.key}
          onSubmit={this.onSubmit}
          initialValues={this.props.initialValues}
        >
          <PatientInfo />
          <PrescriberLocationInfo />
          <MedicationInfo />
        </Wizard>
        <Loader isOpen={this.state.isLoaderOpen} />
      </React.Fragment>
    );
  }
}

export default withRouter(EnrollmentForm);

import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';

interface IProps {
  patientId: string;
  userHasAccessToPatientLocation: boolean;
}

const EnrollmentExistsModal = (props: IProps) => {
  // const [modal, setModal] = useState(props.patientId !== null);

  // See TODO below
  // const toggle = () => {
  //   setModal(false);
  // };
  return (
    <React.Fragment>
      <Modal
        isOpen={props.patientId !== null}
        className="exists-modal modal-lg"
        backdrop={true}
      >
        <ModalHeader className="modal-header text-center justify-content-center">
          This patient already exists in the system.
        </ModalHeader>
        {props.userHasAccessToPatientLocation ? (
          <>
            <ModalBody>
              Go to the patient summary page to create a new case for an
              existing patient or enroll a new patient.
            </ModalBody>
            <ModalFooter className="flex-row-reverse">
              {/* TODO: This button is being commented out for now due to issues being caused by issues described by VRTXHUBV2-468 
                      at some point, we may reintroduce it with fixed functionality */}
              {/* <Button color="secondary" onClick={this.toggle}>
              New Enrollment
            </Button> */}
              <Button
                tag={Link}
                color="primary"
                to={`/Enrollment/${props.patientId}`}
              >
                Patient summary
              </Button>
            </ModalFooter>
          </>
        ) : (
          <>
            <ModalBody className="text-center">
              They are aligned to a center you do not have access to. Please
              contact Vertex GPS&trade;{' '}
              <a href="tel:877-752-5933">877-752-5933</a>
            </ModalBody>
            <ModalFooter className="justify-content-center">
              <Button tag={Link} color="primary" to={'/homepage'}>
                Ok, I understand
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default EnrollmentExistsModal;

import { IAuthService } from './IAuthService';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { CognitoIdToken } from 'amazon-cognito-identity-js';
import { log } from '../utilities/ErrorHandler';
import { PasswordResetRequiredException } from '../constants/LoginNotificationCodes';
import { isTruthy } from './JsHelpers';

export class AuthServiceCognito implements IAuthService {
  errorHandlerFuncByErrorCode = new Map([
    [
      PasswordResetRequiredException,
      (err: any) => {
        throw err;
      }
    ],
    [
      undefined,
      (err: any) => {
        log(err);
      }
    ]
  ]);

  public getRefreshToken = async () => {
    const session = await Auth.currentSession();
    if (session.isValid()) {
      return session.getRefreshToken().getToken();
    }

    throw new Error('No active session');
  };

  public getIdToken = async () => {
    const session = await Auth.currentSession();
    if (session.isValid()) {
      return session.getIdToken().getJwtToken();
    }

    throw new Error('No active session');
  };

  public getUserName = async () => {
    const user = await Auth.currentAuthenticatedUser();
    return user.getUsername();
  };

  public loggedIn = async () => {
    try {
      const user: CognitoUser = await Auth.currentAuthenticatedUser();
      return user ? true : false;
    } catch {
      return false;
    }
  };

  public login = async (
    username: string,
    password: string
  ): Promise<boolean> => {
    try {
      const user = await Auth.signIn(this.toLower(username), password);
      return user!!;
    } catch (err) {
      this.errorHandlerFuncByErrorCode.has(err.code)
        ? this.errorHandlerFuncByErrorCode.get(err.code)!(err)
        : this.errorHandlerFuncByErrorCode.get(undefined)!(err);
    }
    return false;
  };

  public logout = () => {
    return Auth.signOut();
  };

  public forgotPassword = (email: string) => {
    return Auth.forgotPassword(this.toLower(email));
  };

  public forgotPasswordSubmit = (
    email: string,
    code: string,
    confirmPassword: string
  ) => {
    return Auth.forgotPasswordSubmit(
      this.toLower(email),
      code,
      confirmPassword
    );
  };

  public userIsAdmin = async () => {
    try {
      const session = await Auth.currentSession();
      return this.hasGroup(session.getIdToken(), 'SoftwareSupport');
    } catch {
      return false;
    }
  };

  public getCurrentUserInfo = async () => {
    return await Auth.currentUserInfo();
  };

  private toLower = (value: string) => {
    // Cognito is case sensitive by default and does not offer a configuration option to make it case-insensitive.  To combat this, we lower all emails before sending them
    return isTruthy(value) ? value!.toLowerCase() : value;
  };

  private hasGroup = (token: CognitoIdToken, groupName: string) => {
    const tokenPayload = token.decodePayload();
    const groups: string[] | undefined = tokenPayload['cognito:groups'];
    if (groups) {
      return groups.indexOf(groupName) >= 0;
    }

    return false;
  };
}

import React from 'react';
import { Media } from 'reactstrap';
import cf_logo from '../../img/CareformLogo_Color.png';
import TermsModal from '../parts/TermsModal';

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
          <Media
            object
            src={cf_logo}
            alt="Careform Logo"
            className="cf-footer__logo"
          />
          <p>
            Powered by Careform v8.11.0 &copy;&nbsp;2022{' '}
            <TermsModal
              includeLink={true}
              backdrop={true}
              headerToggle={true}
              includeFooter={false}
            />
            <br className="rwd-br" />
            <span className="ver"></span>
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;

import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

interface IConfirmationDialogueProps {
  headerText: string;
  bodyText: string;
  confirmText: string;
  denyText: string;
  onSelection: (yesNo: boolean) => void;
}

const ConfirmationDialogue = (props: IConfirmationDialogueProps) => {
  const onSelectYes = () => {
    props.onSelection(true);
  };

  const onSelectNo = () => {
    props.onSelection(false);
  };

  return (
    <Modal isOpen={true} className="modal-lg exists-modal" backdrop={true}>
      <ModalHeader className="modal-header text-center justify-content-center">
        {props.headerText}
      </ModalHeader>
      <ModalBody className="pl-5 pr-5">{props.bodyText}</ModalBody>
      <ModalFooter className="justify-content-spacearound btn-group mt-2">
        <Button
          role="button"
          color="primary"
          className="ml-4 mr-3"
          onClick={onSelectYes}
        >
          {props.confirmText}
        </Button>
        <Button
          role="button"
          color="primary"
          className="ml-3 mr-4"
          onClick={onSelectNo}
        >
          {props.denyText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationDialogue;

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import LoginPage from './components/pages/LoginPage';
import ForgotPasswordPage from './components/pages/ForgotPasswordPage';
import SetPasswordPage from './components/pages/SetPasswordPage';
import HomePage from './components/pages/HomePage';
import Footer from './components/parts/Footer';
import NewCasePage from './components/pages/NewCasePage';
import RegistrationPage from './components/pages/RegistrationPage';
import Verify from './components/style_guide/Verify';
import Container from './components/style_guide/Container';
import Summary from './components/style_guide/Summary';
import CoverageResultsPage from './components/pages/CoverageResultsPage';
import ManualProcessPage from './components/pages/ManualProcessPage';
import CaseSummaryPage from './components/pages/CaseSummaryPage';
import StyleGuideCoverageResultsPage from './components/style_guide/StyleGuideCoverageResultsPage';
import KitchenSink from './components/style_guide/KitchenSink';
import Pin from './components/style_guide/Pin';
import StyleGuideRegister from './components/style_guide/Register';
import Prescription from './components/style_guide/Prescription';
import NotFoundPage from './components/pages/NotFoundPage';
import ErrorPage from './components/pages/ErrorPage';
import PinPage from './components/pages/PinPage';
import AdminPage from './components/pages/AdminPage';
import Authenticator from './components/parts/Authenticator';
import PriorAuthRedirect from './components/pages/PriorAuthRedirect';
import './App.scss';
import { AuthServiceCognito } from './utilities/AuthServiceCognito';
import EnrollmentCompletePage from './components/pages/EnrollmentCompletePage';
import { initializeAnalytics } from './utilities/PendoAnalytics';
import Loader from './components/parts/Loader';

class App extends Component {
  constructor(props) {
    super(props);
    this.Auth = props.authSvc || new AuthServiceCognito();
    this.state = {
      isAdmin: null
    };
  }

  maybeRenderStyleGuideRoutes = () => {
    return process.env.NODE_ENV === 'development'
      ? // an array is returned here because, even though a Fragment
        // does not result in another level of nesting being rendered to the DOM,
        // react router does not currently have support for parsing nested levels
        // to determine whether or not that level is only a fragment
        // (and only one level of routes is supported within a Switch)
        // https://github.com/ReactTraining/react-router/issues/5785
        // keys are necessary due to the array structure
        [
          <Route
            exact
            path="/style_guide/verify"
            render={props => (
              <Authenticator
                key={'Auth-' + Verify.name}
                authSvc={this.Auth}
                component={<Verify {...props} />}
                location={props.location}
              />
            )}
            key={0}
          />,
          <Route
            exact
            path="/style_guide/container"
            render={props => (
              <Authenticator
                key={'Auth-' + Container.name}
                authSvc={this.Auth}
                component={<Container {...props} />}
                location={props.location}
              />
            )}
            key={1}
          />,
          <Route
            exact
            path="/style_guide/summary"
            render={props => (
              <Authenticator
                key={'Auth-' + Summary.name}
                authSvc={this.Auth}
                component={<Summary {...props} />}
                location={props.location}
              />
            )}
            key={2}
          />,
          <Route
            exact
            path="/style_guide/coverage-results"
            render={props => (
              <Authenticator
                key={'Auth-' + StyleGuideCoverageResultsPage.name}
                authSvc={this.Auth}
                component={<StyleGuideCoverageResultsPage {...props} />}
                location={props.location}
              />
            )}
            key={3}
          />,
          <Route
            exact
            path="/style_guide/pin"
            render={props => (
              <Authenticator
                key={'Auth-' + Pin.name}
                authSvc={this.Auth}
                component={<Pin {...props} />}
                location={props.location}
              />
            )}
            key={4}
          />,
          <Route
            exact
            path="/style_guide/kitchen-sink"
            render={props => (
              <Authenticator
                key={'Auth-' + KitchenSink.name}
                authSvc={this.Auth}
                component={<KitchenSink {...props} />}
                location={props.location}
              />
            )}
            key={5}
          />,
          <Route
            exact
            path="/style_guide/prescription"
            render={props => (
              <Authenticator
                key={'Auth-' + Prescription.name}
                authSvc={this.Auth}
                component={<Prescription {...props} />}
                location={props.location}
              />
            )}
            key={6}
          />,
          <Route
            exact
            path="/style_guide/register"
            component={StyleGuideRegister}
            key={7}
          />
        ]
      : null;
  };

  componentDidMount() {
    this.Auth.userIsAdmin()
      .then(isAdmin => this.setState({ isAdmin }))
      .then(
        this.Auth.getCurrentUserInfo().then(userInfo => {
          if (userInfo) {
            const {
              attributes: { 'custom:userid': userId, email, name, family_name }
            } = userInfo;
            initializeAnalytics(userId, email, name, family_name);
          } else {
            initializeAnalytics();
          }
        })
      );
  }

  render() {
    // Do not render route until isAdmin has been resolved
    if (this.state.isAdmin === null) {
      return <Loader />;
    }

    return (
      <div className="App">
        <Switch>
          <Route
            exact
            path="/"
            render={renderProps => (
              <LoginPage {...renderProps} authSvc={this.Auth} />
            )}
          />
          <Route exact path="/register" component={RegistrationPage} />
          <Route
            exact
            path="/forgot-password"
            render={renderProps => (
              <ForgotPasswordPage {...renderProps} authSvc={this.Auth} />
            )}
          />
          <Route
            exact
            path="/set-password"
            render={renderProps => (
              <SetPasswordPage {...renderProps} authSvc={this.Auth} />
            )}
          />
          <Route
            exact
            path="/Enrollment/:enrollmentAttemptId/SignaturePin"
            render={props => (
              <Authenticator
                key={'Auth-' + PinPage.name}
                authSvc={this.Auth}
                component={<PinPage {...props} />}
                location={props.location}
              />
            )}
          />
          <Route
            exact
            path="/Enrollment/:enrollmentAttemptId/Complete"
            render={props => (
              <Authenticator
                key={'Auth-' + EnrollmentCompletePage.name}
                authSvc={this.Auth}
                component={<EnrollmentCompletePage {...props} />}
                location={props.location}
              />
            )}
          />
          <Route
            exact
            path="/homepage"
            render={props => (
              <Authenticator
                key={`Auth-${
                  this.state.isAdmin ? AdminPage.name : HomePage.name
                }`}
                authSvc={this.Auth}
                component={
                  this.state.isAdmin ? (
                    <AdminPage {...props} />
                  ) : (
                    <HomePage {...props} />
                  )
                }
                location={props.location}
              />
            )}
          />
          <Route
            exact
            path="/PriorAuth/:priorAuthId"
            render={props => (
              <Authenticator
                key={'Auth-' + PriorAuthRedirect.name}
                authSvc={this.Auth}
                component={<PriorAuthRedirect {...props} />}
                location={props.location}
              />
            )}
          />
          <Route
            exact
            path="/new-case"
            render={props => (
              <Authenticator
                key={'Auth-' + NewCasePage.name}
                authSvc={this.Auth}
                component={<NewCasePage {...props} />}
                location={props.location}
              />
            )}
          />
          <Route
            exact
            path="/coverage-results"
            render={props => (
              <Authenticator
                key={'Auth-' + CoverageResultsPage.name}
                authSvc={this.Auth}
                component={<CoverageResultsPage {...props} />}
                location={props.location}
              />
            )}
          />
          <Route
            exact
            path="/manual-process"
            render={props => (
              <Authenticator
                key={'Auth-' + ManualProcessPage.name}
                authSvc={this.Auth}
                component={<ManualProcessPage {...props} />}
                location={props.location}
              />
            )}
          />
          <Route
            exact
            path="/enrollment/:patientId"
            render={props => (
              <Authenticator
                key={'Auth-' + CaseSummaryPage.name}
                authSvc={this.Auth}
                component={<CaseSummaryPage {...props} />}
                location={props.location}
              />
            )}
          />
          <Route exact path="/error" component={ErrorPage} />
          {/* Style Guide Routes */}
          {this.maybeRenderStyleGuideRoutes()}
          <Route
            render={props => (
              <Authenticator
                key={'Auth-' + NotFoundPage.name}
                authSvc={this.Auth}
                component={<NotFoundPage {...props} />}
                location={props.location}
              />
            )}
          />
        </Switch>
        <Footer />
      </div>
    );
  }
}

export default App;

import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

class DeleteConfirmationModal extends React.Component {
  confirm = () => {
    this.props.closeConfirmationModal(true, this.props.attachmentId);
  };

  cancel = () => {
    this.props.closeConfirmationModal(false);
  };

  render() {
    return (
      <Modal
        isOpen={this.props.confirmationModalIsOpen}
        toggle={this.cancel}
        className={this.props.className}
        autoFocus={false}
      >
        <ModalHeader toggle={this.cancel} className="modal-header--no-border" />
        <ModalBody className="text-center mb-3">
          <h1>Delete File</h1>
          <p>File will be deleted. Are you sure you want to do this?</p>

          <button
            type="button"
            className="btn btn-primary btn-min-width mt-2 mr-1"
            onClick={this.confirm}
            autoFocus={false}
          >
            Ok
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-min-width mt-2"
            onClick={this.cancel}
            autoFocus={true}
          >
            Cancel
          </button>
        </ModalBody>
      </Modal>
    );
  }
}

export default DeleteConfirmationModal;

import React from 'react';
import { CardFooter } from 'reactstrap';
import InitiateEpa from '../caseSummaryParts/InitiateEpa';
import { isTruthy } from '../../../utilities/JsHelpers';
import { hidePAMosaicStatuses } from '../../../utilities/StatusHelpers';

const hidePAFooter = (
  isManualInsurance,
  mosaicIntegration,
  epaNotSupported
) => {
  return (
    isManualInsurance ||
    epaNotSupported ||
    (isTruthy(mosaicIntegration) &&
      isTruthy(mosaicIntegration.paRequestStatus) &&
      hidePAMosaicStatuses.includes(mosaicIntegration.paRequestStatus))
  );
};

const maybeRenderPAFooter = props => {
  const {
    isManualInsurance,
    mosaicIntegration,
    priorAuthorization,
    enrollmentRequestId,
    updatePriorAuth,
    setEpaInitiationAlerts,
    epaNotSupported,
    showRenewEpa
  } = props;

  return hidePAFooter(
    isManualInsurance,
    mosaicIntegration,
    epaNotSupported
  ) ? null : (
    <CardFooter>
      <InitiateEpa
        priorAuthorization={priorAuthorization}
        enrollmentRequestId={enrollmentRequestId}
        updatePriorAuth={updatePriorAuth}
        setEpaInitiationAlerts={setEpaInitiationAlerts}
        epaNotSupported={epaNotSupported}
        showRenewEpa={showRenewEpa}
      />
    </CardFooter>
  );
};

const PAFooter = props => maybeRenderPAFooter(props);

export default PAFooter;

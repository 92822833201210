import React from 'react';
import { CardBody } from 'reactstrap';
import { dataNotAvailable } from '../../constants/StringConstants';
import { getTruthyValueOrDefault } from '../../utilities/JsHelpers';

class PatientCard extends React.Component {
  render() {
    const {
      props: {
        resultFirstName,
        resultLastName,
        resultGender,
        resultCity,
        resultState,
        resultBirthDate,
        resultZipCode
      }
    } = this;

    return (
      <React.Fragment>
        <CardBody>
          <h6>Patient Details</h6>
          <dl>
            <dt>Patient Name:</dt>
            <dd>
              {getTruthyValueOrDefault(resultFirstName, dataNotAvailable)}{' '}
              {getTruthyValueOrDefault(resultLastName, dataNotAvailable)}
            </dd>
          </dl>
          <dl>
            <dt>Gender:</dt>
            <dd>{getTruthyValueOrDefault(resultGender, dataNotAvailable)}</dd>
          </dl>
          <dl>
            <dt>Date of Birth:</dt>
            <dd>
              {getTruthyValueOrDefault(resultBirthDate, dataNotAvailable)}
            </dd>
          </dl>
          <dl>
            <dt>City, State:</dt>
            <dd>
              {getTruthyValueOrDefault(resultCity, dataNotAvailable)},{' '}
              {getTruthyValueOrDefault(resultState, dataNotAvailable)}
            </dd>
          </dl>
          <dl>
            <dt>Zip Code:</dt>
            <dd>{getTruthyValueOrDefault(resultZipCode, dataNotAvailable)}</dd>
          </dl>
        </CardBody>
        <hr />
      </React.Fragment>
    );
  }
}

export default PatientCard;

import React from 'react';
import { EligibilityResult } from '../../../model/EligibilityResult';
import { EnrollmentAttempt } from '../../../model/EnrollmentAttempt';
import CoverageResultsMain from '../../parts/coverageResultsParts/CoverageResultsMain';
import CoverageResultsNextButton from '../../parts/coverageResultsParts/CoverageResultsNextButton';
import PatientCard from '../../parts/PatientCard';
import CoverageResultsMoreInformation from '../../parts/coverageResultsParts/CoverageResultsMoreInformation';
import {
  Card,
  CardBody,
  UncontrolledCollapse,
  Button,
  CardHeader,
  CardFooter,
  CardDeck
} from 'reactstrap';
import {
  isTruthy,
  isNonEmptyArray,
  isFalsey
} from '../../../utilities/JsHelpers';
import { ExclamationIcon } from 'mdi-react';

const activeStyles = {
  borderRadius: '3px',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#8d48c0',
  boxShadow: '0 0 0 0.2rem rgba(73, 35, 101, 0.25)'
};
const inActiveStyles = {
  borderRadius: '3px',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: '#d3d3d3'
};

interface IEligibilityAndCoverageResultsSection {
  enrollmentAttempt: EnrollmentAttempt;
  selectedCoverageId: any;
  setSelectedCoverageId: (selectedCoverageId: any) => void;
  setSelectedCoverageName: (selectedCoverageName: string) => void;
  location: any;
}

const EligibilityAndCoverageResultsSection: React.FunctionComponent<IEligibilityAndCoverageResultsSection> = (
  props: IEligibilityAndCoverageResultsSection
) => {
  const getStyles = (coverageId: string) => {
    return isTruthy(props.selectedCoverageId) &&
      props.selectedCoverageId === coverageId
      ? activeStyles
      : inActiveStyles;
  };

  const onCoverageSelectionChange = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    selectedCoverageId: string
  ) => {
    e.preventDefault();

    //If there are two or more coverages shown, changing the coverage selection should be tracked
    const selectedCoverageName =
      props.enrollmentAttempt.eligibilityAndCoverageResponse?.result?.results?.find(
        (element: any) => {
          return element.result_id === selectedCoverageId;
        }
      )?.rx_insurance.pbm_name ?? '';

    props.setSelectedCoverageId(selectedCoverageId);
    props.setSelectedCoverageName(selectedCoverageName);
  };

  const maybeRenderCoverageResultsMoreInformationSection = (
    eligibilityResult: any
  ) => {
    return isTruthy(eligibilityResult?.results) &&
      isNonEmptyArray(eligibilityResult?.results)
      ? eligibilityResult.results!.map((coverageResult: any) =>
          isFalsey(coverageResult.errors)
            ? renderCoverageResultsMoreInformationSection(coverageResult)
            : renderCoverageResultsCouldNotBeObtained(
                eligibilityResult.result_id
              )
        )
      : renderCoverageResultsCouldNotBeObtained(eligibilityResult.result_id);
  };

  const renderCoverageResultsCouldNotBeObtained = (
    eligibilityResultId: string
  ) => {
    return renderError(
      'This Patient’s Complete Coverage could not be obtained.',
      eligibilityResultId
    );
  };

  const renderCoverageResultsMoreInformationSection = (coverageResult: any) => {
    return (
      <CoverageResultsMoreInformation
        key={coverageResult.resultId}
        coverageResult={coverageResult}
      />
    );
  };

  const renderError = (errorText: string, key: string) => {
    return (
      <div key={key} className="notify">
        <div className="notify__item notify__item--aware">
          <ExclamationIcon className="align-middle" size="3em" />
          <div className="content align-middle">
            <h5>{errorText}</h5>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {props.enrollmentAttempt.eligibilityAndCoverageResponse.result.results ? (
        <div className="summary-details pb-5">
          <CardDeck
            key={props.enrollmentAttempt.enrollmentInfo.patientLastName}
            className="summary-body"
          >
            {props.enrollmentAttempt.eligibilityAndCoverageResponse.result.results.map(
              (eligibilityResult: EligibilityResult, i: number) => (
                <Card
                  style={getStyles(eligibilityResult.result_id)}
                  className="summary-content coverage-card"
                  key={eligibilityResult.result_id}
                  onClick={e =>
                    onCoverageSelectionChange(e, eligibilityResult.result_id)
                  }
                >
                  <CardHeader>
                    Option:{' '}
                    <strong>{eligibilityResult.rx_insurance.pbm_name}</strong>
                  </CardHeader>

                  <PatientCard
                    resultFirstName={eligibilityResult.member.first_name}
                    resultLastName={eligibilityResult.member.last_name}
                    resultGender={eligibilityResult.member.gender}
                    resultBirthDate={eligibilityResult.member.birth_date}
                    resultCity={eligibilityResult.member.city}
                    resultState={eligibilityResult.member.state}
                    resultZipCode={eligibilityResult.member.zipcode}
                  />

                  <CardBody>
                    <CoverageResultsMain result={eligibilityResult} />
                  </CardBody>

                  <CardFooter>
                    <Button
                      className="btn-block"
                      color="link"
                      key={eligibilityResult.result_id}
                      id={'toggler-' + i}
                    >
                      More Information
                    </Button>
                  </CardFooter>
                  <UncontrolledCollapse toggler={'#toggler-' + i}>
                    {maybeRenderCoverageResultsMoreInformationSection(
                      eligibilityResult
                    )}
                  </UncontrolledCollapse>
                </Card>
              )
            )}
          </CardDeck>
        </div>
      ) : (
        renderError(
          'The eligibility information for this patient could not be returned, please complete the next few screens.',
          props.enrollmentAttempt.enrollmentInfo.patientLastName
        )
      )}
      <CoverageResultsNextButton
        resultsExist={true}
        coverageSelectionId={props.selectedCoverageId}
        enrollmentInfo={props.enrollmentAttempt.enrollmentInfo}
        enrollmentAttemptId={props.enrollmentAttempt.enrollmentAttemptId}
      />
    </>
  );
};

export default EligibilityAndCoverageResultsSection;

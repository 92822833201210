import React from 'react';
import { Field } from 'react-final-form';
import { ExclamationIcon } from 'mdi-react';
import CaseSummaryNotificationArea from '../caseSummaryParts/CaseSummaryNotificationArea';
import { pinForm } from '../../../constants/StringConstants';

const notifyItems = [
  {
    status: 'aware',
    icon: <ExclamationIcon size="2.625em" />,
    alert: '',
    message:
      'Please be aware that Vertex GPS will not be sending this prescription to the pharmacy'
  }
];

const SignedFormOption = ({ submitError }) => {
  return (
    <div className="mx-3 my-4 p-3">
      <div className="custom-control custom-checkbox">
        <Field
          name="signedForm.willUploadSignedForm"
          id="willUploadSignedForm"
          type="checkbox"
          className="custom-control-input"
          component="input"
        />{' '}
        <label className="custom-control-label" htmlFor="willUploadSignedForm">
          I understand that I am responsible for sending the prescription to the
          pharmacy and the prescription portion of the enrollment form will go
          to Vertex GPS.
        </label>
        {submitError ? (
          <div
            className="error mt-3 d-block text-center"
            data-testid="paper-enrollment-error"
          >
            {pinForm.uploadValidationContent.errorText}
          </div>
        ) : null}
      </div>
      <CaseSummaryNotificationArea items={notifyItems} />
    </div>
  );
};

export default SignedFormOption;

import React from 'react';
import { Field } from 'react-final-form';
import InputError from '../parts/InputError';

interface IProps {
  name: string;
  validator: () => boolean;
  textContent: string;
}

const AcknowledgementBox = (props: IProps) => (
  <div className="col-xs-12 col-lg-12 mt-3">
    <div className="notify">
      <div className="notify__item notify__item--aware">
        <div className="custom-control custom-checkbox">
          <Field
            className="custom-control-input"
            id={props.name}
            name={props.name}
            component="input"
            type="checkbox"
            validate={props.validator}
          />{' '}
          <label className="custom-control-label" htmlFor={props.name}>
            {props.textContent}
          </label>
          <InputError name={props.name} />
        </div>
      </div>
    </div>
  </div>
);

export default AcknowledgementBox;

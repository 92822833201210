import http from './http';

const config = (window as any).vertexConfig;
const vertexhub = http(config.REACT_FETCH_MODE);

export default {
  vertex: {
    submitEnrollment(enrollmentInfo: any) {
      return vertexhub.post(
        `${config.REACT_APP_API_SERVER}/Benefits`,
        enrollmentInfo
      );
    },
    getEnrollment(patientId: string) {
      return vertexhub.get(
        `${config.REACT_APP_API_SERVER}/Enrollment/${patientId}`
      );
    },
    getHomePageFilters() {
      return vertexhub.get(`${config.REACT_APP_API_SERVER}/HomePageFilters`);
    },
    getEnrollments() {
      return vertexhub.get(`${config.REACT_APP_API_SERVER}/Enrollments`);
    },
    getSpecialtyPharmacies() {
      return vertexhub.get(
        `${config.REACT_APP_API_SERVER}/SpecialtyPharmacies`
      );
    },
    getStates() {
      return vertexhub.get(`${config.REACT_APP_API_SERVER}/States`);
    },
    getMedications() {
      return vertexhub.get(`${config.REACT_APP_API_SERVER}/Medications`);
    },
    getPrescribers() {
      return vertexhub.get(`${config.REACT_APP_API_SERVER}/Prescribers`);
    },
    getLocations() {
      return vertexhub.get(`${config.REACT_APP_API_SERVER}/Locations`);
    },
    login(url: string, body: any) {
      return vertexhub.postFormData(url, body);
    },
    refreshToken(url: string, body: any) {
      return vertexhub.postFormData(url, body, true);
    },
    getPatientDetails(patientId: string) {
      return vertexhub.get(
        `${config.REACT_APP_API_SERVER}/Patient/${patientId}`
      );
    },
    getAttachment(attachmentId: string, enrollmentRequestId: string) {
      return vertexhub.getFile(
        `${config.REACT_APP_API_SERVER}/Attachment/${enrollmentRequestId}/GetAttachment/${attachmentId}`
      );
    },
    initiateEpa(enrollmentRequestId: string) {
      return vertexhub.post(
        `${config.REACT_APP_API_SERVER}/Epa/${enrollmentRequestId}/InitiateEpa`
      );
    },
    getLatestEpaStatus(enrollmentRequestId: string) {
      return vertexhub.get(
        `${config.REACT_APP_API_SERVER}/Epa/${enrollmentRequestId}/status`
      );
    },
    createEnrollmentForm(attachmentType: number, updateEnrollmentInfo: any) {
      return vertexhub.post(
        `${config.REACT_APP_API_SERVER}/Enrollment/CreateEnrollmentForm?attachmentType=${attachmentType}`,
        updateEnrollmentInfo
      );
    },
    getSignatureUrl(
      enrollmentAttemptId: string,
      excludePatientConsent: boolean
    ) {
      return vertexhub.postFormData(
        `${config.REACT_APP_API_SERVER}/Enrollment/${enrollmentAttemptId}/SignatureUrl?returnRoute=${window.location.origin}/Enrollment/${enrollmentAttemptId}/complete&excludePatientConsent=${excludePatientConsent}`
      );
    },
    saveSignatureEvent(enrollmentAttemptId: string, signatureEvent: string) {
      return vertexhub.postFormData(
        `${config.REACT_APP_API_SERVER}/Enrollment/${enrollmentAttemptId}/SignatureEvent?signatureEvent=${signatureEvent}`
      );
    },
    openEpaPortal(
      priorAuthorizationId: string,
      refreshToken: string,
      uriEncodedUserName: string
    ) {
      window.open(
        `${config.REACT_APP_EPA_PORTAL}${priorAuthorizationId}/questionnaire?refreshToken=${refreshToken}&userName=${uriEncodedUserName}`,
        '_blank'
      );
    },
    checkPin(formData: any) {
      return vertexhub.postFormData(
        `${config.REACT_APP_API_SERVER}/pin`,
        formData
      );
    },
    setPin(data: any) {
      return vertexhub.patchFormData(
        `${config.REACT_APP_API_SERVER}/pin`,
        data
      );
    },
    checkTermsOfUse() {
      return vertexhub.get(`${config.REACT_APP_API_SERVER}/admin/TermsOfUse`);
    },
    acceptTerms() {
      return vertexhub.post(`${config.REACT_APP_API_SERVER}/admin/TermsOfUse`);
    },
    uploadAttachment(enrollmentRequestId: string, file: any) {
      const formData = new FormData();
      formData.append('file', file);
      return vertexhub.postFormData(
        `${config.REACT_APP_API_SERVER}/Attachment/Upload/${enrollmentRequestId}`,
        formData
      );
    },
    getPatientUserAccessByDemographics(
      first: string,
      last: string,
      zip: string,
      dob: string,
      gender: string
    ) {
      return vertexhub.get(
        `${
          config.REACT_APP_API_SERVER
        }/Enrollment?firstName=${encodeURIComponent(
          first
        )}&lastName=${encodeURIComponent(
          last
        )}&zipCode=${zip}&dob=${dob}&gender=${gender}`
      );
    },
    getAttachmentList(enrollmentRequestId: string) {
      return vertexhub.get(
        `${config.REACT_APP_API_SERVER}/Attachment/${enrollmentRequestId}`
      );
    },
    deleteAttachment(attachmentId: string, enrollmentRequestId: string) {
      return vertexhub.delete(
        `${config.REACT_APP_API_SERVER}/Attachment/${attachmentId}/${enrollmentRequestId}`
      );
    },
    getPatientIdFromPriorAuthId(priorAuthorizationId: string) {
      return vertexhub.get(
        `${config.REACT_APP_API_SERVER}/PriorAuth/${priorAuthorizationId}/EnrollmentId`
      );
    },
    getCaseManagerByEnrollmentId(enrollmentId: string) {
      return vertexhub.get(
        `${config.REACT_APP_API_SERVER}/Enrollment/${enrollmentId}/CaseManager`
      );
    },
    verifyAccessBeforeLoginSuccess(username: string) {
      return vertexhub.get(
        `${
          config.REACT_APP_API_SERVER
        }/Login/Verify?emailAddress=${encodeURIComponent(username)}`
      );
    },
    incrementFailedLoginAttempts(username: string) {
      return vertexhub.post(
        `${config.REACT_APP_API_SERVER}/Login/IncrementFailedLoginAttempts/`,
        username
      );
    },
    resetAllFailedLoginAttempts(username: string) {
      return vertexhub.post(
        `${config.REACT_APP_API_SERVER}/Login/ResetAllFailedLoginAttempts/`,
        username
      );
    },
    updateLastAccessBeforeLoginSuccess(username: string) {
      return vertexhub.patch(
        `${config.REACT_APP_API_SERVER}/Login/UpdateLastAccessAfterLoginSuccess/`,
        username
      );
    },
    getTypeaheadCenters() {
      return vertexhub.get(
        `${config.REACT_APP_API_SERVER}/Registration/GetTypeaheadCenters`
      );
    },
    submitRegistration(registrationInfo: any) {
      return vertexhub.post(
        `${config.REACT_APP_API_SERVER}/Registration`,
        registrationInfo
      );
    },
    updateEnrollment(updateEnrollmentInfo: any) {
      return vertexhub.patch(
        `${config.REACT_APP_API_SERVER}/Enrollment`,
        updateEnrollmentInfo
      );
    },
    updateCoverageSelectionId(
      enrollmentAttemptId: string,
      coverageSelectionId: string
    ) {
      return vertexhub.patch(
        `${config.REACT_APP_API_SERVER}/Benefits/${enrollmentAttemptId}/${coverageSelectionId}`
      );
    },
    getEnrolledPatientReports() {
      return vertexhub.get(
        `${config.REACT_APP_API_SERVER}/Report/EnrolledPatientReportList`
      );
    },
    getPatientStatusReports() {
      return vertexhub.get(
        `${config.REACT_APP_API_SERVER}/Report/PatientStatusReportList`
      );
    },
    downloadReport(reportId: string) {
      return vertexhub.getFile(
        `${config.REACT_APP_API_SERVER}/Report/${reportId}`
      );
    }
  }
};

import React from 'react';

export const FilterIcon = (isActive: boolean) =>
  isActive ? (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0.810822L6.23334 11.8639L12.4667 0.810822H0Z"
        fill="#492365"
      />
    </svg>
  ) : (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0.810822L6.23334 11.8639L12.4667 0.810822H0ZM2.3375 2.19245H10.1292L6.23334 9.10061L2.3375 2.19245Z"
        fill="#6b6b6b"
      />
    </svg>
  );

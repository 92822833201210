import { Field } from 'react-final-form';
import React from 'react';
import InputError from './InputError';

interface IProps {
  validator: (value: string) => Boolean;
  options: Array<any>;
  optionMap: { key: string; value: string };
  name: string;
}

const DropdownSelect = (props: IProps) => (
  <div className="col-xs-12 col-lg-6">
    <label>
      {props.name}:
      <Field
        className="form-control form-control-sm"
        name={props.optionMap.key}
        component="select"
        id={props.name}
        validate={props.validator}
      >
        <option value="">Select</option>
        {props.options.map(option => (
          <option
            key={option[props.optionMap.key]}
            value={option[props.optionMap.key]}
          >
            {option[props.optionMap.value]}
          </option>
        ))}
      </Field>
    </label>
    <InputError name={props.optionMap.key} />
  </div>
);

export default DropdownSelect;

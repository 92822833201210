import React from 'react';
import classnames from 'classnames';
import { isTruthy } from '../../utilities/JsHelpers';

interface IPillProps {
  status: string;
  drugName: string;
}

const StatusPill = (props: IPillProps) => {
  return (
    <div
      className={classnames('therapy-status', {
        'two-tone': isTruthy(props.status)
      })}
    >
      <span>{props.drugName}</span>
      {props.status && (
        <>
          <br />
          <span>{props.status}</span>
        </>
      )}
    </div>
  );
};

export default StatusPill;

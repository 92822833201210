import React from 'react';
import Wizard from '../Wizard';
import MutationsForm from './medicationInfoParts/MutationsForm';
import MedicationCardDeck from './medicationInfoParts/MedicationCardDeck';
import RefillsInput from './medicationInfoParts/RefillsInput';
import ShouldDispenseAsWrittenRadioSelect from './medicationInfoParts/ShouldDispenseAsWrittenRadioSelect';
import SpecialInstructionsInput from './medicationInfoParts/SpecialInstructionsInput';

interface IProps {
  onMedicationSelectedChanged(): void | null;
}

const MedicationInfo = ({ onMedicationSelectedChanged }: IProps) => {
  return (
    <Wizard.Page>
      <h1>
        <span>Medication Information</span>
      </h1>
      <MutationsForm />
      <MedicationCardDeck
        onMedicationSelectedChanged={onMedicationSelectedChanged}
      />
      <br />
      <RefillsInput />
      <ShouldDispenseAsWrittenRadioSelect />
      <SpecialInstructionsInput />
    </Wizard.Page>
  );
};

MedicationInfo.defaultProps = {
  onMedicationSelectedChanged: () => {}
};

export default MedicationInfo;

import React from 'react';
import {
  CardHeader,
  CardImg,
  CardBody,
  Card,
  CardTitle,
  CardSubtitle
} from 'reactstrap';
import {
  isTruthy,
  getTruthyValueOrDefault
} from '../../../utilities/JsHelpers';
import { dataNotAvailable } from '../../../constants/StringConstants';

const medicationDisplay = medication => {
  // eslint-disable-next-line default-case
  switch (medication) {
    case 'ORKAMBI':
      return 'ORKAMBI (lumacaftor/ivacaftor)';
    case 'SYMDEKO':
      return 'SYMDEKO (tezacaftor/ivacaftor and ivacaftor)';
    case 'KALYDECO':
      return 'KALYDECO (ivacaftor)';
    case 'TRIKAFTA':
      return 'TRIKAFTA (elexacaftor /tezacaftor / ivacaftor and ivacaftor)';
  }
};

const CaseSummaryMedicationCard = ({
  medicationDetails,
  mosaicIntegration
}) => (
  <Card className="summary-content medication-card">
    <CardHeader>Current Medication</CardHeader>
    <div className="img-wrapper">
      <CardImg
        top
        width="100%"
        src={medicationDetails.medicationImage}
        alt="Card image cap"
      />
    </div>
    <CardBody>
      <CardTitle>
        {medicationDisplay(
          getTruthyValueOrDefault(
            medicationDetails.medicationName.toUpperCase()
          ),
          dataNotAvailable
        )}
      </CardTitle>
      <CardSubtitle>Drug Summary</CardSubtitle>
      <dl>
        <dt>Dose</dt>
        <dd>
          {getTruthyValueOrDefault(medicationDetails.dose, dataNotAvailable)}
        </dd>
      </dl>
      <dl>
        <dt>Supply</dt>
        <dd>
          {getTruthyValueOrDefault(medicationDetails.supply, dataNotAvailable)}
        </dd>
      </dl>
      <dl>
        <dt>Refills</dt>
        <dd>
          {getTruthyValueOrDefault(medicationDetails.refills, dataNotAvailable)}
        </dd>
      </dl>
      <dl>
        <dt>Special Instructions</dt>
        <dd>
          {getTruthyValueOrDefault(
            medicationDetails.specialInstructions,
            dataNotAvailable
          )}
        </dd>
      </dl>
      <dl>
        <dt>Previous Treatment</dt>
        <dd>
          {isTruthy(mosaicIntegration)
            ? getTruthyValueOrDefault(
                mosaicIntegration.previousTreatment,
                dataNotAvailable
              )
            : dataNotAvailable}
        </dd>
      </dl>
    </CardBody>
  </Card>
);

export default CaseSummaryMedicationCard;

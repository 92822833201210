import React from 'react';
import { Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { isTruthy } from '../../utilities/JsHelpers';
import StatusPill from './StatusPill';
import DaysOverdueForRefill from './daysOverdueForRefillParts/DaysOverdueForRefill';

class EnrollmentCard extends React.Component {
  onViewCaseClick = (e, patientId) => {
    this.props.viewCase(patientId);
  };

  render() {
    const status = isTruthy(this.props.cardData.therapyStatus)
      ? this.props.cardData.therapyStatus
      : null;
    return (
      <Col lg="4" md="6" xs="12">
        <div className={this.props.cardClass}>
          <div className="card-header">
            <h5 className="card-title">{this.props.cardData.patientName}</h5>
            <StatusPill
              drugName={this.props.cardData.medicationName}
              status={status}
            />
            <dl>
              <dt>DOB:</dt>
              <dd>{this.props.cardData.patientDob}</dd>
            </dl>
            {isTruthy(this.props.cardData.coPayStatus) ? (
              <dl className="float-right">
                <dd>{this.props.cardData.coPayStatus}</dd>
              </dl>
            ) : null}
          </div>
          <div className="card-status">
            <p>{this.props.cardData.status}</p>
          </div>
          {isTruthy(this.props.cardData.priorAuthNote) ? (
            <div className="card-body card-note">
              <p className="card-text">{this.props.cardData.priorAuthNote}</p>
            </div>
          ) : null}
          <div className="card-footer">
            <DaysOverdueForRefill
              daysOverdueForRefill={this.props.cardData.daysOverdueForRefill}
              daysOverdueForRefillStatus={
                this.props.cardData.daysOverdueForRefillStatus
              }
            />
            <div>
              <Link to={'/Enrollment/' + this.props.cardData.patientId}>
                <Button color="outline-primary" className="float-right">
                  View Patient
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </Col>
    );
  }
}

export default EnrollmentCard;

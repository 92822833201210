import React from 'react';
import { Field } from 'react-final-form';

const InputError = ({ name }) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? <span className="error inline-b">{error}</span> : null
    }
  />
);

export default InputError;

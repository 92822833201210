import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  type: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
  smallLabel: PropTypes.string
};

class BubbleTrainItem extends React.Component {
  render() {
    return (
      <div className={`bubble__item bubble__item--${this.props.status}`}>
        <div className="bubble__item__type">{this.props.type}</div>

        {this.props.icon}
        <div className="bubble__item__label">{this.props.label}</div>
        <div className="bubble__item__small-label">{this.props.smallLabel}</div>
      </div>
    );
  }
}

BubbleTrainItem.propTypes = propTypes;

export default BubbleTrainItem;

import React from 'react';
import { Button, Modal, ModalHeader, ModalFooter } from 'reactstrap';

interface IProps {
  isOpen: boolean;
  onOkClicked: () => void;
}

const RenewFailedModal = ({ isOpen, onOkClicked }: IProps) => {
  return (
    <>
      <Modal isOpen={isOpen} className="ok-modal" backdrop={true}>
        <ModalHeader toggle={onOkClicked} />
        <div className="text-center">
          <h2>Something changed</h2>
          <p>
            This patient's information does not match the
            <br />
            previous PA approval. A new case must be created.
          </p>
        </div>
        <ModalFooter>
          <Button color="primary" size="lg" onClick={onOkClicked}>
            OK, I understand
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default RenewFailedModal;

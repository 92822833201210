import React from 'react';

export const ClearIcon = () => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 9.39524L9.0875 10.8077L5.5 7.22024L1.9125 10.8077L0.5 9.39524L4.0875 5.80774L0.5 2.22024L1.9125 0.807739L5.5 4.39524L9.0875 0.807739L10.5 2.22024L6.9125 5.80774L10.5 9.39524Z"
      fill="#5A6771"
    />
  </svg>
);

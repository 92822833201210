import React from 'react';
import { Button, Modal, ModalHeader, ModalFooter } from 'reactstrap';

interface IProps {
  isOpen: boolean;
  onYes(): void;
  onNo(): void;
}

const NewCaseModal = ({ isOpen, onYes, onNo }: IProps) => {
  return (
    <React.Fragment>
      <Modal isOpen={isOpen} className="sure-modal" backdrop={true}>
        <ModalHeader toggle={onNo} />
        <div className="text-center">
          <h2>Are you sure?</h2>
          <p>Creating a new case will override the current case.</p>
        </div>
        <ModalFooter>
          <Button color="primary" size="lg" onClick={onYes}>
            Yes
          </Button>
          <Button color="secondary" size="lg" onClick={onNo}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default NewCaseModal;

import React, { useState, useEffect } from 'react';
import MedicationCard from './MedicationCard';
import { CardDeck } from 'reactstrap';
import Loader from '../../../parts/Loader';
import { error } from '../../../../utilities/ErrorHandler';
import {
  isNullOrUndefined,
  isNonEmptyArray
} from '../../../../utilities/JsHelpers';
import api from '../../../../api';

type Dosage = {
  id: string;
  ndc: string;
  unit_of_measure: string;
  description: string;
  days_supplies: Array<{
    id: string;
    number_of_days: number;
    description: string;
  }>;
  dosage_details: Array<{
    id: string;
    name: string;
    description: string;
    sort_order: number;
  }>;
};

type Medication = {
  id: string;
  name: string;
  imagePath: string;
  dosages: Array<Dosage> | null;
};

interface IProps {
  onMedicationSelectedChanged(): void;
}

const MedicationCardDeck = ({ onMedicationSelectedChanged }: IProps) => {
  const [medications, setMedications] = useState<Array<Medication> | null>(
    null
  );
  const [medicationSelected, setMedicationSelected] = useState<
    Medication | null | undefined
  >(null);
  const [dosageId, setDosageId] = useState<string | null>(null);

  const onMedicationClick = (medicationSelected: Medication) => {
    onMedicationSelectedChanged();
    setMedicationSelected(medicationSelected);
  };

  const onDosageClick = (dosageId: string) => {
    const currentMedication = medications!.find((medication: Medication) =>
      medication.dosages!.find(
        (dosage: { id: string }) => dosage.id === dosageId
      )
    );

    setMedicationSelected(currentMedication);
    setDosageId(dosageId);
  };

  useEffect(() => {
    (async () => {
      try {
        const medications = await api.vertex
          .getMedications()
          .catch(err => error(err));
        setMedications(medications);
      } catch (err) {
        error(err);
      }
    })().catch(err => error(err));
  }, []);

  return isNullOrUndefined(medications) ? (
    <Loader isOpen={true} />
  ) : (
    <>
      <h1 className="mt-4 mb-5">Select Medication and Dosage</h1>
      <CardDeck>
        {isNonEmptyArray(medications)
          ? medications!.map(medication => (
              <MedicationCard
                key={medication.id}
                onMedicationClickFunc={onMedicationClick}
                onDosageClickFunc={onDosageClick}
                medication={medication}
                selectedDosageId={dosageId}
                medicationSelected={medicationSelected}
              />
            ))
          : null}
      </CardDeck>
    </>
  );
};

export default MedicationCardDeck;

import logo from '../../img/Vertex_GPS_logo.svg';
import React, { ChangeEvent } from 'react';
import {
  Container,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormText,
  FormFeedback
} from 'reactstrap';
import Loader from '../parts/Loader';
import { IAuthService } from '../../utilities/IAuthService';
import { isTruthy, isNullOrUndefined } from '../../utilities/JsHelpers';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { AuthServiceCognito } from '../../utilities/AuthServiceCognito';
import { INavService, NavServiceBrowser } from '../../utilities/INavService';
import { withRouter } from 'react-router';
import LoginNotification from '../parts/loginParts/LoginNotification';
import { LoginNotificationItemMap } from '../parts/loginParts/LoginNotifcationItemMap';
import { error } from '../../utilities/ErrorHandler';
import {
  PasswordResetRequiredException,
  PasswordResetSuccess,
  RegistrationReceived,
  AlreadyRegistered
} from '../../constants/LoginNotificationCodes';
import { forbidden } from '../../constants/HttpStatusCodes';
import api from '../../api';

interface IProps extends RouteComponentProps {
  authSvc?: IAuthService;
  navSvc?: INavService;
}

interface IState {
  submitting: boolean;
  email: string;
  password: string;
  validate: {
    emailState: string;
    passwordState: string;
  };
  notificationItem: object;
}

// source: https://alligator.io/react/fancy-forms-reactstrap/
// url: https://github.com/alligatorio/Fancy-Form-Example/blob/master/src/App.js
class LoginPage extends React.Component<IProps, IState> {
  private authSvc: IAuthService;
  private navSvc: INavService;
  // TODO: [VRTXHUBV2-202] This max login attempts constant also exists on the API,
  // ideally, it'd be nice to be able to share the value between client
  // and API in some way. However, there isn't an obvious solution
  // at this time, so a tech story has been created for it and placed
  // in the backlog.
  private maxLoginAttempts: string = '4';

  constructor(props: IProps) {
    super(props);
    this.state = {
      submitting: false,
      email: '',
      password: '',
      validate: {
        emailState: '',
        passwordState: ''
      },
      notificationItem: {}
    };

    this.authSvc = props.authSvc || new AuthServiceCognito();
    this.navSvc = props.navSvc || new NavServiceBrowser();
  }

  componentDidMount() {
    const {
      props: {
        location: { state }
      }
    } = this;

    this.authSvc
      .loggedIn()
      .then(isLoggedIn => {
        if (isLoggedIn && !this.isPreviousLocationSetPasswordPage(state)) {
          this.redirectToApp();
          return;
        }

        if (this.isPreviousLocationSetPasswordPage(state)) {
          this.resetFailedLoginAttempts(state)
            .then(() => this.setState({ submitting: false }))
            .catch(err => error(err));
        }
      })
      .catch(err => error(err));
  }

  private async resetFailedLoginAttempts(state: any) {
    return this.setState({ submitting: true }, async () => {
      await api.vertex.verifyAccessBeforeLoginSuccess(state.email);
    });
  }

  private redirectToApp() {
    const {
      props: {
        location: { state }
      }
    } = this;
    // if the user is coming from the SetPasswordPage,
    // we do not want to return them to it, and will
    // treat their previous location as undefined
    const path = state
      ? isTruthy(this.isPreviousLocationSetPasswordPage(state))
        ? undefined
        : this.setPathToPreviousLocation()
      : undefined;
    this.navSvc.redirectToApp(path);
  }

  private setPathToPreviousLocation() {
    return this.props.location.state.from.pathname;
  }

  private isPreviousLocationSetPasswordPage(state: any): any {
    return isTruthy(state) && isTruthy(state.isPasswordReset) ? true : false;
  }

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value }
    } = event;

    this.setState({
      [name]: value
    } as any);
  };

  handleSignInException = (e: { code: string | number }) => {
    const {
      state: { email }
    } = this;

    e.code === PasswordResetRequiredException
      ? this.redirectToResetPassword(email)
      : this.redirectToGenericError();
  };

  private redirectToResetPassword(email: string) {
    this.props.history.push('/forgot-password', {
      email,
      isPasswordResetRequired: true
    });
  }

  private redirectToGenericError = () => {
    this.props.history.push('/error');
  };

  setInvalidLogin = () => {
    const {
      state: { email }
    } = this;

    api.vertex
      .incrementFailedLoginAttempts(email)
      .then(response => {
        if (response >= this.maxLoginAttempts) {
          this.props.history.push('/forgot-password', {
            email,
            isAccountLocked: true
          });
        }

        this.displayInvalidLogin();
      })
      .catch(err => error(err));
  };

  private displayInvalidLogin() {
    const {
      state: { validate }
    } = this;

    validate.passwordState = 'has-danger';
    this.setState({ validate, submitting: false });
  }

  private submitForm = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const {
      state: { email, password }
    } = this;

    this.setState({ submitting: true }, async () => {
      try {
        if (await this.isAccountLocked(email)) {
          this.props.history.push('/forgot-password', {
            email,
            isAccountLocked: true
          });
          return;
        }

        await this.login(email, password);
      } catch (e) {
        this.handleSignInException(e);
      }
    });
  };

  private async updateLastAccess(email: string) {
    try {
      await api.vertex.updateLastAccessBeforeLoginSuccess(email);
      this.redirectToApp();
    } catch (e) {
      this.redirectToGenericError();
      return;
    }
  }

  private login = async (email: string, password: string) => {
    try {
      const isValidLogin = await this.authSvc.login(email, password);
      if (isValidLogin) {
        this.updateLastAccess(email).catch(err => error(err));
        return;
      }
    } catch (e) {
      this.setState({ submitting: false }, () => this.handleSignInException(e));
      return;
    }

    this.setInvalidLogin();
  };

  private async isAccountLocked(email: string) {
    try {
      await api.vertex.verifyAccessBeforeLoginSuccess(email);
      return false;
    } catch (e) {
      this.setState({ submitting: false });
      if (e.code === forbidden) {
        return true;
      }

      throw e;
    }
  }

  private validateForm = () => {
    const {
      state: { email, password }
    } = this;

    return email.length > 0 && password.length > 0;
  };

  maybeRenderPasswordWasResetNotification = () => {
    const {
      props: {
        location: { state }
      }
    } = this;

    return isTruthy(state) && state.isPasswordReset === true ? (
      <LoginNotification
        item={LoginNotificationItemMap.get(PasswordResetSuccess)}
      />
    ) : null;
  };

  maybeRenderUserRegistrationMessage = () => {
    const {
      props: {
        location: { state }
      }
    } = this;

    if (
      isTruthy(state) &&
      !isNullOrUndefined(state.newUserRegistrationEmailSent)
    ) {
      return state.newUserRegistrationEmailSent === true ? (
        <LoginNotification
          item={LoginNotificationItemMap.get(RegistrationReceived)}
        />
      ) : (
        <LoginNotification
          item={LoginNotificationItemMap.get(AlreadyRegistered)}
        />
      );
    }

    return null;
  };

  render() {
    const {
      state: {
        email,
        password,
        submitting,
        validate: { emailState }
      }
    } = this;

    return (
      <Container className="main login col-xs-12 col-lg-4">
        <Loader isOpen={submitting} />
        <img
          src={logo}
          className="logo"
          alt="Vertex GPS | Guidaince and Patient Support"
        />
        <h1>
          <span>Sign In</span>
        </h1>
        <Form className="form" onSubmit={this.submitForm}>
          <Col>
            <FormGroup>
              <Label>Username</Label>
              <Input
                type="text"
                name="email"
                id="exampleEmail"
                placeholder="Enter your username"
                value={email}
                valid={emailState === 'has-success'}
                invalid={emailState === 'has-danger'}
                onChange={e => {
                  this.handleChange(e);
                }}
              />
              <FormFeedback valid>
                That's a great email you've got there.
              </FormFeedback>
              <FormFeedback>
                Looks like there is an issue with your email. Please input a
                correct email.
              </FormFeedback>
              <FormText> Your username is your email.</FormText>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label htmlFor="examplePassword"> Password </Label>
              <Input
                type="password"
                name="password"
                id="examplePassword"
                placeholder="********"
                value={password}
                invalid={this.state.validate.passwordState === 'has-danger'}
                onChange={e => this.handleChange(e)}
              />
              <FormFeedback>Invalid username or password </FormFeedback>
            </FormGroup>
            {this.maybeRenderPasswordWasResetNotification()}
            {this.maybeRenderUserRegistrationMessage()}
          </Col>
          <FormText>
            <Link
              className="float-left mt-4 mb-4 ml-2 btn btn-link"
              to={'/forgot-password'}
            >
              Forgot Password?
            </Link>
          </FormText>
          <FormText>
            <Link
              className="float-left mt-4 mb-4 ml-2 btn btn-link"
              to={'/register'}
            >
              Register
            </Link>
          </FormText>
          <Button
            color="primary"
            className="float-right mt-4 mb-4 mr-3"
            type="submit"
            disabled={submitting || !this.validateForm()}
          >
            Sign In
          </Button>
        </Form>
      </Container>
    );
  }
}

export default withRouter(LoginPage);

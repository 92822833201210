import { Input } from 'reactstrap';
import React from 'react';
import { FilterModel } from '../../../model/FilterModel';
import classnames from 'classnames';

interface IFilterOptionProps {
  optionName: string;
  onSelected: (filter: FilterModel) => void;
  isSelected: boolean;
}

const FilterOption = (props: IFilterOptionProps) => {
  const onSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(!selected);
    props.onSelected({ label: event.target.name, isSelected: !selected });
  };

  const [selected, setSelected] = React.useState(props.isSelected);

  return (
    <li
      key={props.optionName + '-li'}
      className="custom-control custom-checkbox mt-1 pr-1"
    >
      <Input
        checked={selected}
        id={props.optionName}
        className="custom-control-input form-control"
        component="input"
        type="checkbox"
        name={props.optionName}
        onChange={onSelected}
      />
      <label
        className={classnames('custom-control-label', {
          'text-primary': selected
        })}
        htmlFor={props.optionName}
      >
        {props.optionName}
      </label>
    </li>
  );
};

export default FilterOption;

import React from 'react';
import { Field } from 'react-final-form';

type IProps = {
  id: string;
  value: string;
  text: string;
};

const ShouldDispenseAsWrittenInput = ({ id, value, text }: IProps) => {
  return (
    <div className="custom-control custom-radio custom-control-inline">
      <Field
        id={id}
        className="custom-control-input"
        name="shouldDispenseAsWritten"
        component="input"
        type="radio"
        value={value}
      />{' '}
      <label className="custom-control-label" htmlFor={id}>
        {text}
      </label>
    </div>
  );
};

export default ShouldDispenseAsWrittenInput;

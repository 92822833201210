import React from 'react';
import PropTypes from 'prop-types';

const NotificationItem = ({ status, icon, alert, message }) => (
  <div className="notify">
    <div className={`notify__item notify__item--${status}`}>
      {icon}
      <div className="content">
        <div className="header">{alert}</div>
        <p>{message}</p>
      </div>
    </div>
  </div>
);

const CaseSummaryNotificationArea = ({ items }) => (
  <React.Fragment>
    {items.map(function(v, i) {
      return (
        <NotificationItem
          status={v.status}
          icon={v.icon}
          alert={v.alert}
          message={v.message}
          key={i}
        />
      );
    })}
  </React.Fragment>
);

NotificationItem.propTypes = {
  status: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  alert: PropTypes.string.isRequired,
  message: PropTypes.string
};

CaseSummaryNotificationArea.propTypes = {
  items: PropTypes.array.isRequired
};

export default CaseSummaryNotificationArea;

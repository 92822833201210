import React from 'react';
import { CardHeader, CardBody, Card } from 'reactstrap';
import CaseManager from '../caseSummaryParts/CaseManager';
import {
  isTruthy,
  getTruthyValueOrDefault,
  toTitleCase,
  formatPhoneNumber,
  getFormattedDate
} from '../../../utilities/JsHelpers';
import {
  dataNotAvailable,
  enrolledInCopayAssistance,
  enrolled
} from '../../../constants/StringConstants';

const displayCopayStatus = mosaicIntegration => {
  const copayStatus = getTruthyValueOrDefault(
    mosaicIntegration.copayStatus,
    dataNotAvailable
  );
  return copayStatus === enrolledInCopayAssistance ? enrolled : copayStatus;
};

const CaseSummaryPatientCard = ({
  patientDetails,
  patientId,
  isImportedEnrollment,
  mosaicIntegration,
  caseManagerDetails
}) => (
  <Card className="summary-content patient-card">
    <CardHeader>Patient Details</CardHeader>
    <CardBody>
      <dl>
        <dt>First Name</dt>
        <dd>
          {getTruthyValueOrDefault(
            patientDetails.patientFirstName,
            dataNotAvailable
          )}
        </dd>
      </dl>
      <dl>
        <dt>Last Name</dt>
        <dd>
          {getTruthyValueOrDefault(
            patientDetails.patientLastName,
            dataNotAvailable
          )}
        </dd>
      </dl>
      <dl>
        <dt>DOB</dt>
        <dd>
          {getTruthyValueOrDefault(patientDetails.patientDob, dataNotAvailable)}
        </dd>
      </dl>
      <dl>
        <dt>Gender</dt>
        <dd>
          {getTruthyValueOrDefault(
            toTitleCase(patientDetails.patientGender),
            dataNotAvailable
          )}
        </dd>
      </dl>
      <dl>
        <dt>Primary Contact</dt>
        <dd>
          {getTruthyValueOrDefault(
            patientDetails.primaryContact,
            dataNotAvailable
          )}
        </dd>
      </dl>
      <dl>
        <dt>Primary Phone</dt>
        <dd>
          {getTruthyValueOrDefault(
            formatPhoneNumber(patientDetails.primaryPhone),
            dataNotAvailable
          )}
        </dd>
      </dl>
      <dl>
        <dt>Primary Email</dt>
        <dd>
          {getTruthyValueOrDefault(
            patientDetails.patientEmail,
            dataNotAvailable
          )}
        </dd>
      </dl>
      {!isImportedEnrollment ? (
        <dl>
          <dt>Preferred Pharmacy</dt>
          <dd>
            {getTruthyValueOrDefault(
              patientDetails.specialtyPharmacyName,
              dataNotAvailable
            )}
          </dd>
        </dl>
      ) : null}
      <dl>
        <dt>Copay Assistance</dt>
        <dd>
          {isTruthy(mosaicIntegration)
            ? displayCopayStatus(mosaicIntegration)
            : dataNotAvailable}
        </dd>
      </dl>
      <dl>
        <dt>Copay Id</dt>
        <dd>
          {isTruthy(mosaicIntegration)
            ? getTruthyValueOrDefault(
                mosaicIntegration.copayId,
                dataNotAvailable
              )
            : dataNotAvailable}
        </dd>
      </dl>
    </CardBody>
    <hr />
    {isTruthy(mosaicIntegration) ? (
      <CardBody>
        <dl>
          <dt>Last Shipped Date</dt>
          <dd>
            {isTruthy(mosaicIntegration.shippingDate)
              ? getFormattedDate(mosaicIntegration.shippingDate)
              : dataNotAvailable}
          </dd>
        </dl>
        <dl>
          <dt>Last Shipped Pharmacy</dt>
          <dd>
            {getTruthyValueOrDefault(
              mosaicIntegration.pharmacyName,
              dataNotAvailable
            )}
          </dd>
        </dl>
      </CardBody>
    ) : null}
    <CardHeader>Refill Activity</CardHeader>
    <CardBody>
      <dl>
        <dt>Days Overdue for Refill</dt>
        <dd>
          {isTruthy(mosaicIntegration)
            ? getTruthyValueOrDefault(
                mosaicIntegration.daysOverdueForRefill,
                dataNotAvailable
              )
            : dataNotAvailable}
        </dd>
      </dl>
      <dl>
        <dt>Treatment Status</dt>
        <dd>
          {isTruthy(mosaicIntegration)
            ? getTruthyValueOrDefault(
                mosaicIntegration.therapyStatus,
                dataNotAvailable
              )
            : dataNotAvailable}
        </dd>
      </dl>
      <dl>
        <dt>Treatment Start Date</dt>
        <dd>
          {isTruthy(mosaicIntegration) &&
          isTruthy(mosaicIntegration.treatmentStartDate)
            ? getTruthyValueOrDefault(
                getFormattedDate(mosaicIntegration.treatmentStartDate),
                dataNotAvailable
              )
            : dataNotAvailable}
        </dd>
      </dl>
      <dl>
        <dt>Compliance Rate</dt>
        <dd>
          {isTruthy(mosaicIntegration)
            ? getTruthyValueOrDefault(
                mosaicIntegration.complianceRate,
                dataNotAvailable
              )
            : dataNotAvailable}
        </dd>
      </dl>
      <dl>
        <dt>Last 3 Shipment Dates</dt>
        <dd />
      </dl>
      <dl>
        <dt>Most Recent</dt>
        <dd>
          {isTruthy(mosaicIntegration) &&
          isTruthy(mosaicIntegration.shipmentDateMostRecent)
            ? getTruthyValueOrDefault(
                getFormattedDate(mosaicIntegration.shipmentDateMostRecent),
                dataNotAvailable
              )
            : dataNotAvailable}
        </dd>
      </dl>
      <dl>
        <dt>2nd Prior</dt>
        <dd>
          {isTruthy(mosaicIntegration) &&
          isTruthy(mosaicIntegration.shipmentDate2)
            ? getTruthyValueOrDefault(
                getFormattedDate(mosaicIntegration.shipmentDate2),
                dataNotAvailable
              )
            : dataNotAvailable}
        </dd>
      </dl>
      <dl>
        <dt>3rd Prior</dt>
        <dd>
          {isTruthy(mosaicIntegration) &&
          isTruthy(mosaicIntegration.shipmentDate3)
            ? getTruthyValueOrDefault(
                getFormattedDate(mosaicIntegration.shipmentDate3),
                dataNotAvailable
              )
            : dataNotAvailable}
        </dd>
      </dl>
    </CardBody>
    <CaseManager caseManagerDetails={caseManagerDetails} />
  </Card>
);

export default CaseSummaryPatientCard;

import React from 'react';
import { EnrollmentInfo } from '../../../model/EnrollmentInfo';
import CoverageResultsNextButton from '../../parts/coverageResultsParts/CoverageResultsNextButton';
import { ExclamationIcon } from 'mdi-react';

interface IEligibilityAndCoverageResultsNotFoundSection {
  enrollmentAttemptId: any;
  enrollmentInfo: EnrollmentInfo;
}

const EligibilityAndCoverageResultsNotFoundSection: React.FunctionComponent<IEligibilityAndCoverageResultsNotFoundSection> = (
  props: IEligibilityAndCoverageResultsNotFoundSection
) => {
  const renderError = (errorText: string, key: string) => {
    return (
      <div key={key} className="notify">
        <div className="notify__item notify__item--aware">
          <ExclamationIcon className="align-middle" size="3em" />
          <div className="content align-middle">
            <h5>{errorText}</h5>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderError(
        'The eligibility information for this patient could not be returned, please complete the next few screens.',
        props.enrollmentAttemptId
      )}
      <CoverageResultsNextButton
        resultsExist={false}
        coverageSelectionId={''}
        enrollmentInfo={props.enrollmentInfo}
        enrollmentAttemptId={props.enrollmentAttemptId}
      />
    </>
  );
};

export default EligibilityAndCoverageResultsNotFoundSection;

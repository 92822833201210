import React from 'react';
import { Button } from 'reactstrap';

const NewCaseBtn = ({
  style = {},
  id = 'NewCaseBtn',
  fill = 'none',
  width = '100%',
  className = 'NewCaseBtn',
  viewBox = '0 0 20 20'
}) => (
  <React.Fragment>
    <Button color="link" size="lg" aria-label="New Case" id="actionNewCase">
      <svg
        width={width}
        style={style}
        height={width}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${className || ''}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        transform="rotate(0)"
      >
        <path
          d="M2.54043 4.91675H0.604126V18.9167C0.604126 20.0167 1.47546 20.9167 2.54043 20.9167H16.0946V18.9167H2.54043V4.91675ZM18.0309 0.916748H6.41305C5.34808 0.916748 4.47674 1.81675 4.47674 2.91675V14.9167C4.47674 16.0167 5.34808 16.9167 6.41305 16.9167H18.0309C19.0959 16.9167 19.9672 16.0167 19.9672 14.9167V2.91675C19.9672 1.81675 19.0959 0.916748 18.0309 0.916748ZM17.0627 9.91675H13.1901V13.9167H11.2538V9.91675H7.3812V7.91675H11.2538V3.91675H13.1901V7.91675H17.0627V9.91675Z"
          fill="#5A6771"
          fillOpacity="1"
        />
      </svg>
    </Button>
  </React.Fragment>
);

export default NewCaseBtn;

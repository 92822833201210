import React from 'react';
import NavBar from '../parts/NavBar';
import { isFalsey } from '../../utilities/JsHelpers';
import {
  Card,
  CardBody,
  UncontrolledCollapse,
  Button,
  CardHeader,
  CardFooter,
  CardDeck
} from 'reactstrap';
import { PatientSupportSpecialistLabel } from '../parts/PatientSupportSpecialistLabel';

class StyleGuideCoverageResultsPage extends React.Component {
  activeStyles = {
    borderRadius: '3px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#8d48c0',
    boxShadow: '0 0 0 0.2rem rgba(73, 35, 101, 0.25)'
  };

  inActiveStyles = {
    borderRadius: '3px',
    borderStyle: 'solid',
    borderWidth: '1px'
  };
  getStyles = coverageId => {
    return this.state.selectedCoverageId &&
      this.state.selectedCoverageId === coverageId
      ? this.activeStyles
      : this.inActiveStyles;
  };

  getNotAllResultsHaveResult = results => {
    let notAllResultsHaveResult = false;

    results.forEach(result => {
      if (isFalsey(result.results)) {
        notAllResultsHaveResult = true;
      }
    });

    return notAllResultsHaveResult;
  };

  redirectToManualProcessPage = () => {
    this.props.history.push('/manual-process');
  };

  redirectToCaseSummaryPage = () => {
    this.props.history.push('/case-summary');
  };

  navigateToNext = e => {
    e.preventDefault();
    this.state.noCoverageResultsFound === true
      ? this.redirectToManualProcessPage()
      : this.redirectToCaseSummaryPage();
  };

  renderCoverageResultsOrError = () => {
    return this.renderCoverageResults();
    // return isTruthy(this.props.location.state.coverageResults)
    //   ? this.renderCoverageResults(
    //       this.props.location.state.coverageResults.results
    //     )
    //   : this.renderError();
  };

  renderError = () => {
    return (
      <div className="col=sm-12">
        <h1>
          {' '}
          A <PatientSupportSpecialistLabel /> will be in contact soon
        </h1>
      </div>
    );
  };

  renderCoverageResults = () => {
    const results = [
      {
        result_id: '4d32289b-0b7d-4448-9aba-e3de65894552',
        member: {
          first_name: 'ANGELYNE',
          last_name: 'DELAPLAINE',
          address_1: '901 SAUVBLANC BLVD',
          city: 'PETALUMA',
          state: 'CA',
          zipcode: '94952',
          birth_date: '2012-09-01',
          gender: 'female',
          has_data_changed: 'unknown'
        },
        cardholder: {
          key: '8351ZD',
          first_name: 'ANGELYNE',
          last_name: 'DELAPLAINE'
        },
        rxinsurance: {
          pbm_id: 'T00000000021633',
          pbm_name: 'PBMF',
          plan_id: '918-7',
          plan_name: 'MID-CA PLAN7',
          pbm_member_id: 'PBM-ZZ-T92293 8791%AD',
          formulary_id: 'FSL102',
          message_id: '433657901',
          group_id: 'JW92983',
          group_name: 'JW MID-CA#7',
          bin: '001398',
          pcn: 'PBMF-JW-2784-2',
          coverage_date_start: '2015-08-01',
          coverage_date_end: '2099-12-31',
          segment: 'unknown',
          retail_benefit: 'true',
          retail: {
            out_of_pocket: 'unknown'
          },
          mail_order_benefit: 'true',
          mail_order: {
            out_of_pocket: 'unknown'
          },
          has_ltc_coverage: 'unknown',
          has_sp_coverage: 'true',
          sp: {
            ncpdp_id: 'unknown',
            name: 'unknown'
          }
        },
        results: [
          {
            result_id: 'f5b3f733-a4d6-4fd1-9104-9633969d49cc',
            pharmacy: {
              type: 'Specialty',
              store_name: 'Mail Order Pharmacy 10.6MU NOCS',
              npi: '1356606818',
              ncpdp_id: '1629900',
              address_1: '1629-90 Supply Ln',
              address_2: 'unknown',
              city: 'Chicago',
              state: 'IL',
              zipcode: '60622',
              phone_number: '3122603142'
            },
            rxinsurance: {
              plan_pay_amount: '7339',
              individual_deductible: '350',
              individual_deductible_met: '350',
              individual_deductible_remaining: '0',
              patient_pay_amount: '14036',
              co_insurance_pct: 'unknown',
              out_of_pocket_amount: 'unknown',
              out_of_pocket_max: 'unknown',
              out_of_pocket_met: 'unknown',
              out_of_pocket_remaining: 'unknown',
              pa_required: 'true',
              pa_on_file: 'unknown',
              pa_effective_date: 'unknown',
              pa_expiration_date: 'unknown'
            },
            drug: {
              ndc: '51167020001',
              status: 'Covered',
              quantity: '6',
              days_supply: '30',
              unit_of_measure: 'Kit'
            }
          }
        ]
      },
      {
        result_id: '227ccc14-8af7-41ca-a3ef-9bea1bd397be',
        member: {
          first_name: 'ANGELYNE 2',
          last_name: 'DELAPLAINE 2',
          address_1: '90210 SAUVBLANC BLVD',
          city: 'PETALUMA 2',
          state: 'PA',
          zipcode: '94952',
          birth_date: '2012-09-02',
          gender: 'female',
          has_data_changed: 'unknown'
        },
        cardholder: {
          key: 'unknown',
          first_name: 'ANGELYNE',
          last_name: 'DELAPLAINE'
        },
        rxinsurance: {
          pbm_id: 'T00000000001010',
          pbm_name: 'PBMA',
          plan_id: 'Y8831',
          plan_name: 'unknown',
          pbm_member_id: '2455_Y8831_92',
          formulary_id: 'FSL107',
          message_id: '433657901',
          group_id: 'Y8831_9',
          group_name: 'SPC-9288',
          bin: '002318',
          pcn: 'PBMA-Y9288',
          coverage_date_start: '2016-09-01',
          coverage_date_end: '2099-12-31',
          segment: 'unknown',
          retail_benefit: 'true',
          retail: {
            out_of_pocket: 'unknown'
          },
          mail_order_benefit: 'true',
          mail_order: {
            out_of_pocket: 'unknown'
          },
          has_ltc_coverage: 'unknown',
          has_sp_coverage: 'true',
          sp: {
            ncpdp_id: 'unknown',
            name: 'unknown'
          }
        },
        results: [
          {
            result_id: null,
            pharmacy: null,
            rxinsurance: null,
            drug: null,
            errors: [
              {
                category: 'invalid-data',
                description: 'System Error',
                location:
                  'External service rejection: Something unexpected went wrong.'
              }
            ]
          }
        ]
      }
    ];

    return results ? (
      <CardDeck className="summary-body">
        {results.map((result, i) => (
          <Card
            style={this.getStyles(result.result_id)}
            className="summary-content coverage-card"
            key={result.result_id}
            onClick={e => this.onCoverageSelectionChange(e, result.result_id)}
          >
            <CardHeader>
              Option: <strong>{result.rxinsurance.pbm_name}</strong>
            </CardHeader>
            <CardBody>
              <dl className="warning">
                <dt>Patient&nbsp;Name:</dt>
                <dd>
                  {result.member.first_name} {result.member.last_name}
                </dd>
              </dl>
              <dl>
                <dt>Gender:</dt>
                <dd>{result.member.gender}</dd>
              </dl>
              <dl>
                <dt>Date of Birth:</dt>
                <dd>{result.member.birth_date}</dd>
              </dl>
              <dl className="warning">
                <dt>Address:</dt>
                <dd>
                  {result.member.address_1} {result.member.city}{' '}
                  {result.member.state} {result.member.zipcode}
                </dd>
              </dl>
            </CardBody>
            <hr />
            <CardBody>
              <dl>
                <dt>PBM Name:</dt>
                <dd>{result.rxinsurance.pbm_name}</dd>
              </dl>
              <dl>
                <dt>Prior Authorization:</dt>
                <dd>{result.rxinsurance.pa_required}</dd>
              </dl>
              <dl>
                <dt>Plan ID:</dt>
                <dd>{result.rxinsurance.plan_id}</dd>
              </dl>
              <dl>
                <dt>Coverage Start Date:</dt>
                <dd>{result.rxinsurance.coverage_date_start}</dd>
              </dl>
              <dl>
                <dt>Coverage End Date:</dt>
                <dd>{result.rxinsurance.coverage_date_end}</dd>
              </dl>
              <dl>
                <dt>Group ID:</dt>
                <dd>{result.rxinsurance.group_id}</dd>
              </dl>
            </CardBody>

            <CardFooter>
              <Button
                className="btn-block"
                color="link"
                key={result.result_id}
                id={'toggler-' + i}
              >
                More Information
              </Button>
            </CardFooter>
            <UncontrolledCollapse toggler={'#toggler-' + i}>
              <dl>
                <dt>Member ID:</dt>
                <dd>{result.rxinsurance.pbm_member_id}</dd>
              </dl>
              <dl>
                <dt>Group ID:</dt>
                <dd>{result.rxinsurance.group_name}</dd>
              </dl>
              <dl>
                <dt>BIN:</dt>
                <dd>{result.rxinsurance.bin}</dd>
              </dl>
              <dl>
                <dt>PCN:</dt>
                <dd>{result.rxinsurance.pcn}</dd>
              </dl>
              <dl>
                <dt>Pharmacy Type:</dt>
                <dd>this =result.pharmacy.type</dd>
              </dl>
              <dl>
                <dt>Drug Status:</dt>
                <dd>this = subResult.coverageDrug.status</dd>
              </dl>
              <dl>
                <dt>Coverage Alerts:</dt>
                <dd>
                  this = subResult.rxInsurance.coverage_alerts
                  <span>
                    {alert.reference_text}
                    <br />
                  </span>
                </dd>
              </dl>
              <dl>
                <dt>Quantity Priced:</dt>
                <dd>this = subResult.coverageDrug.quantity</dd>
              </dl>
              <dl>
                <dt>Day's Supply Priced:</dt>
                <dd>this = subResult.coverageDrug.days_supply</dd>
              </dl>
              <dl>
                <dt>Plan Pay Amount:</dt>
                <dd>this = subResult.rxInsurance.plan_pay_amount</dd>
              </dl>
              <dl>
                <dt>Individual Deductible:</dt>
                <dd>this = subResult.rxInsurance.individual_deductible</dd>
              </dl>
              <dl>
                <dt>Individual Deductible Met:</dt>
                <dd>this = subResult.rxInsurance.individual_deductible_met</dd>
              </dl>
              <dl>
                <dt>Individual Deductible Remaining:</dt>
                <dd>
                  this = subResult.rxInsurance.individual_deductible_remaining
                </dd>
              </dl>
              <dl>
                <dt>Patient Pay Amount:</dt>
                <dd>this = subResult.rxInsurance.patient_pay_amount</dd>
              </dl>
              <dl>
                <dt>OOP Amount:</dt>
                <dd>this = subResult.rxInsurance.out_of_pocket_amount</dd>
              </dl>
              <dl>
                <dt>OOP Max:</dt>
                <dd>this = subResult.rxInsurance.out_of_pocket_max</dd>
              </dl>
              <dl>
                <dt>OOP Met:</dt>
                <dd>this = subResult.rxInsurance.out_of_pocket_met</dd>
              </dl>
              <dl>
                <dt>OOP Remaining:</dt>
                <dd>this = subResult.rxInsurance.out_of_pocket_remaining</dd>
              </dl>
            </UncontrolledCollapse>
          </Card>
        ))}
      </CardDeck>
    ) : (
      this.renderError('one')
    );
  };

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  onCoverageSelectionChange = (e, coverageId) => {
    //If there are two or more coverage's shown, changing the coverage selection should be tracked
    this.setState({
      selectedCoverageId: coverageId
    });
  };

  render() {
    return (
      <React.Fragment>
        <NavBar />
        <div className="container mt-6 mb-6">
          <div className="row">
            <div className="main col-md-12">
              <div className="row summary">
                <div className="summary-header">
                  <h1>
                    <span>Coverage Results for: </span> Patient Full Name
                  </h1>
                </div>

                <div className="col-12">
                  <h4>
                    Please select the coverage option you would like to use.
                  </h4>

                  <h4>
                    You've selected the coverage option: <strong>NAME</strong>
                  </h4>

                  <h5 className="notify__item notify__item--aware">
                    There appears to be some differences between the patient
                    details entered and what was found.{' '}
                  </h5>
                </div>
              </div>

              <div className="summary-details pb-5">
                {this.renderCoverageResultsOrError()}
              </div>

              <pre>
                {JSON.stringify(
                  {
                    patientName: this.state.patientName,
                    selctedCoverageId: this.state.selectedCoverageId
                  },
                  null,
                  '\t'
                )}
              </pre>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default StyleGuideCoverageResultsPage;

import React, { FC, ReactElement } from 'react';
import EnrollmentCard from '../EnrollmentCard';
import { IPatientCard } from '../../../Interfaces/IPatientCard';

interface IEnrollmentCardSectionProps {
  actionCards: IPatientCard[];
  statusCards: IPatientCard[];
  completedCards: IPatientCard[];
}

const EnrollmentCardSection: FC<any> = (
  props: IEnrollmentCardSectionProps
): ReactElement => {
  return (
    <div>
      <span className="section-header__title status-1">Action Required</span>
      <div className="row sm-pad">
        {props.actionCards?.map((actionCard: IPatientCard) => (
          <EnrollmentCard
            key={actionCard.patientId}
            cardClass={'card status-1 mb-3'}
            cardData={actionCard}
          />
        ))}
      </div>
      <span className="section-header__title status-2">In Progress</span>
      <div className="row sm-pad">
        {props.statusCards?.map((statusCard: IPatientCard) => (
          <EnrollmentCard
            key={statusCard.patientId}
            cardClass={'card status-2 mb-3'}
            cardData={statusCard}
          />
        ))}
      </div>
      <span className="section-header__title status-3">
        Enrolled Patients (No Action Required)
      </span>
      <div className="row sm-pad">
        {props.completedCards?.map((completedCard: IPatientCard) => (
          <EnrollmentCard
            key={completedCard.patientId}
            cardClass={'card status-3 mb-3'}
            cardData={completedCard}
          />
        ))}
      </div>
    </div>
  );
};

export default EnrollmentCardSection;

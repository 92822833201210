export interface INavService {
  redirectToApp(path?: string): void;
}

export class NavServiceBrowser implements INavService {
  redirectToApp = (path?: string): void => {
    if (path) {
      window.location.href = window.location.origin + path;
    } else {
      window.location.href = window.location.origin + '/homepage';
    }
  };
}

const shared: INavService = new NavServiceBrowser();
export const NavService = {
  shared
};

import { error } from '../../utilities/ErrorHandler';

const useRouteErrorHandler = (route, state, history) => {
  const GenericRouteErrorHandler = 'GenericRouteErrorHandler';
  const RouteErrorHandlerFuncMap = new Map([
    [
      '/coverage-results',
      () => {
        const enrollmentAttempt = state.enrollmentAttempt;
        enrollmentAttempt.eligibilityAndCoverageResponse = null;

        history.replace('/coverage-results', {
          enrollmentAttempt
        });
        window.location.reload();
      }
    ],
    [GenericRouteErrorHandler, error]
  ]);

  return RouteErrorHandlerFuncMap.has(route)
    ? RouteErrorHandlerFuncMap.get(route)
    : RouteErrorHandlerFuncMap.get(GenericRouteErrorHandler);
};

export default useRouteErrorHandler;

import React, { useState, useEffect, useRef } from 'react';
import NavBar from '../parts/NavBar';
import PinForm from '../parts/PinForm';
import Loader from '../parts/Loader';
import Toastr from '../parts/Toastr';
import api from '../../api';
import { isFalsey, isTruthy } from '../../utilities/JsHelpers';
import * as ErrorHandler from '../../utilities/ErrorHandler';
import { RouteComponentProps, StaticContext } from 'react-router';
import { ToastContainer } from 'react-toastr';
import { FORM_ERROR } from 'final-form';

/**
 * Parameters passed via the url
 */
export interface IPinPageUrlParams {
  enrollmentAttemptId: string;
}

/**
 * Parameters passed via the location.state
 */
export interface IPinPageStateParams {
  enrollmentAttemptId: string;
  prescriberId: string;
  patientEmail: string;
  coverageSelectionId?: string;
  canShareBenefitsInfo?: boolean;
  updateEnrollmentInfo: any;
}

/**
 * Interface describing the component props.  We extend RouteComponentProps to gain
 * access to the match and location information passed by react-router.
 */
interface IProps
  extends RouteComponentProps<
    IPinPageUrlParams,
    StaticContext,
    IPinPageStateParams
  > {}

interface ISubmissionResults {
  submitting: boolean;
  responseAction?: () => void;
}

const PinPage: React.FunctionComponent<IProps> = props => {
  const [submissionResults, setSubmissionResults] = useState<
    ISubmissionResults
  >({
    submitting: false
  });
  const [optionSelected, setOptionSelected] = useState<number | null>(null);
  const [optionName, setOptionName] = useState('');
  const toast = useRef<ToastContainer>();

  const PRESCRIBERPIN = 'prescriberPin';
  const SIGNEDENROLLMENT = 'signedEnrollment';
  const SIGNEDFORM = 'signedForm';
  const ATTACHMENTTYPE_UNSIGNEDPEF = 3;
  const ATTACHMENTTYPE_NOTAPRESCRIPTIONPEF = 4;

  const redirectToDocusign = (docusignUrl: string) => {
    return () => window.location.assign(docusignUrl);
  };

  const redirectToCaseSummaryPage = (patientId: string) => {
    return () =>
      props.history.push({
        pathname: `/Enrollment/${patientId}`
      });
  };

  const generateDocusignRequestUrl = (excludePatientConsent: boolean) => {
    api.vertex
      .getSignatureUrl(
        props.match.params.enrollmentAttemptId,
        excludePatientConsent //coerces the undefined to false
      )
      .then(docusignUrl => {
        setSubmissionResults({
          submitting: false,
          responseAction: redirectToDocusign(docusignUrl)
        });
      })
      .catch(e => {
        ErrorHandler.error(e);
      });
  };

  const onSubmit = async (values: any) => {
    setSubmissionResults({ submitting: true });
    const updateEnrollmentInfo = {
      ...props.location.state.updateEnrollmentInfo,
      coverageSelectionId: props.location.state.coverageSelectionId,
      canShareBenefitsInfo: props.location.state.canShareBenefitsInfo,
      enrollmentAttemptId: props.match.params.enrollmentAttemptId
    };

    if (
      optionName === PRESCRIBERPIN &&
      isTruthy(values) &&
      isFalsey(values.prescriberPin)
    ) {
      setSubmissionResults({ submitting: false });
      return { [FORM_ERROR]: 'Submission Failed' };
    }

    switch (optionName) {
      case PRESCRIBERPIN: {
        let formData = new FormData();
        formData.append('pin', values.prescriberPin.pin);
        formData.append('prescriberId', props.location.state.prescriberId);
        const isValidPin = await api.vertex.checkPin(formData);

        if (isValidPin) {
          api.vertex
            .updateEnrollment(updateEnrollmentInfo)
            .then(() =>
              generateDocusignRequestUrl(!!values.excludePatientConsent)
            )
            .catch(err => ErrorHandler.error(err));
        } else {
          setSubmissionResults({ submitting: false });
          return { [FORM_ERROR]: 'Submission Failed' };
        }
        break;
      }
      case SIGNEDENROLLMENT: {
        if (
          optionName === SIGNEDENROLLMENT &&
          isTruthy(values.signedEnrollment) &&
          isTruthy(values.signedEnrollment.willUploadPEF)
        ) {
          createEnrollmentForm(
            ATTACHMENTTYPE_UNSIGNEDPEF,
            updateEnrollmentInfo
          );
        } else {
          setSubmissionResults({ submitting: false });
          return { [FORM_ERROR]: 'Submission Failed' };
        }
        break;
      }
      case SIGNEDFORM: {
        if (
          optionName === SIGNEDFORM &&
          isTruthy(values.signedForm) &&
          isTruthy(values.signedForm.willUploadSignedForm)
        ) {
          createEnrollmentForm(
            ATTACHMENTTYPE_NOTAPRESCRIPTIONPEF,
            updateEnrollmentInfo
          );
        } else {
          setSubmissionResults({ submitting: false });
          return { [FORM_ERROR]: 'Submission Failed' };
        }
        break;
      }
      default: {
        throw new Error('No Prescription Option Selected');
      }
    }
  };

  const createEnrollmentForm = (
    attachmentType: number,
    updateEnrollmentInfo: any
  ) => {
    api.vertex
      .createEnrollmentForm(attachmentType, updateEnrollmentInfo)
      .then(patientId => {
        setSubmissionResults({
          submitting: false,
          responseAction: redirectToCaseSummaryPage(patientId)
        });
      })
      .catch(e => {
        ErrorHandler.error(e);
      });
  };

  const setNewOptionSelected = (
    optionNumberClicked: number,
    optionName: string
  ) => {
    const newOptionSelected =
      optionNumberClicked === optionSelected ? null : optionNumberClicked;
    const newNameSelected = newOptionSelected === null ? '' : optionName;

    setOptionSelected(newOptionSelected);
    setOptionName(newNameSelected);
  };

  // Redirect to the docusign url if it has been supplied
  useEffect(() => {
    if (!!submissionResults && !!submissionResults.responseAction) {
      submissionResults.responseAction();
    }
  });

  return (
    <>
      <Loader isOpen={submissionResults.submitting} />
      <NavBar />
      <div className="container mt-6 mb-6">
        <div className="row">
          <div className="main col-md-12">
            <div className="row summary">
              <div className="summary-header">
                <h1>
                  <span>Prescription Options</span>
                </h1>
              </div>
              <PinForm
                patientEmail={props.location.state.patientEmail}
                submitHandler={onSubmit}
                optionSelected={optionSelected}
                setNewOptionSelected={setNewOptionSelected}
              />
            </div>
          </div>
        </div>
      </div>
      <Toastr toastRef={toast} />
    </>
  );
};

export default PinPage;

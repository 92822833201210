import logo from '../../img/Vertex_GPS_logo.svg';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { MenuIcon } from 'mdi-react';
// import HelpDoc from '../../docs/VertexHelpDoc.pdf';
import NavBarSearch from './NavBarSearch';
import { AuthServiceCognito } from '../../utilities/AuthServiceCognito';
import { error } from '../../utilities/ErrorHandler';
import ReportsModal from '../parts/navBarParts/ReportsModal';
import { localStorageFilterName } from '../../constants/StringConstants';

class NavBar extends React.Component {
  state = {
    menuOneOpen: false,
    menuTwoOpen: false,
    isReportsModalOpen: false
  };

  isCollapseOpen = false;

  textInput = React.createRef();

  toggle = toggleId => {
    if (toggleId === 1) {
      this.setState({
        menuOneOpen: !this.state.menuOneOpen
      });
    } else {
      this.setState({
        menuTwoOpen: !this.state.menuTwoOpen,
        menuOneOpen: !this.state.menuOneOpen
      });
    }
  };
  // TODO: need to also handle tap/click out of menu
  handleResize = () =>
    this.setState({
      menuOneOpen: false,
      menuTwoOpen: false
    });

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleLogout = () => {
    const auth = new AuthServiceCognito();
    auth
      .logout()
      .then(_ => {
        window.sessionStorage.setItem(localStorageFilterName, []);
        window.location.href = window.location.origin;
      })
      .catch(err => error(err));
  };

  onChangePatientSearch = e => {
    if (e.target.value.length >= 3) {
      this.props.filterPatientCards(e.target.value);
      return;
    }

    this.props.setClearPatientCardSearchState();
  };

  onClickClear = () => {
    this.textInput.current.value = '';
    this.props.setClearPatientCardSearchState();
  };

  toggleReportsModal = () => {
    const {
      state: { isReportsModalOpen }
    } = this;

    this.setState({
      isReportsModalOpen: !isReportsModalOpen
    });
  };

  maybeRenderReportsModal = () => {
    // Rendering the reports modal this way defers loading reports until requested,
    // and will also result in reports being refreshed with each modal open
    return this.state.isReportsModalOpen ? (
      <ReportsModal isOpen={true} toggle={this.toggleReportsModal} />
    ) : null;
  };

  render() {
    return (
      <div>
        {this.maybeRenderReportsModal()}
        <Navbar fixed={`top`} color="light" light expand="md">
          <div className="container">
            <NavbarBrand tag={Link} to="/homepage">
              <img
                src={logo}
                className="logo"
                alt="Vertex GPS | Guidaince and Patient Support"
              />
            </NavbarBrand>

            <NavbarToggler
              onClick={() => this.toggle(2)}
              aria-expanded={this.state.menuTwoOpen}
            />
            <Collapse isOpen={this.state.menuTwoOpen} navbar>
              <>
                {this.props.includeSearch ? (
                  <NavBarSearch
                    inputRef={this.textInput}
                    onChangePatientSearch={this.onChangePatientSearch}
                    onClickClear={this.onClickClear}
                  />
                ) : null}
              </>
              <Nav className="ml-auto btn-group" navbar>
                <NavItem className="enroll">
                  <NavLink
                    className="btn btn-primary"
                    tag={Link}
                    to="/new-case"
                  >
                    New Enrollment
                  </NavLink>
                </NavItem>
                <NavItem className="enroll">
                  <NavLink
                    className="btn btn-info"
                    onClick={this.toggleReportsModal}
                  >
                    Reports
                  </NavLink>
                </NavItem>
                <Dropdown
                  toggle={() => !this.state.menuTwoOpen && this.toggle(1)}
                  isOpen={this.state.menuOneOpen}
                  nav
                  inNavbar
                >
                  <DropdownToggle className="d-none d-md-block" nav>
                    <MenuIcon size="2em" />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem tag={Link} to="/homepage">
                      Home
                    </DropdownItem>
                    <DropdownItem
                      tag="a"
                      href="https://www.vertexgps.com/hcpportalvideos"
                      target="_blank"
                    >
                      Help
                    </DropdownItem>
                    <DropdownItem divider />

                    <DropdownItem onClick={this.handleLogout}>
                      Logout
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Nav>
            </Collapse>
          </div>
        </Navbar>
      </div>
    );
  }
}

export default NavBar;

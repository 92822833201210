import logo from '../../img/Vertex_GPS_logo.svg';
import React, { useState } from 'react';
import {
  Container,
  Col,
  Form,
  FormGroup,
  FormText,
  Label,
  Input,
  Button
} from 'reactstrap';
import { LoginNotificationItemMap } from '../parts/loginParts/LoginNotifcationItemMap';
import LoginNotification from '../parts/loginParts/LoginNotification';
import {
  // AttemptLimitExceeded,
  UsernameRequired,
  CodeToBeDelivered,
  PasswordResetRequired,
  LimitExceededException,
  UserNotFoundException,
  AccountLocked,
  NotAuthorizedException
} from '../../constants/LoginNotificationCodes';
import { Link } from 'react-router-dom';
import { isTruthy, isFalsey } from '../../utilities/JsHelpers';
import { error } from '../../utilities/ErrorHandler';

const ForgotPasswordPage = props => {
  const [email, setEmail] = useState(
    props.location.state ? props.location.state.email : ''
  );
  const [notificationItem, setNotificationItem] = useState(null);
  const errorHandlerFuncByErrorCode = new Map([
    [
      UserNotFoundException,
      () => {
        props.history.replace({
          pathname: '/set-password'
        });
      }
    ],
    [
      //Deactivate users throw this exception;we catch this and redirect to set-password page
      //The user cannot perform any operation on reset pwd page since they never receive a reset code in their email
      NotAuthorizedException,
      () => {
        props.history.replace({
          pathname: '/set-password'
        });
      }
    ],
    [
      LimitExceededException,
      // TODO: [VRTXHUBV2-192] This Functionality Should Be Uncommented for the September release,
      // as it was not CRC Approved for August.
      // () => {
      //   setNotificationItem(
      //     LoginNotificationItemMap.get(AttemptLimitExceeded)
      //   );
      () => {}
    ],
    [
      undefined,
      err => {
        error(err);
      }
    ]
  ]);

  const validateForm = () => {
    return email.length > 0;
  };

  const submitForm = e => {
    e.preventDefault();
    if (isUsernameProvided()) {
      props.authSvc
        .forgotPassword(email)
        .then(() => {
          props.history.replace({
            pathname: `/set-password`,
            state: {
              email
            }
          });
        })
        .catch(err => {
          errorHandlerFuncByErrorCode.has(err.code)
            ? errorHandlerFuncByErrorCode.get(err.code)(err)
            : errorHandlerFuncByErrorCode.get(undefined)(err);
        });
    }
  };

  const isUsernameProvided = () => {
    const emailMissing = isFalsey(email);
    if (emailMissing) {
      setNotificationItem(LoginNotificationItemMap.get(UsernameRequired));
      return false;
    }

    setNotificationItem(null);
    return true;
  };

  const maybeRenderPasswordResetRequired = () => {
    const {
      location: { state }
    } = props;

    return isTruthy(state) && isTruthy(state.isPasswordResetRequired) ? (
      <LoginNotification
        item={LoginNotificationItemMap.get(PasswordResetRequired)}
      />
    ) : null;
  };

  const renderAccountLockedOrCodeToBeDeliveredNotification = () => {
    const {
      location: { state }
    } = props;

    return isTruthy(state) && isTruthy(state.isAccountLocked) ? (
      <LoginNotification item={LoginNotificationItemMap.get(AccountLocked)} />
    ) : (
      <LoginNotification
        item={LoginNotificationItemMap.get(CodeToBeDelivered)}
      />
    );
  };

  return (
    <Container className="main login col-xs-12 col-lg-4">
      <img
        src={logo}
        className="logo"
        alt="Vertex GPS | Guidaince and Patient Support"
      />
      <h1>
        <span>Reset Password</span>
      </h1>
      <Form className="form" onSubmit={submitForm}>
        <Col>
          <FormGroup>
            <Label>
              Username
              <Input
                type="text"
                name="email"
                id="exampleEmail"
                placeholder="Enter your username"
                value={email}
                onChange={e => {
                  setEmail(e.target.value);
                }}
              />
            </Label>
          </FormGroup>
          <LoginNotification item={notificationItem} />
          {renderAccountLockedOrCodeToBeDeliveredNotification()}
          {maybeRenderPasswordResetRequired()}
        </Col>

        <FormText>
          <Link className="float-left mt-4 mb-4 ml-2 btn btn-link" to={'/'}>
            Back to Sign In
          </Link>
        </FormText>

        {/*
        // TODO: needs added to SEPTEMBER release ticket should have been created by Beth P. 8.1.19
        <FormText>
          <Link
            className="float-left mt-4 mb-4 ml-2 btn btn-link"
            to={'/set-password'}
          >
            Have a code?
          </Link>
        </FormText>
        */}

        <Button
          color="primary"
          className="float-right mt-4 mb-4 mr-3"
          type="submit"
          disabled={!validateForm()}
          role="button"
        >
          Send Code
        </Button>
      </Form>
    </Container>
  );
};

export default ForgotPasswordPage;

import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import CircleArrow from '../../parts/CircleArrow';

const AccordionCard = ({
  optionSelected,
  cardOption,
  cardName,
  optionHeader,
  optionDescription,
  cardBody,
  setClassName,
  setOptionSelected,
  ...other
}) => {
  return (
    <Card
      {...other} // Pass any other props that we're not using on to the child
      className={setClassName}
      aria-expanded={optionSelected === cardOption}
    >
      <Accordion.Toggle
        as={Card.Header}
        eventKey={String(cardOption)}
        onClick={() => {
          setOptionSelected(cardOption, cardName);
        }}
      >
        <h3>{optionHeader}</h3>
        <span>{optionDescription}</span>
        <CircleArrow isOptionSelected={optionSelected === cardOption} />
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={String(cardOption)}>
        <Card.Body>{cardBody}</Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default AccordionCard;

import React from 'react';
import { ClearIcon } from 'mdi-react';

class NavBarSearch extends React.Component {
  render() {
    return (
      <div className="input-group col-lg-8 col-xs-12 mx-auto">
        <input
          ref={this.props.inputRef}
          type="text"
          className="form-control"
          placeholder="eg. Patient Name"
          aria-label="Recipient's username"
          aria-describedby="button-addon2"
          onChange={this.props.onChangePatientSearch}
        />

        <div className="input-group-append">
          <button
            className="btn btn-outline-primary"
            type="button"
            id="button-addon2"
            onClick={this.props.onClickClear}
          >
            <ClearIcon size="14px" />
          </button>
        </div>
      </div>
    );
  }
}

export default NavBarSearch;

export const dataNotAvailable = '--';
export const emptyGuid = '00000000-0000-0000-0000-000000000000';
export const defaultCaseMngrImgName = 'profile-silho.jpg';
export const unknown = 'unknown';
export const enrolledInCopayAssistance = 'Enrolled in Copay Assistance';
export const enrolled = 'Enrolled';
export const softwareSupportPhone = '1-800-290-0011';
const enrollmentFormIsPrescription =
  'I want the Vertex GPS enrollment form to be a prescription.';

export const pinForm = {
  option1Header: 'I will enter the Prescriber PIN',
  option1Description: enrollmentFormIsPrescription,
  option2Header: 'I will upload the Prescriber signed enrollment form',
  option2Description: enrollmentFormIsPrescription,
  option3Header: 'I will submit the prescription directly to the pharmacy',
  option3Description:
    'Vertex GPS Enrollment form will not be used as a prescription.',
  submitBtn: 'Complete Enrollment',
  pinValidationContent: {
    softwareSupportPhone,
    errorTextLine1:
      'Please enter a valid PIN. Otherwise, please call Software Support',
    errorTextLine2: 'for assistance'
  },
  uploadValidationContent: {
    errorText:
      'Please select that you understand how the prescription will be handled.'
  }
};

export const localStorageFilterName = 'filters';

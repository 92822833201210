import React from 'react';
import Wizard from '../Wizard';
import LocationSelect from './LocationSelect';
import Loader from '../Loader';
import * as ErrorHandler from '../../../utilities/ErrorHandler';
import api from '../../../api';
import { isTruthy, isNullOrUndefined } from '../../../utilities/JsHelpers';
import EnrollmentExistsModal from '../EnrollmentExistsModal';
import { EnrollmentInfo } from '../../../model/EnrollmentInfo';
import AcknowledgementBox from '../AcknowledgementBox';
import DropdownSelect from '../DropdownSelect';

const required = value => (isTruthy(value) ? undefined : 'Required');
const acknowledgmentText =
  'I certify that (1) the Vertex Pharmaceuticals Incorporated (“Vertex”) therapy I prescribe is medically necessary and is in the best interest of the patient listed above; (2) I have any consent required under federal and state law for the release of the patient’s information on this form to Vertex and its contractors and business partners (“Contractors”) for benefits verification and coordination of dispensing Vertex medicine; (3) I will comply with state-specific prescription requirements and understand non-compliance with these requirements could result in further outreach by the patient’s specialty pharmacy; (4) I understand that information I provide on this form, if signed by the patient, will be used by Vertex and its Contractors as authorized by the patient.';
const acknowledgmentRequired = value =>
  isTruthy(value) ? undefined : 'Please Acknowledge consent before proceeding';

class PrescriberLocationInfo extends React.Component {
  state = {
    prescribers: null,
    locations: null,
    address: '',
    state: '',
    city: '',
    zip: '',
    testProp: '',
    patientId: null,
    userHasAccessToPatientLocation: false
  };

  maybeRenderEnrollmentExistsModal = () => {
    return this.props.patientId !== this.state.patientId ? (
      <EnrollmentExistsModal
        key={this.state.patientId}
        patientId={this.state.patientId}
        userHasAccessToPatientLocation={
          this.state.userHasAccessToPatientLocation
        }
      />
    ) : null;
  };

  async componentDidMount() {
    try {
      let promiseCollection = [];
      promiseCollection.push(await api.vertex.getPrescribers());
      promiseCollection.push(await api.vertex.getLocations());

      const formInfo = new EnrollmentInfo(this.props);
      promiseCollection.push(
        await api.vertex.getPatientUserAccessByDemographics(
          formInfo.patientFirstName,
          formInfo.patientLastName,
          formInfo.patientZipCode,
          formInfo.patientDateOfBirth,
          formInfo.patientGender
        )
      );

      Promise.all(promiseCollection)
        .then(res => {
          this.setState({
            prescribers: res[0],
            locations: res[1],
            patientId: res[2].patientId,
            userHasAccessToPatientLocation:
              res[2].userHasAccessToPatientLocation
          });
        })
        .catch(err => ErrorHandler.error(err));
    } catch (error) {
      ErrorHandler.error(error);
    }
  }

  render() {
    const {
      state: { prescribers, locations }
    } = this;

    return isNullOrUndefined(prescribers) || isNullOrUndefined(locations) ? (
      <Loader isOpen={true} />
    ) : (
      <Wizard.Page>
        <h1>
          <span>Prescriber &amp; Center Information</span>
        </h1>
        <DropdownSelect
          validator={required}
          options={prescribers}
          optionMap={{ key: 'prescriberId', value: 'prescriberName' }}
          name="Prescriber"
        ></DropdownSelect>
        <LocationSelect locations={locations} />
        <h1 className="mt-5">Acknowledgment</h1>
        <AcknowledgementBox
          name="Acknowledgement"
          validator={acknowledgmentRequired}
          textContent={acknowledgmentText}
        ></AcknowledgementBox>
        {this.maybeRenderEnrollmentExistsModal()}
      </Wizard.Page>
    );
  }
}

export default PrescriberLocationInfo;

import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
var Spinner = require('react-spinkit');

class Loader extends React.Component {
  render() {
    const {
      props: { isOpen }
    } = this;

    return (
      <React.Fragment>
        <Modal isOpen={isOpen} className="loader">
          <ModalBody className="text-center">
            <Spinner name="ball-scale-multiple" color="#492365" />
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Loader;

import React from 'react';
import IdleTimer from 'react-idle-timer';
import SnapEngageWrapper from './SnapEngageWrapper';
import { AuthServiceCognito } from '../../utilities/AuthServiceCognito';
import { error } from '../../utilities/ErrorHandler';

class Timer extends React.Component {
  static timer;

  startTimeoutTimer = () => {
    this.setState({
      remaining: this.idleTimer.getRemainingTime(),
      lastActive: this.idleTimer.getLastActiveTime(),
      elapsed: this.idleTimer.getElapsedTime()
    });

    Timer.timer = setInterval(() => {
      if (this.idleTimer === null) return;

      this.setState({
        remaining: this.idleTimer.getRemainingTime(),
        lastActive: this.idleTimer.getLastActiveTime(),
        elapsed: this.idleTimer.getElapsedTime()
      });
    }, 1000);
  };

  componentDidMount() {
    // start the session timeout timer
    this.startTimeoutTimer();
  }

  constructor(props) {
    super(props);
    this.state = {
      remaining: this.props.timeout,
      lastActive: null,
      elapsed: null
    };
    this.Auth = new AuthServiceCognito();
    this.idleTimer = null;
  }

  render() {
    const {
      state: { remaining }
    } = this;
    if (remaining <= 0) {
      this.Auth.logout().catch(err => error(err));
      window.location.href = window.location.origin;
    }

    return (
      <IdleTimer
        ref={ref => {
          this.idleTimer = ref;
        }}
        element={document}
        timeout={1000 * 60 * 15}
        events={[
          'mousemove',
          'keydown',
          'DOMMouseScroll',
          'mousedown',
          'mouseWheel',
          'touchstart',
          'touchmove',
          'MSPointerDown',
          'MSPointerMove'
        ]}
      >
        {this.props.children}
        <SnapEngageWrapper />
      </IdleTimer>
    );
  }
}

export default Timer;

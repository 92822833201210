import React, { useEffect } from 'react';
import Loader from '../parts/Loader';
import { isTruthy } from '../../utilities/JsHelpers';
import api from '../../api';
import { error } from '../../utilities/ErrorHandler';

const PriorAuthRedirect = props => {
  useEffect(() => {
    async function fetchData() {
      const priorAuthId = props.match.params.priorAuthId;
      const patientId = await api.vertex.getPatientIdFromPriorAuthId(
        priorAuthId
      );

      if (isTruthy(patientId)) {
        return props.history.push('/Enrollment/' + patientId);
      } else {
        return props.history.push('/homepage');
      }
    }
    fetchData().catch(err => error(err));
  }, [props.history, props.match.params.priorAuthId]);

  return <Loader isOpen={true} />;
};

export default PriorAuthRedirect;

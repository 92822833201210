import React from 'react';
import NavBar from '../parts/NavBar';
import CaseSummaryNotificationArea from '../parts/caseSummaryParts/CaseSummaryNotificationArea';
import CaseSummaryHeader from '../parts/caseSummaryParts/CaseSummaryHeader';
import CaseSummaryMedicationCard from '../parts/caseSummaryParts/CaseSummaryMedicationCard';
import CaseSummaryStatusSection from '../parts/caseSummaryParts/CaseSummaryStatusSection';
import CaseSummaryInsuranceCard from '../parts/caseSummaryParts/CaseSummaryInsuranceCard';
import CaseSummaryPatientCard from '../parts/caseSummaryParts/CaseSummaryPatientCard';
import FileLibraryContainer from '../parts/caseSummaryParts/FileLibraryContainer';
import { CardDeck } from 'reactstrap';
import {
  isFalsey,
  isNonEmptyArray,
  isTruthy,
  getFormattedDate
} from '../../utilities/JsHelpers';
import { error } from '../../utilities/ErrorHandler';
import Loader from '../parts/Loader';
import { emptyGuid } from '../../constants/StringConstants';
import { getPAStatus } from '../../utilities/StatusHelpers';
import api from '../../api';
import NewCaseModal from '../parts/caseSummaryParts/NewCaseModal';
import capitalize from 'voca/capitalize';

class CaseSummaryPage extends React.Component {
  state = {
    medications: [],
    epaInitiationAlerts: [],
    caseSummaryDetails: null,
    priorAuthorization: null,
    mosaicIntegration: null,
    isManualInsurance: false,
    enrollmentImported: false,
    caseManager: {},
    epaNotSupported: false,
    confirmNewCaseModalVisible: false,
    showRenewEpa: false
  };

  toggleConfirmNewCaseModal() {
    const {
      state: { confirmNewCaseModalVisible }
    } = this;

    this.setState({
      confirmNewCaseModalVisible: !confirmNewCaseModalVisible
    });
  }

  getMedicationDetails = async selectedMedication => {
    const medications = await api.vertex.getMedications();
    return await this.setMedicationInfo(selectedMedication, medications);
  };

  getCoverageDetails = (
    manualInsurance,
    coverageSelectedId,
    coverageResults
  ) => {
    return coverageSelectedId === emptyGuid || coverageSelectedId === null
      ? this.getManualInsuranceCoverageDetails(manualInsurance)
      : this.getBIHubCoverageDetails(coverageSelectedId, coverageResults);
  };

  getPatientDetails = enrollmentDetails => {
    let patient = enrollmentDetails.response.enrollmentRequest.patient;

    return {
      patientFirstName: patient.firstName,
      patientLastName: patient.lastName,
      patientZipCode: patient.zipCode,
      patientDob: getFormattedDate(patient.birthDate),
      patientGender: patient.gender,
      primaryContact: patient.primaryContact,
      primaryPhone: patient.primaryContactPhone,
      secondaryPhone: isTruthy(patient.secondaryContactPhone)
        ? patient.secondaryContactPhone
        : '',
      isAllowedLeaveMessages: patient.isAllowedToLeaveMessages,
      patientEmail: patient.email,
      language: patient.languagePreference,
      shippingDate: isTruthy(
        enrollmentDetails.response.enrollmentRequest.mosaicIntegration
      )
        ? enrollmentDetails.response.enrollmentRequest.mosaicIntegration
            .shippingDate
        : '',
      specialtyPharmacyName: patient.pharmacyName
    };
  };

  getBIHubCoverageDetails = (coverageSelectedId, coverageResults) => {
    const { results } = coverageResults;
    const coverageDetails = results.find(element => {
      return element.result_id === coverageSelectedId;
    });

    return {
      payerName: coverageDetails.rxinsurance.pbm_name,
      planName: coverageDetails.rxinsurance.plan_name,
      planId: coverageDetails.rxinsurance.plan_id,
      groupName: coverageDetails.rxinsurance.group_name,
      groupId: coverageDetails.rxinsurance.group_id,
      memberId: coverageDetails.rxinsurance.pbm_member_id,
      coverageStartDate: coverageDetails.rxinsurance.coverage_date_start,
      coverageEndDate: coverageDetails.rxinsurance.coverage_date_end,
      pharmacyInformation: coverageDetails.results
    };
  };

  getPriorAuthorizationLabel = coverageDetails => {
    return isTruthy(coverageDetails.results) &&
      isNonEmptyArray(coverageDetails.results) &&
      isTruthy(coverageDetails.results[0].rxinsurance)
      ? coverageDetails.results[0].rxinsurance.pa_required
      : '';
  };

  getManualInsuranceCoverageDetails = manualInsurance => {
    return {
      payerName: manualInsurance.primaryInsurancePayerName,
      planId: manualInsurance.primaryInsuranceIdNumber,
      groupId: manualInsurance.primaryInsuranceGroupNumber,
      memberId: '',
      groupName: '',
      paRequired: ''
    };
  };

  getCaseSummaryData = async enrollmentDetails => {
    try {
      const {
        response: {
          enrollmentRequest: {
            manualInsurance,
            medication,
            coverageSelectionId,
            enrollmentRequestId
          }
        }
      } = enrollmentDetails;

      const coverageResults = isTruthy(
        enrollmentDetails.response.coverageResults
      )
        ? enrollmentDetails.response.coverageResults
        : null;

      if (isTruthy(coverageResults)) this.epaNotSupported(coverageResults);
      const caseSummaryDetails = {};

      caseSummaryDetails.medicationDetails = await this.getMedicationDetails(
        medication
      );
      caseSummaryDetails.coverageDetails = await this.getCoverageDetails(
        manualInsurance,
        coverageSelectionId,
        coverageResults
      );

      caseSummaryDetails.patientDetails = await this.getPatientDetails(
        enrollmentDetails
      );

      await this.getCaseManager(enrollmentRequestId);
      this.setState({
        caseSummaryDetails,
        isManualInsurance: isTruthy(manualInsurance),
        enrollmentImported:
          enrollmentDetails.response.enrollmentRequest.enrollmentImported
      });
    } catch (e) {
      error(e);
    }
  };

  getCaseManager = async enrollmentRequestId => {
    let caseManager = await api.vertex.getCaseManagerByEnrollmentId(
      enrollmentRequestId
    );
    this.setState({ caseManager });
  };

  updatePriorAuth(epaInitiationAlerts, optionalCallback = () => {}) {
    api.vertex
      .getLatestEpaStatus(this.enrollmentRequestId)
      .then(result => {
        this.setState(
          {
            priorAuthorization: result.response,
            epaInitiationAlerts
          },
          () => {
            optionalCallback();
          }
        );
      })
      .catch(err => error(err));
  }

  setEpaInitiationAlerts(epaInitiationAlerts, optionalCallback = () => {}) {
    this.setState(
      {
        epaInitiationAlerts
      },
      optionalCallback()
    );
  }

  setMedicationInfo = async (selectedMedication, medications) => {
    const {
      dosageId,
      daysSupplyId,
      refills,
      specialInstructions
    } = selectedMedication;

    let matchedMedication = {};
    let drugDosage = [];
    medications.forEach(medication => {
      const maybeMatchedDosage = medication.dosages.filter(
        dosage => dosage.id === dosageId
      );

      if (isNonEmptyArray(maybeMatchedDosage)) {
        drugDosage = maybeMatchedDosage;
        matchedMedication = medication;
      }
    });

    const daysSupply = drugDosage[0].days_supplies.find(
      element => element.id === daysSupplyId
    );

    return {
      dose: drugDosage[0].description,
      refills,
      specialInstructions,
      medicationName: matchedMedication.name,
      medicationImage: require(`../../img/${matchedMedication.imagePath}`),
      supply: daysSupply.description
    };
  };

  renderPAStatusBubble = () => {
    const {
      state: { priorAuthorization, mosaicIntegration }
    } = this;

    return getPAStatus(priorAuthorization, mosaicIntegration);
  };

  async componentDidMount() {
    try {
      const enrollmentDetails = await api.vertex.getEnrollment(this.patientId);
      const {
        response: {
          priorAuthorization,
          showRenewEpa,
          enrollmentRequest: { enrollmentRequestId, mosaicIntegration }
        }
      } = enrollmentDetails;

      this.enrollmentRequestId = enrollmentRequestId;
      this.setState(
        {
          priorAuthorization,
          showRenewEpa,
          mosaicIntegration
        },
        () => this.getCaseSummaryData(enrollmentDetails)
      );
    } catch (e) {
      error(e);
    }
  }

  epaNotSupported = coverageResults => {
    if (
      isTruthy(coverageResults) &&
      isTruthy(coverageResults.results) &&
      isTruthy(coverageResults.results[0])
    ) {
      const rxInsurance = coverageResults.results[0];
      const epaNotSupported =
        isTruthy(rxInsurance) &&
        isTruthy(rxInsurance.rxinsurance) &&
        isTruthy(rxInsurance.rxinsurance.message_id === 'unknown');

      this.setState({ epaNotSupported });
    }
  };

  navigateEnrollmentPage = async () => {
    let enrollmentInformation = await api.vertex.getPatientDetails(
      this.patientId
    );

    const specialtyPharmacies = await api.vertex.getSpecialtyPharmacies();

    enrollmentInformation = this.formatEnrollmentInformation(
      enrollmentInformation,
      specialtyPharmacies
    );
    this.props.history.push('/new-case', {
      enrollmentInformation
    });
  };

  formatEnrollmentInformation = (
    enrollmentInformation,
    specialtyPharmacies
  ) => {
    return {
      ...enrollmentInformation,
      primaryContactBy: capitalize(
        enrollmentInformation.primaryContactBy,
        true
      ),
      secondaryContactBy: capitalize(
        enrollmentInformation.secondaryContactBy,
        true
      ),
      language: String(enrollmentInformation.language),
      additionalContactLanguage: String(
        enrollmentInformation.additionalContactLanguage
      ),
      pharmacyNpi:
        specialtyPharmacies.filter(
          sp => sp.npi === enrollmentInformation.pharmacyNpi
        ).length > 0
          ? enrollmentInformation.pharmacyNpi
          : ''
    };
  };

  constructor(props) {
    super(props);
    this.patientId = this.props.match.params.patientId;
    this.signingEvent = this.props.location.search;
    this.updatePriorAuth = this.updatePriorAuth.bind(this);
    this.setEpaInitiationAlerts = this.setEpaInitiationAlerts.bind(this);
    this.renderPAStatusBubble = this.renderPAStatusBubble.bind(this);
    this.toggleConfirmNewCaseModal = this.toggleConfirmNewCaseModal.bind(this);
    this.navigateEnrollmentPage = this.navigateEnrollmentPage.bind(this);
  }

  render() {
    const {
      state: { caseSummaryDetails }
    } = this;

    return isFalsey(caseSummaryDetails) ? (
      <Loader isOpen={true} />
    ) : (
      <React.Fragment>
        <NavBar />
        <div className="container mt-6 mb-2">
          <div className="row">
            <div className="main col-md-12">
              <div className="row summary">
                <CaseSummaryHeader
                  patientFirstName={
                    caseSummaryDetails.patientDetails.patientFirstName
                  }
                  patientLastName={
                    caseSummaryDetails.patientDetails.patientLastName
                  }
                  toggleConfirmNewCaseModal={this.toggleConfirmNewCaseModal}
                  confirmNewCaseModalVisible={
                    this.state.confirmNewCaseModalVisible
                  }
                />
                <CaseSummaryNotificationArea
                  items={this.state.epaInitiationAlerts}
                />
                {!this.state.enrollmentImported ? (
                  <CaseSummaryStatusSection
                    priorAuthorization={this.state.priorAuthorization}
                    mosaicIntegration={this.state.mosaicIntegration}
                    renderPAStatusBubble={this.renderPAStatusBubble}
                  />
                ) : null}
                <CardDeck className="summary-body">
                  <CaseSummaryMedicationCard
                    medicationDetails={caseSummaryDetails.medicationDetails}
                    mosaicIntegration={this.state.mosaicIntegration}
                  />
                  <CaseSummaryInsuranceCard
                    coverageDetails={caseSummaryDetails.coverageDetails}
                    enrollmentRequestId={this.enrollmentRequestId}
                    priorAuthorization={this.state.priorAuthorization}
                    updatePriorAuth={this.updatePriorAuth}
                    setEpaInitiationAlerts={this.setEpaInitiationAlerts}
                    isManualInsurance={this.state.isManualInsurance}
                    mosaicIntegration={this.state.mosaicIntegration}
                    epaNotSupported={this.state.epaNotSupported}
                    showRenewEpa={this.state.showRenewEpa}
                  />
                  <CaseSummaryPatientCard
                    patientDetails={caseSummaryDetails.patientDetails}
                    patientId={this.patientId}
                    isImportedEnrollment={this.state.enrollmentImported}
                    mosaicIntegration={this.state.mosaicIntegration}
                    caseManagerDetails={this.state.caseManager}
                  />
                </CardDeck>
              </div>
            </div>
          </div>
          <FileLibraryContainer
            enableUpload={true}
            enrollmentRequestId={this.enrollmentRequestId}
          />
          <p
            id="legalText"
            className="mt-4 text-center legal muted"
            htmlFor="Legal"
          >
            DATA SOURCES AND LIMITATIONS: The information included in this
            report is based on prescription refill data from the previous six
            months from specialty pharmacies in the limited distribution network
            used by Vertex, as well as information provided directly by patients
            to Vertex GPS. Estimations based on prescription refill activity
            assume that patients have been prescribed the recommended
            FDA-approved dose for their product and are taking the product as
            indicated. Hospital pharmacies or government entities are not
            included in the data. Patient compliance rate is calculated using
            the Proportion of Days Covered methodology as: 1-(Number of missed
            days/Total number of days of therapy in the period) x 100.
          </p>
        </div>
        <NewCaseModal
          isOpen={this.state.confirmNewCaseModalVisible}
          onNo={this.toggleConfirmNewCaseModal}
          onYes={this.navigateEnrollmentPage}
        />
      </React.Fragment>
    );
  }
}

export default CaseSummaryPage;

import React from 'react';
import { Row, Container, FormGroup } from 'reactstrap';
import { Field } from 'react-final-form';
import Wizard from '../Wizard';
import formatString from 'format-string-by-pattern';
import { zipMask, phoneMask } from '../../../constants/InputMasks';
import InputError from '../InputError';
import * as ErrorHandler from '../../../utilities/ErrorHandler';
import api from '../../../api';
import BirthDate from '../BirthDate';
import {
  validEmail,
  validPhone,
  validZip,
  composeValidators,
  required
} from '../../../utilities/Validators';

class PatientInfo extends React.Component {
  state = {
    year: null,
    month: null,
    day: null,
    specialtyPharmacies: [],
    states: []
  };
  onAddLanguageClick = () => {
    const {
      props: { form }
    } = this;
    form.change('additionalContactOtherLanguage', '');
  };
  onLanguageClick = () => {
    const {
      props: { form }
    } = this;
    form.change('otherLanguage', '');
  };
  onSameAsPatientClick = () => {
    const {
      props: { form }
    } = this;

    form.change(
      'additionalContactFirstName',
      form.getFieldState('patientFirstName').value
    );
    form.change(
      'additionalContactLastName',
      form.getFieldState('patientLastName').value
    );
    form.change(
      'additionalContactPreferredName',
      form.getFieldState('patientPreferredName').value
    );

    form.change(
      'additionalContactPhoneBy',
      form.getFieldState('primaryContactBy').value
    );
    form.change(
      'additionalContactPhone',
      form.getFieldState('primaryPhone').value
    );
    form.change(
      'additionalContactEmail',
      form.getFieldState('patientEmail').value
    );
    form.change(
      'additionalContactLanguage',
      form.getFieldState('language').value
    );
    form.change(
      'additionalContactOtherLanguage',
      form.getFieldState('otherLanguage').value
    );
  };

  async componentDidMount() {
    try {
      let specialtyPharmacies = [];
      let states = [];
      specialtyPharmacies = await api.vertex.getSpecialtyPharmacies();
      this.setState({
        specialtyPharmacies
      });
      states = await api.vertex.getStates();
      this.setState({
        states
      });
    } catch (error) {
      ErrorHandler.error(error);
    }
  }

  render() {
    return (
      <Wizard.Page>
        <h1>
          <span>Patient Information</span>
        </h1>
        <Container>
          <Row>
            <div className="col-12 col-lg-4">
              <FormGroup>
                <label>Patient First Name:</label>
                <Field
                  className="form-control form-control-sm"
                  name="patientFirstName"
                  component="input"
                  type="text"
                  placeholder="Patient First Name"
                  validate={required()}
                  maxLength={25}
                />
                <InputError name="patientFirstName" />
              </FormGroup>
            </div>
            <div className="col-12 col-lg-4">
              <FormGroup>
                <label>Patient Last Name:</label>
                <Field
                  className="form-control form-control-sm"
                  name="patientLastName"
                  component="input"
                  type="text"
                  placeholder="Patient Last Name"
                  validate={required()}
                  maxLength={25}
                />
                <InputError name="patientLastName" />
              </FormGroup>
            </div>
            <div className="col-12 col-lg-4">
              <FormGroup>
                <label>Preferred Name or Pronoun:</label>
                <Field
                  className="form-control form-control-sm"
                  name="patientPreferredName"
                  component="input"
                  type="text"
                  placeholder="Preferred Name"
                  maxLength={25}
                />
              </FormGroup>
            </div>
          </Row>
          <Row>
            <div className="col-12 col-lg-5">
              <BirthDate
                year={this.props.patientDateOfBirthYear}
                month={this.props.patientDateOfBirthMonth}
                day={this.props.patientDateOfBirthDay}
              />
            </div>
            <div className="col-12 col-lg-2">
              <label className="col-12">Patient Gender:</label>
              <Field
                className="form-control form-control-sm"
                name="patientGender"
                component="select"
                validate={required()}
              >
                <option value="">Select</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="unknown">Unknown</option>
              </Field>
              <InputError name="patientGender" />
            </div>
            <div className="col-12 col-lg-3">
              <FormGroup>
                <label>State:</label>
                <Field
                  className="form-control form-control-sm"
                  name="patientState"
                  component="select"
                  id="patientState"
                  validate={required()}
                >
                  <option value="">Select</option>
                  {this.state.states.map(patientState => (
                    <option key={patientState.code} value={patientState.code}>
                      {patientState.name}
                    </option>
                  ))}
                </Field>

                <InputError name="patientState" />
              </FormGroup>
            </div>
            <div className="col-12 col-lg-2">
              <FormGroup>
                <label>Patient Zip Code:</label>
                <Field
                  className="form-control form-control-sm"
                  name="patientZipCode"
                  component="input"
                  type="text"
                  parse={formatString(zipMask.parse)}
                  placeholder={zipMask.parse}
                  validate={composeValidators(validZip, required())}
                  format={formatString(zipMask.parse)}
                />
                <InputError name="patientZipCode" />
              </FormGroup>
            </div>
          </Row>
          <Row>
            <div className="col-12 col-lg-4">
              <label>Patient Email:</label>
              <Field
                className="form-control form-control-sm "
                name="patientEmail"
                component="input"
                type="text"
                placeholder="Patient Email"
                maxLength={100}
                validate={validEmail}
              />
              <InputError name="patientEmail" />
            </div>
          </Row>
          <Row>
            <div className="col-12 col-lg-8">
              <FormGroup>
                <Row>
                  <div className="col-12 col-lg-6">
                    <label>Primary Phone Number:</label>
                    <Row>
                      <div className="col-4">
                        <Field
                          className="form-control form-control-sm"
                          name="primaryContactBy"
                          component="select"
                          validate={required()}
                        >
                          <option value="">Select</option>
                          <option value="Mobile">Mobile</option>
                          <option value="Home">Home</option>
                        </Field>
                        <InputError name="primaryContactBy" />
                      </div>
                      <div className="col-8">
                        <Field
                          className="form-control form-control-sm"
                          name="primaryPhone"
                          component="input"
                          type="text"
                          placeholder={phoneMask.parse}
                          parse={formatString(phoneMask.parse)}
                          validate={composeValidators(required(), validPhone)}
                          maxLength={25}
                          id="primaryPhone"
                        />
                        <InputError name="primaryPhone" />
                      </div>
                    </Row>
                  </div>
                  <div className="col-12 col-lg-6">
                    <label>
                      Secondary Phone Number: <small>(Optional)</small>
                    </label>
                    <Row>
                      <div className="col-4">
                        <Field
                          className="form-control form-control-sm"
                          id="secondaryContactBy"
                          name="secondaryContactBy"
                          component="select"
                        >
                          <option value="">Select</option>
                          <option value="Mobile">Mobile</option>
                          <option value="Home">Home</option>
                        </Field>
                      </div>
                      <div className="col-8">
                        <Field
                          className="form-control form-control-sm"
                          name="secondaryPhone"
                          component="input"
                          type="text"
                          placeholder={phoneMask.parse}
                          parse={formatString(phoneMask.parse)}
                          maxLength={25}
                          validate={validPhone}
                          id="secondaryPhone"
                        />{' '}
                        <InputError name="secondaryPhone" />
                      </div>
                    </Row>
                  </div>
                </Row>
              </FormGroup>
            </div>
            <div className="col-12 col-lg-4 mt-4">
              <div className="custom-control custom-checkbox">
                <Field
                  id="isAllowedLeaveMessages"
                  className="custom-control-input"
                  name="isAllowedLeaveMessages"
                  component="input"
                  type="checkbox"
                />{' '}
                <label
                  className="custom-control-label"
                  htmlFor="isAllowedLeaveMessages"
                >
                  Ok to Leave Messages?
                </label>
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-12 col-lg-6">
              <FormGroup>
                <label>Patient's Preferred Pharmacy:</label>
                <Field
                  className="form-control form-control-sm"
                  name="pharmacyNpi"
                  component="select"
                  id="pharmacyNpi"
                  validate={required()}
                >
                  <option value="">Select</option>
                  {this.state.specialtyPharmacies.map(specialtyPharmacy => (
                    <option
                      key={specialtyPharmacy.npi}
                      value={specialtyPharmacy.npi}
                    >
                      {specialtyPharmacy.name}
                    </option>
                  ))}
                </Field>
                <InputError name="pharmacyNpi" />
              </FormGroup>
            </div>
          </Row>
          <Row>
            <div className="col-12">
              <label className="d-inline mr-3">Language Preference:</label>
              <div className="custom-control custom-radio custom-control-inline">
                <Field
                  id="languageEnglish"
                  className="custom-control-input"
                  name="language"
                  component="input"
                  type="radio"
                  onClick={this.onLanguageClick}
                  value={'1'}
                />{' '}
                <label
                  className="custom-control-label"
                  htmlFor="languageEnglish"
                >
                  English
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <Field
                  id="languageSpanish"
                  className="custom-control-input"
                  name="language"
                  component="input"
                  type="radio"
                  onClick={this.onLanguageClick}
                  value={'2'}
                />{' '}
                <label
                  className="custom-control-label"
                  htmlFor="languageSpanish"
                >
                  Spanish
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <Field
                  id="isOtherLanguage"
                  className="custom-control-input"
                  name="language"
                  component="input"
                  type="radio"
                  value={'3'}
                />{' '}
                <label
                  className="custom-control-label"
                  htmlFor="isOtherLanguage"
                >
                  Other
                </label>
              </div>
              <div className="custom-control custom-input custom-control-inline">
                <Field
                  className="form-control form-control-sm"
                  name="otherLanguage"
                  id="otherLanguage"
                  component="input"
                  type="text"
                  placeholder="Other Language"
                  maxLength={100}
                />
              </div>
            </div>
          </Row>
        </Container>
        <h1>
          <span>
            <br />
            <br />
            Additional Contact Information
          </span>
        </h1>
        <Container>
          <Row>
            <div className="col-12">
              <div className="custom-control custom-checkbox custom-control-inline">
                <Field
                  id="isPrimaryCaregiver"
                  className="custom-control-input"
                  name="isPrimaryCaregiver"
                  component="input"
                  type="checkbox"
                />{' '}
                <label
                  className="custom-control-label"
                  htmlFor="isPrimaryCaregiver"
                >
                  Primary Caregiver
                </label>
              </div>
              <div className="custom-control custom-checkbox custom-control-inline">
                <Field
                  id="isLegalGuardian"
                  className="custom-control-input"
                  name="isLegalGuardian"
                  component="input"
                  type="checkbox"
                />{' '}
                <label
                  className="custom-control-label"
                  htmlFor="isLegalGuardian"
                >
                  Legal Guardian
                </label>
              </div>
              <div className="custom-control custom-checkbox custom-control-inline">
                <Field
                  id="isAdditionalContact"
                  className="custom-control-input"
                  name="isAdditionalContact"
                  component="input"
                  type="checkbox"
                />{' '}
                <label
                  className="custom-control-label"
                  htmlFor="isAdditionalContact"
                >
                  Additional Contact
                </label>
              </div>
              <div className="custom-control custom-checkbox custom-control-inline">
                <Field
                  id="isSameAsPatient"
                  className="custom-control-input"
                  name="isSameAsPatient"
                  component="input"
                  type="checkbox"
                  onClick={this.onSameAsPatientClick}
                />{' '}
                <label
                  className="custom-control-label"
                  htmlFor="isSameAsPatient"
                >
                  Same as Patient
                </label>
                <label className="custom-control custom-control-inline">
                  Check all that apply
                </label>
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-12 col-lg-4">
              <FormGroup>
                <label>First Name:</label>
                <Field
                  className="form-control form-control-sm"
                  name="additionalContactFirstName"
                  component="input"
                  type="text"
                  validate={required()}
                  placeholder="First Name"
                  maxLength={25}
                />
                <InputError name="additionalContactFirstName" />
              </FormGroup>
            </div>
            <div className="col-12 col-lg-4">
              <FormGroup>
                <label>Middle Initial:</label>
                <Field
                  className="form-control form-control-sm"
                  name="additionalContactMiddleInitial"
                  component="input"
                  type="text"
                  placeholder="Middle Initial"
                  maxLength={25}
                />
              </FormGroup>
            </div>
            <div className="col-12 col-lg-4">
              <FormGroup>
                <label>Last Name:</label>
                <Field
                  className="form-control form-control-sm"
                  name="additionalContactLastName"
                  component="input"
                  type="text"
                  validate={required()}
                  placeholder="Last Name"
                  maxLength={25}
                />
                <InputError name="additionalContactLastName" />
              </FormGroup>
            </div>
          </Row>
          <Row>
            <div className="col-12 col-lg-4">
              <FormGroup>
                <label>Preferred Name or Pronoun:</label>
                <Field
                  className="form-control form-control-sm"
                  name="additionalContactPreferredName"
                  component="input"
                  type="text"
                  placeholder="Preferred Name"
                  maxLength={25}
                />
              </FormGroup>
            </div>
            <div className="col-12 col-lg-4">
              <FormGroup>
                <label>Relationship to Patient:</label>
                <Field
                  className="form-control form-control-sm"
                  name="additionalContactRelationshipToPatient"
                  component="input"
                  type="text"
                  placeholder="Relationship to Patient"
                  maxLength={25}
                />
              </FormGroup>
            </div>
          </Row>
          <Row>
            <div className="col-12 col-lg-4">
              <FormGroup>
                <label>Phone Number:</label>
                <Row>
                  <div className="col-4">
                    <Field
                      className="form-control form-control-sm"
                      name="additionalContactPhoneBy"
                      component="select"
                    >
                      <option value="">Select</option>
                      <option value="Mobile">Mobile</option>
                      <option value="Home">Home</option>
                    </Field>
                  </div>
                  <div className="col-6">
                    <Field
                      className="form-control form-control form-control-sm"
                      name="additionalContactPhone"
                      component="input"
                      type="text"
                      placeholder={phoneMask.parse}
                      parse={formatString(phoneMask.parse)}
                      validate={validPhone}
                      maxLength={25}
                      id="additionalContactPhone"
                    ></Field>
                    <InputError name="additionalContactPhone" />
                  </div>
                </Row>
              </FormGroup>
            </div>
            <div className="col-12 col-lg-4">
              <FormGroup>
                <label>Contact Email:</label>
                <Field
                  className="form-control form-control-sm "
                  name="additionalContactEmail"
                  component="input"
                  type="text"
                  placeholder="Additional Contact Email"
                  maxLength={100}
                  validate={validEmail}
                />
                <InputError name="additionalContactEmail" />
              </FormGroup>
            </div>
          </Row>
          <Row>
            <div className="col-12">
              <label className="d-inline mr-3">Language Preference:</label>
              <div className="custom-control custom-radio custom-control-inline">
                <Field
                  id="additionalContactLanguageEnglish"
                  className="custom-control-input"
                  name="additionalContactLanguage"
                  component="input"
                  type="radio"
                  onClick={this.onAddLanguageClick}
                  value={'1'}
                />{' '}
                <label
                  className="custom-control-label"
                  htmlFor="additionalContactLanguageEnglish"
                >
                  English
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <Field
                  id="additionalContactLanguageSpanish"
                  className="custom-control-input"
                  name="additionalContactLanguage"
                  component="input"
                  type="radio"
                  onClick={this.onAddLanguageClick}
                  value={'2'}
                />{' '}
                <label
                  className="custom-control-label"
                  htmlFor="additionalContactLanguageSpanish"
                >
                  Spanish
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <Field
                  id="additionalContactIsOtherLanguage"
                  className="custom-control-input"
                  name="additionalContactLanguage"
                  component="input"
                  type="radio"
                  value={'3'}
                />{' '}
                <label
                  className="custom-control-label"
                  htmlFor="additionalContactIsOtherLanguage"
                >
                  Other
                </label>
              </div>
              <div className="custom-control custom-input custom-control-inline">
                <Field
                  className="form-control form-control-sm"
                  name="additionalContactOtherLanguage"
                  id="additionalContactOtherLanguage"
                  component="input"
                  type="text"
                  placeholder="Other Language"
                  maxLength={100}
                />
              </div>
            </div>
          </Row>
        </Container>
      </Wizard.Page>
    );
  }
}

export default PatientInfo;

import React from 'react';
import { Button, CardText } from 'reactstrap';
import PAStatusNote from './PAStatusNote';
import { isFalsey, isTruthy } from '../../../utilities/JsHelpers';
import api from '../../../api';
import InitiateEpaButton from './InitiateEpaButton';
import classNames from 'classnames';
import { initiateEpa, renewEpa } from '../../../utilities/EpaAlerts';
import { AuthServiceCognito } from '../../../utilities/AuthServiceCognito';
import { error } from '../../../utilities/ErrorHandler';

interface IProps {
  priorAuthorization: PriorAuthorization | null;
  enrollmentRequestId: string;
  updatePriorAuth(epaInitiationAlerts: EpaInitiationAlert[]): void;
  setEpaInitiationAlerts(epaInitiationAlerts: EpaInitiationAlert[]): void;
  epaNotSupported: boolean;
  showRenewEpa: boolean;
}

type PriorAuthorization = {
  priorAuthorizationId: string;
  deadlineToReply: string | undefined | null;
  displayStatus: string;
  authorizationEndDate: string | undefined | null;
};

type EpaInitiationAlert = {
  status: string;
  icon: JSX.Element;
  alert: string;
  message: string;
};

const InitiateEpa = ({
  priorAuthorization,
  enrollmentRequestId,
  updatePriorAuth,
  setEpaInitiationAlerts,
  epaNotSupported,
  showRenewEpa
}: IProps) => {
  const navigateToPriorAuth = async () => {
    const authService = new AuthServiceCognito();

    try {
      const refreshToken = await authService.getRefreshToken();
      const uriEncodedUserName = encodeURIComponent(
        await authService.getUserName()
      );

      api.vertex.openEpaPortal(
        priorAuthorization!.priorAuthorizationId,
        refreshToken,
        uriEncodedUserName
      );
    } catch (err) {
      error(err);
    }
  };

  const renderEpaAction = () => {
    return isPriorAuthNullOrDeadlineToReplyExpired() ? (
      <InitiateEpaButton
        enrollmentRequestId={enrollmentRequestId}
        setEpaInitiationAlerts={setEpaInitiationAlerts}
        updatePriorAuth={updatePriorAuth}
        label={initiateEpa}
      />
    ) : (
      renderPAInformationOrPAClosed()
    );
  };

  const isPriorAuthNullOrDeadlineToReplyExpired = () => {
    return (
      isFalsey(priorAuthorization?.priorAuthorizationId) ||
      (isTruthy(priorAuthorization) &&
        Date.parse(priorAuthorization!.deadlineToReply!) < Date.now())
    );
  };

  const renderPAInformationOrPAClosed = () => {
    return (
      isTruthy(priorAuthorization?.priorAuthorizationId) &&
      (priorAuthorization!.displayStatus !== 'PA Closed'
        ? renderPAInformation()
        : renderPAClosed())
    );
  };

  const maybeRenderEpaExpired = () => {
    return isTruthy(priorAuthorization?.priorAuthorizationId) &&
      Date.parse(priorAuthorization!.deadlineToReply!) < Date.now() ? (
      <CardText className="note">
        Please re-initiate the ePA associated with this case, your previous
        question set has expired.
      </CardText>
    ) : null;
  };

  const renderPAInformation = () => {
    return (
      <div>
        <Button
          data-testid="pa-information-button"
          color="primary"
          className={classNames('btn-block', {
            warning: epaNotSupported
          })}
          onClick={navigateToPriorAuth}
          disabled={isTruthy(epaNotSupported)}
        >
          ePA Information
        </Button>
        {maybeRenderRenewPA()}
        {renderPAStatusNote()}
      </div>
    );
  };

  const maybeRenderRenewPA = () => {
    return isTruthy(priorAuthorization?.priorAuthorizationId) &&
      isTruthy(priorAuthorization!.authorizationEndDate) &&
      showRenewEpa ? (
      <InitiateEpaButton
        enrollmentRequestId={enrollmentRequestId}
        setEpaInitiationAlerts={setEpaInitiationAlerts}
        updatePriorAuth={updatePriorAuth}
        label={renewEpa}
      />
    ) : null;
  };

  const renderPAClosed = () => {
    return (
      <div>
        <div className="card-status status-1">
          <p>
            <strong>ePA Closed</strong>
          </p>
          {renderPAStatusNote()}
        </div>
      </div>
    );
  };

  const renderPAStatusNote = () => {
    return (
      <PAStatusNote
        epaNotSupported={epaNotSupported}
        priorAuthorization={priorAuthorization}
      />
    );
  };

  return (
    <React.Fragment>
      {renderEpaAction()}
      {maybeRenderEpaExpired()}
    </React.Fragment>
  );
};

export default InitiateEpa;

import React from 'react';
import { RouteComponentProps } from 'react-router';
import NavBar from '../parts/NavBar';
import CoverageResultsHeader from '../parts/coverageResultsParts/CoverageResultsHeader';
import EligibilityAndCoverageResultsSection from '../parts/coverageResultsParts/EligibilityAndCoverageResultsSection';
import EligibilityAndCoverageResultsNotFoundSection from '../parts/coverageResultsParts/EligibilityAndCoverageResultsNotFoundSection';
import { isTruthy, isNonEmptyArray, isFalsey } from '../../utilities/JsHelpers';
import { emptyGuid } from '../../constants/StringConstants';
import { EnrollmentInfo } from '../../model/EnrollmentInfo';
import { EligibilityResult } from '../../model/EligibilityResult';
import { EnrollmentAttempt } from '../../model/EnrollmentAttempt';

interface ICoverageResultsPageProps extends RouteComponentProps {
  location: any;
}

const CoverageResultsPage: React.FunctionComponent<ICoverageResultsPageProps> = ({
  location
}) => {
  const getDefaultCoverageSelectionName = (results: Array<any>) => {
    return isTruthy(results) && isNonEmptyArray(results) && results.length === 1
      ? results[0].rx_insurance.pbm_name
      : '';
  };

  const doResultsExist = (enrollmentAttempt: EnrollmentAttempt) => {
    if (
      isFalsey(enrollmentAttempt.eligibilityAndCoverageResponse?.result) ||
      isFalsey(
        enrollmentAttempt.eligibilityAndCoverageResponse?.result?.results
      ) ||
      enrollmentAttempt.eligibilityAndCoverageResponse?.result.tx_id ===
        emptyGuid
    ) {
      return false;
    }

    return true;
  };
  let defaultCoverage = '';
  const {
    state: { enrollmentAttempt }
  } = location;
  const resultsExist = doResultsExist(enrollmentAttempt);

  const getDefaultCoverageSelectionId = (results: Array<EligibilityResult>) => {
    return isTruthy(results) && isNonEmptyArray(results) && results.length === 1
      ? results[0].result_id
      : '';
  };

  if (resultsExist) {
    const results =
      enrollmentAttempt.eligibilityAndCoverageResponse.result.results;
    defaultCoverage = getDefaultCoverageSelectionId(results);
  }

  let defaultCoverageName = '';

  if (resultsExist) {
    const results =
      enrollmentAttempt.eligibilityAndCoverageResponse.result.results;
    defaultCoverageName = getDefaultCoverageSelectionName(results);
  }

  const [selectedCoverageId, setSelectedCoverageId] = React.useState(
    defaultCoverage
  );
  const [selectedCoverageName, setSelectedCoverageName] = React.useState(
    defaultCoverageName
  );

  const getPatientName = (enrollmentInfo: EnrollmentInfo) => {
    return `${
      isTruthy(enrollmentInfo) ? enrollmentInfo.patientFirstName : ''
    } ${isTruthy(enrollmentInfo) ? enrollmentInfo.patientLastName : ''}`;
  };

  return (
    <>
      <NavBar />
      <div className="container mt-6 mb-6">
        <div className="row">
          <div className="main col-md-12">
            <CoverageResultsHeader
              patientName={getPatientName(enrollmentAttempt.enrollmentInfo)}
              resultsExist={resultsExist}
              selectedCoverageName={selectedCoverageName}
            />
            {resultsExist === true ? (
              <EligibilityAndCoverageResultsSection
                enrollmentAttempt={enrollmentAttempt}
                selectedCoverageId={selectedCoverageId}
                setSelectedCoverageId={setSelectedCoverageId}
                setSelectedCoverageName={setSelectedCoverageName}
                location={location}
              />
            ) : (
              <EligibilityAndCoverageResultsNotFoundSection
                enrollmentAttemptId={enrollmentAttempt.enrollmentAttemptId}
                enrollmentInfo={enrollmentAttempt.enrollmentInfo}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CoverageResultsPage;

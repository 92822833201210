import React from 'react';
import * as daysOverdueConstants from '../../../constants/DaysOverdueConstants';
import {
  getIconContent,
  getLabelClass,
  getErrorMessage
} from './DaysOverdueForRefillMap';

interface IProps {
  daysOverdueForRefill: number;
  daysOverdueForRefillStatus: string;
}

const DaysOverdueForRefill = (props: IProps) => {
  const iconContent = getIconContent(props.daysOverdueForRefillStatus);
  const labelClass = getLabelClass(props.daysOverdueForRefillStatus);
  const errorMessage = getErrorMessage(
    props.daysOverdueForRefillStatus,
    props.daysOverdueForRefill
  );

  return (
    <React.Fragment>
      <div>
        <span className="card-dod-refill">
          <span className="dod-refill-label">
            {daysOverdueConstants.daysOverdueForRefillLabel}
          </span>
          <span className={`dod-refill-value ${labelClass}`}>
            {iconContent ? (
              <span className="dod-icon">{iconContent}</span>
            ) : null}
            {errorMessage}
          </span>
        </span>
      </div>
    </React.Fragment>
  );
};

export default DaysOverdueForRefill;

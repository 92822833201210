import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Router } from 'react-router-dom';
import './App.scss';
import * as serviceWorker from './serviceWorker';
import history from './utilities/History';
import ReactGA from 'react-ga';
import * as amplify from './amplify';

amplify.configure();

ReactGA.initialize(window.vertexConfig.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);

history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

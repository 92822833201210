import React from 'react';
import { Button, Col, Tooltip } from 'reactstrap';
import { CloseIcon, DownloadIcon } from 'mdi-react';
import filesize from 'filesize';
import { format } from 'date-fns';
import { isTruthy } from '../../../utilities/JsHelpers';

class FileDetail extends React.Component {
  state = {
    isTooltipDeleteOpen: false,
    isTooltipDownloadOpen: false
  };

  onClickDownload = () => {
    const {
      props: {
        file: { id: attachmentId, name: attachmentName }
      }
    } = this;

    this.props.onClickDownloadAttachment(attachmentId, attachmentName);
  };

  onDelete = () => {
    const {
      props: {
        file: { id: attachmentId }
      }
    } = this;

    this.props.onDelete(attachmentId);
  };

  toggleTooltipDelete = () => {
    this.setState({
      isTooltipDeleteOpen: !this.state.isTooltipDeleteOpen
    });
  };

  toggleTooltipDownload = () => {
    this.setState({
      isTooltipDownloadOpen: !this.state.isTooltipDownloadOpen
    });
  };

  render() {
    const { file, timestampVerbiage } = this.props;

    return (
      <React.Fragment>
        <Col className="file-list__file" xs="12">
          <div className="file-list__file__info">
            <div className="file-list__file__info__name">{file.name}</div>
            <div className="file-list__file__info__details">
              Size: {filesize(file.file_size)}&nbsp;&nbsp;&nbsp;
              {isTruthy(timestampVerbiage)
                ? timestampVerbiage
                : 'Modified'}: {format(Date.parse(file.timestamp), 'MM-DD-YY')}
            </div>
          </div>
          <div className="file-list__file__actions">
            {file.attachment_type === 'UserUploads' ? (
              <span>
                <Tooltip
                  placement="top"
                  isOpen={this.state.isTooltipDeleteOpen}
                  target={`#actionDelete${file.id}`}
                  toggle={this.toggleTooltipDelete}
                >
                  Delete
                </Tooltip>

                <Button
                  color="link"
                  size="lg"
                  aria-label="Delete"
                  id={`actionDelete${file.id}`}
                  onClick={this.onDelete}
                >
                  <CloseIcon />
                </Button>
              </span>
            ) : (
              ''
            )}
            <Tooltip
              placement="top"
              isOpen={this.state.isTooltipDownloadOpen}
              target={`#actionDownload${file.id}`}
              toggle={this.toggleTooltipDownload}
            >
              Download
            </Tooltip>
            <Button
              onClick={this.onClickDownload}
              color="link"
              size="lg"
              aria-label="Download"
              id={`actionDownload${file.id}`}
            >
              <DownloadIcon />
            </Button>
          </div>
        </Col>
      </React.Fragment>
    );
  }
}

export default FileDetail;

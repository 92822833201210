import React from 'react';
import { Button } from 'reactstrap';
import Loader from '../../parts/Loader';
import api from '../../../api';
import { epaAlertMap } from '../../../utilities/EpaAlerts';
import RenewEpaFailedModal from '../caseSummaryParts/RenewEpaFailedModal';

interface IProps {
  enrollmentRequestId: string;
  setEpaInitiationAlerts(epaInitiationAlerts: EpaInitiationAlert[]): void;
  updatePriorAuth(epaInitiationAlerts: EpaInitiationAlert[]): void;
  label: string;
}

type EpaInitiationAlert = {
  status: string;
  icon: JSX.Element;
  alert: string;
  message: string;
};

const InitiateEpaButton = ({
  enrollmentRequestId,
  setEpaInitiationAlerts,
  updatePriorAuth,
  label
}: IProps) => {
  const [isLoaderOpen, setIsLoaderOpen] = React.useState(false);
  const [
    isRenewEpaFailedModalOpen,
    setIsRenewEpaFailedModalOpen
  ] = React.useState(false);

  const processPriorAuthorization = () => {
    let epaInitiationAlerts: EpaInitiationAlert[] = [];

    api.vertex
      .initiateEpa(enrollmentRequestId)
      .then(result => {
        const {
          response: { isEpaInitiated }
        } = result;
        setIsLoaderOpen(false);

        if (isEpaInitiated) {
          epaInitiationAlerts.push(epaAlertMap.get(label)!);
          updatePriorAuth(epaInitiationAlerts);
          return;
        }

        setIsRenewEpaFailedModalOpen(true);
      })
      .catch(() => {
        epaInitiationAlerts.push(epaAlertMap.get(null)!);
        setEpaInitiationAlerts(epaInitiationAlerts);
        setIsLoaderOpen(false);
      });
  };

  const initiatePriorAuthorization = () => {
    setIsLoaderOpen(true);
    processPriorAuthorization();
  };

  const onOkClicked = () => {
    setIsRenewEpaFailedModalOpen(false);
  };

  React.useEffect(() => {
    return () => {
      setIsLoaderOpen(false);
    };
  }, []);

  return (
    <>
      <Button
        data-testid="initiate-epa-button"
        color="primary"
        className="btn-block"
        onClick={initiatePriorAuthorization}
      >
        {label}
      </Button>
      <Loader isOpen={isLoaderOpen} />
      <RenewEpaFailedModal
        isOpen={isRenewEpaFailedModalOpen}
        onOkClicked={onOkClicked}
      />
    </>
  );
};

export default InitiateEpaButton;

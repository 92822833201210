import React from 'react';
import { Button } from 'reactstrap';

class CaseSummaryHeader extends React.Component {
  showModal = () => {
    this.props.toggleConfirmNewCaseModal();
  };

  render() {
    return (
      <div className="summary-header">
        <h1>
          <span>Patient Enrollment: </span>
          {`${this.props.patientFirstName} ${this.props.patientLastName}`}
        </h1>
        <Button color="primary" onClick={this.showModal}>
          Create New Case
        </Button>
      </div>
    );
  }
}

export default CaseSummaryHeader;

import React from 'react';
import ShouldDispenseAsWrittenInput from './ShouldDispenseAsWrittenInput';

const ShouldDispenseAsWrittenRadioSelect = () => {
  return (
    <div className="col-lg-8 col-xs-12 mt-5">
      <label className="d-inline mt-2 mr-3">Dispense as written</label>
      <ShouldDispenseAsWrittenInput
        id={'ShouldDispenseAsWritten-yes'}
        value={'true'}
        text={'Yes'}
      />
      <ShouldDispenseAsWrittenInput
        id={'ShouldDispenseAsWritten-no'}
        value={'false'}
        text={'No'}
      />
    </div>
  );
};

export default ShouldDispenseAsWrittenRadioSelect;

import React from 'react';
import MutationInput from './MutationInput';

const mutationsDbLink = 'https://vertextreatmentshcp.com';

const MutationsForm = () => {
  return (
    <>
      <h3 className="col-12 mt-3">Specify Patient's Indicated Mutation(s):</h3>
      <MutationInput label={'Mutation 1'} name={'mutation1'} maxLength={25} />
      <MutationInput
        label={'Mutation 2'}
        name={'mutation2'}
        maxLength={25}
      />{' '}
      <span className={'col-xs-12 col-lg-12 mt-4 text-center'}>
        Find more information regarding indicated mutations{' '}
        <a target="_blank" rel="noopener noreferrer" href={mutationsDbLink}>
          {mutationsDbLink}
        </a>
      </span>
    </>
  );
};

export default MutationsForm;

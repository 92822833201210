import { isTruthy, isArray, isNonEmptyArray } from '../utilities/JsHelpers';

export const requiredError = 'Required';
export const daySupplyRequiredError = 'Days supply is required';
export const dosageIsRequiredError = 'Dosage selection is required';
export const acknowledgementRequiredError =
  'Please Acknowledge consent before proceeding';

export const invalidError = 'Invalid';
export const emailInvalidError = 'Invalid email';
export const phoneInvalidError = 'Invalid phone';
export const npiInvalidError = 'Invalid NPI';

export const required = (errorMessage: string = requiredError) => (
  value: any
) =>
  isArray(value)
    ? isNonEmptyArray(value)
      ? undefined
      : errorMessage
    : isTruthy(value)
    ? undefined
    : errorMessage;

export const validEmail = (email: string) => {
  return isTruthy(email)
    ? email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      ? undefined
      : emailInvalidError
    : undefined;
};

export const validNpi = (value: string) =>
  value.match(/\b\d{10}\b/i) ? undefined : npiInvalidError;

export const validPhone = (value: string) =>
  isTruthy(value)
    ? value.match(/^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/i)
      ? undefined
      : phoneInvalidError
    : undefined;

export const validNumberRange = (min: number, max: number) => (value: any) =>
  !isNaN(value) && value >= min && value <= max
    ? undefined
    : `Must be a number between ${min} and ${max}`;

export const validZip = (value: string) =>
  isTruthy(value)
    ? value.match(/^\d{5}(?:[-\s]\d{4})?$/i)
      ? undefined
      : invalidError
    : undefined;

export const composeValidators = (
  ...validators: {
    (value: string): string | undefined;
  }[]
) => (value: string) =>
  validators.reduce(
    (error: string | undefined, validator) => error || validator(value),
    undefined
  );

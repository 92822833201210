import React from 'react';
import { ModalBody } from 'reactstrap';

class TermsModalBody extends React.Component {
  render() {
    return (
      <ModalBody>
        <strong>VERTEX PORTAL TERMS OF USE</strong>
        <br />
        <br />
        <em>
          These Terms of Use contain an exclusive jurisdiction clause, an
          arbitration clause, indemnification obligations, disclaimers of
          warranties and limitations of liability. Please review these Terms of
          Use in their entirety.
        </em>
        <br />
        <br />
        THIS IS AN AGREEMENT BETWEEN YOU ("Licensee", “you”, “your”) AND
        CAREFORM LLC, AND CAREFORM LLC (collectively, "Careform LLC", “we”,
        “our”). BY USING THE CAREFORM LLC WEB PORTAL SOFTWARE AND/OR SERVICES
        (“PRODUCT”) AND/OR ACKNOWLEDGING ANY ACCEPTANCE OF THESE TERMS OF USE,
        YOU ACCEPT AND AGREE TO THESE TERMS OF USE. YOU ALSO ACKNOWLEDGE AND
        AGREE THAT CAREFORM LLC HAS BEEN ENGAGED BY VERTEX PHARMACEUTICALS
        INCORPORATED OR ITS AFFILIATES OR SUBSIDIARIES (collectively, “Vertex”)
        TO PROVIDE THE PRODUCT FOR VERTEX’S ADMINISTRATION AND OPERATION OF
        VERTEX GUIDANCE & PATIENT SUPPORT (“Vertex GPS”). YOU WILL INTERACT WITH
        VERTEX GPS THROUGH YOUR USE OF THE PRODUCT.
        <br />
        <br />
        Careform LLC reserves the right, at our discretion, to change, modify,
        add, or remove portions of these Terms of Use at any time. Please check
        these Terms of Use periodically for changes. Your continued use of the
        Product after the posting of changes to these Terms of Use will mean you
        agree to abide by those changes. Any amendment to these Terms of Use by
        you must be agreed to by Careform LLC in writing.
        <br />
        <br />
        Program Enrollment/Product License
        <br />
        <br />
        <strong>1. ENROLLMENT IN PROGRAM</strong>
        <br />
        <br />
        By using this Product, including the Software, and Hardware defined
        below, Licensee enrolls in the Vertex Portal Program (the "Program").
        While enrolled in the Program, Licensee is granted a limited license, as
        described below, to use the Product solely for the purpose of tracking
        Vertex Pharmaceuticals Incorporated products and inventory and for
        certain benefit investigation and fulfillment services, if applicable.
        The Product cannot, and Licensee agrees will not, be used for tracking
        other pharmaceutical products or otherwise as an inventory system. There
        shall be NO payment due to Careform LLC from Licensee under these Terms
        of Use including for usage of the Product. Enrollment in the Program
        shall be ongoing until identified as terminated (for any reason or no
        reason) by Careform LLC or Vertex or its affiliates or subsidiaries
        (“Vertex”).
        <br />
        <br />
        <strong>2. GRANT OF LICENSE</strong>
        <br />
        <br />
        Licensee is granted a limited license to use the Product, which includes
        certain Software and Hardware, as defined below, solely in connection
        with the Program as long as Licensee remains a participant in the
        Program. Careform LLC reserves all rights not expressly granted to
        Licensee.
        <br />
        <br />
        (i) Software - Subject to the foregoing, Careform LLC grants Licensee a
        non-exclusive, non-assignable, and non-transferable license to use
        Careform LLC' software program(s) as provided through the Product and
        its accompanying documentation (all hereinafter termed the "Software")
        subject to the terms herein. Careform LLC may create, from time to time,
        updated versions of the Software. Careform LLC reserves the right to
        make changes to or improvements in any aspect of the Software at any
        time without prior notice to Licensee and without an obligation to
        supply such changed and/or improved Software to Licensee.
        <br />
        <br />
        (ii) Hardware - Subject to the foregoing, Careform LLC grants Licensee a
        non-assignable and non-transferable license to use any Careform LLC
        provided scanners (RFID, barcode or otherwise) (the "Hardware") for use
        with the Software during Licensee's participation in the Program.
        <br />
        <br />
        <strong>3. OWNERSHIP OF PRODUCT; DATA</strong>
        <br />
        <br />
        The Product, including the Software accessible through the Product, and
        all intellectual property rights included in or associated with the
        Product, including but not limited to patents, copyrights, trademarks
        and service marks, are owned or licensed by Careform LLC, and all right,
        title and interest in and to the Product remains with Careform LLC or
        its licensors. Any content supplied to Careform, LLC by Vertex and
        incorporated into the Product by Careform, LLC without modification,
        which content is created solely by Vertex without the assistance or
        input of Careform LLC and without reference to any materials or other
        information provided or owned by Careform LLC, remains subject to Vertex
        copyright and is used with Vertex’s permission. Except as ordinarily
        occurs when a content is downloaded to your computer in the normal
        course of operations, material from the Product may not be copied,
        distributed, re-published, uploaded, posted, modified, reverse
        engineered, or transmitted in any manner without our prior written
        consent. Certain content may be licensed from third parties and all such
        third-party content and intellectual property rights related to such
        content belong to the respective third parties. In any event, you may
        not remove any copyright, trademark or other intellectual property or
        proprietary notice or legend contained on the Product or in the content.
        <br />
        <br />
        <strong>4. ADDITIONAL TERMS</strong>
        <br />
        <br />
        If Licensee is accessing these Terms of Use from an administrator
        account, then Licensee hereby represents and warrants that Licensee is
        acting on behalf of the [clinic] entity employing/engaging Licensee, and
        that such entity is bound by all of the terms set forth herein,
        including but not limited to the Additional Terms set forth in Exhibit A
        below.
        <br />
        <br />
        <strong>5. USE RESTRICTIONS</strong>
        <br />
        <br />
        Licensee may not distribute copies of the Software to others. Licensee
        may not modify, adapt, translate, reverse engineer, decompile,
        disassemble, or create derivative works of the Product without the prior
        written consent of Careform LLC. Licensee is only permitted to use the
        Product as specified herein.
        <br />
        <br />
        <strong>6. ASSIGNMENT AND TRANSFER RESTRICTIONS</strong>
        <br />
        <br />
        The Product is licensed only to the Licensee and may not be transferred
        or assigned to anyone without the prior written agreement of Careform
        LLC. Any authorized transferee or assignee of the Product shall be bound
        by the terms and conditions of this Agreement. In no event may Licensee
        transfer, assign, rent, lease, sell, or otherwise dispose of Product, in
        part or in whole, on a temporary or permanent basis except as expressly
        provided herein.
        <br />
        <br />
        <strong>7. LICENSEE COMPLIANCE; INDEMNITY</strong>
        <br />
        <br />
        Licensee represents that all of Licensee's operations within the Program
        are in full compliance with all applicable Federal, State, and Local
        laws and regulations, including those required by HIPAA. Licensee shall
        be solely responsible for the supervision, management, and control of
        Licensee's use of the Product, and related products and documentation.
        ALL INVENTORY INFORMATION, PATIENT AND/OR USER INFORMATION IN THE
        PRODUCT IS PROVIDED BY AND THROUGH LICENSEE AND CAREFORM LLC SHALL HAVE
        NO LIABILITY FOR ANY SUCH INVENTORY INFORMATION (INCLUIDING ALL BAR CODE
        INFORMATION) AND/OR THE ACCURACCY OR INACCURACY OF SUCH INFORMATION.
        Licensee hereby indemnifies and holds harmless Careform LLC, Vertex, and
        their respective affiliates, officers, employees and agents (the
        “Indemnified Parties”) from and against any loss, liability, damages,
        costs, or expenses suffered or incurred by the Indemnified Parties at
        any time as a result of any claim, action, or proceeding arising out of
        or relating to: (i) Licensee's use, operation, or implementation of the
        Product; (ii) Licensee’s breach of any provision of these Terms of Use;
        (iii) medical care provided (or any failure or omission to provide care)
        to any patient in your system even if the Product is used in connection
        with such care; and/or (iv) any negligence (gross or otherwise) or
        willful misconduct of Licensee.
        <br />
        <br />
        <strong>8. TERMINATION</strong>
        <br />
        <br />
        These Terms of Use are effective until terminated. Upon termination, the
        Licensee shall cease all use of the Product. Upon termination of these
        Terms of Use, Licensee shall additionally return the Hardware to
        Careform LLC within five business days. Careform LLC reserves the right
        to send an agent to the place of business of the Licensee to retrieve
        the Hardware if it is not returned within five business days of
        termination of these Terms of Use. Careform LLC will use commercially
        reasonable efforts to retain data associated with the Licensee for at
        least fifteen (15) calendar days following termination of the agreement.
        The provisions of Sections 3, 7, 8, 12, 14, 16, 17 and 18 shall survive
        termination. In addition to the termination rights specified above:
        <br />
        <br />
        (i) Careform LLC shall have the right to terminate in its sole
        discretion this Agreement at any time upon fifteen calendar days advance
        written notice to Licensee.
        <br />
        <br />
        (ii) These Terms of Use shall automatically and immediately terminate
        upon cessation of Licensee's participation in the Program for a period
        of consecutive 7 months.
        <br />
        <br />
        (iii) These Terms of Use may be terminated at the sole discretion of
        Careform LLC upon breach of any of the terms by Licensee.
        <br />
        <br />
        <strong>9. SUPPORT</strong>
        <br />
        <br />
        Careform LLC will use commercially reasonable efforts to provide set-up,
        troubleshooting, and maintenance service associated with the Product.
        Upon contact of Careform LLC by the Licensee regarding a service issue
        via the provided contact information, Careform LLC will use commercially
        reasonable efforts to respond to Licensee's inquiry to develop an action
        plan for addressing the service issue within three business days.
        <br />
        <br />
        <strong>10. NOTICE AND CONSENT TO RECORDING</strong>
        <br />
        <br />
        If and to the extent the Product includes benefit investigation services
        provided by Careform LLC, then Licensee acknowledges and agrees that in
        connection with such benefit investigation services, it will be
        necessary for Careform LLC to monitor business-related telephone calls
        and retrieve recorded business-related conversations as necessary. The
        Careform LLC monitored lines will be used as a training vehicle and to
        ensure accuracy, quality control and consistency as a normal and regular
        business practice. The recordings will be used to clarify any
        discrepancies, resolve any disputes pertaining to a particular
        business-related telephone conversation and to address benefit
        investigation issues.
        <br />
        <br />
        <strong>11. EXPORT LAW</strong>. Licensee may not export all or any
        portion of the Product in violation of applicable export laws and
        regulations.
        <br />
        <br />
        <strong>12. CONFIDENTIALITY</strong>
        <br />
        <br />
        Licensee understands and acknowledges that the Product comprises
        valuable assets and trade secrets of Careform LLC in which Careform LLC
        claims exclusive proprietary rights and interest. Without prior written
        consent from Careform LLC, Licensee agrees not to, and will not permit
        its employees or agents or independent contractors to use, disclose,
        provide, or otherwise make available any non-public or confidential
        materials, documents, specifications, computer screens, computer screen
        layout, computer screen fields, computer screen input and output fields,
        database schema, computer code or the like (no matter in what media or
        form it is embodied) that is received from Careform LLC or Careform LLC'
        agent or independent contractors to any person, other than Licensee's
        employees or agents or its contractor(s) having a bona fide need for
        access. Licensee shall have no obligation of confidentiality with regard
        to information which is:
        <br />
        <br />
        (i) already known to Licensee; or
        <br />
        <br />
        (ii) received by Licensee without confidentiality obligations or
        restrictions on use; or
        <br />
        <br />
        (iii) independently developed by Licensee without access to such
        information; or
        <br />
        <br />
        (iv) required to be disclosed pursuant to any statutory or regulatory
        provision or court order; or
        <br />
        <br />
        (v) publicly available information. The phrase "publicly available"
        shall mean readily accessible to the public in a written publication,
        and shall not include information which is only available by a
        substantial searching of the published literature, and information the
        substance of which must be pieced together from a plurality of different
        publications and sources.
        <br />
        <br />
        <strong>13. U.S. GOVERNMENT RESTRICTED RIGHTS</strong>
        <br />
        <br />
        The Software and Product are provided with RESTRICTED RIGHTS. Use,
        duplication, or disclosure by the Government is subject to restrictions
        set forth in FAR Sections 52-227-14 and 52-227-19 or DFARS Section
        52.227-7013(C)(1)(ii), as applicable. Contractor/manufacturer is:
        ConnectiveRx, 6000 Park Lane, Pittsburgh, PA 15275.
        <br />
        <br />
        <strong>14. DISCLAIMERS OF WARRANTY AND LIABILITY</strong>
        <br />
        <br />
        Licensee expressly acknowledges and agrees that use of the Product is at
        Licensee's sole risk. The PRODUCT IS provided "AS IS" and without
        warranty of any kind and CAREFORM LLC EXPRESSLY DISCLAIMS ALL
        WARRANTIES, EXPRESS AND IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
        IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, NON-INFRINGEMENT AND
        FITNESS FOR A PARTICULAR PURPOSE. CAREFORM LLC DOES NOT WARRANT THAT THE
        FUNCTIONS CONTAINED IN THE PRODUCT WILL MEET LICENSEE'S REQUIREMENTS, OR
        THAT THE OPERATION AND RESULTS OF THE PRODUCT WILL BE UNINTERRUPTED OR
        ERROR-FREE, OR THAT DEFECTS IN THE PRODUCT WILL BE CORRECTED. NO ORAL OR
        WRITTEN INFORMATION OR ADVICE GIVEN BY CAREFORM LLC OR ITS AUTHORIZED
        REPRESENTATIVE SHALL CREATE A WARRANTY OR IN ANY WAY INCREASE THE SCOPE
        OF THIS WARRANTY.
        <br />
        <br />
        Moreover, CAREFORM LLC DOES NOT WARRANT AND EXPRESSLY DISCLAIMS ANY
        WARRANTIES THAT THE EXERCISE OF LICENSE RIGHTS PURSUANT TO THIS
        AGREEMENT WILL NOT INFRINGE ANY CURRENT OR FUTURE INTELLECTUAL PROPERTY
        RIGHTS OWNED BY PERSONS OTHER THAN CAREFORM LLC. Careform LLC makes no
        representation regarding the possibility of any such infringement by use
        of the Product, either alone or when combined with products other than
        the Product. The parties hereby agree that Careform LLC has no duty to
        investigate or to warn Licensee of any such possibility of infringement
        by use of the Product when used alone or when combined with entities
        other than the Product.
        <br />
        <br />
        CAREFORM LLC AND VERTEX AND THEIR RESPECTIVE AFFILIATES, DIRECTORS,
        OFFICERS, EMPLOYEES AND AGENTS, WILL NOT BE LIABLE TO LICENSEE FOR ANY
        INCIDENTAL, INDIRECT, SPECIAL, OR CONSEQUENTIAL DAMAGES (INCLUDING
        DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF
        BUSINESS INFORMATION, AND THE LIKE) ARISING OUT OF THE USE, MISUSE OR
        INABILITY TO USE THE PRODUCT, EVEN IF SUCH PARTY HAS BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES.
        <br />
        <br />
        LICENSEE UNDERSTANDS THAT ANY SERVICES (INCLUDING SUPPORT) AND OTHER
        MATERIALS ARE PROVIDED ON AN “AS IS” BASIS, WITHOUT ANY WARRANTY OF ANY
        KIND. LICENSEE WILL REVIEW, MODIFY AND VALIDATE ALL INFORMATION FOR
        CLINICAL AND OTHER CORRECTNESS AND APPROPRIATENESS IN YOUR ENVIRONMENT
        BEFORE ANY USE. CAREFORM LLC AND VERTEX AND ALL OTHERS ASSOCIATED IN ANY
        WAY WITH THE PRODUCTS HAVE NO RESPONSIBILITY FOR MEDICAL CARE PROVIDED
        (OR ANY FAILURE OR OMISSION TO PROVIDE CARE) TO ANY PATIENT IN YOUR
        SYSTEM EVEN IF SUCH PRODUCT IS USED IN CONNECTION WITH SUCH CARE, AND
        INSTEAD LICENSEE WILL BE SOLELY RESPONSIBLE FOR SUCH CARE. LICENSEE
        HEREBY AGREES TO WAIVE ANY CLAIMS YOU MAY HAVE AGAINST CAREFORM LLC AND
        VERTEX AND ALL OTHERS ASSOCIATED WITH THE PRODUCTS WITH RESPECT THERETO.
        CAREFORM LLC MAY REQUIRE LICENSEE TO AGREE TO ADDITIONAL TERMS IN ORDER
        TO ACCESS ADDITIONAL FUNCTIONALITY IN THE PRODUCTS.
        <br />
        <br />
        <strong>15. PROHIBITED ACTIVITIES</strong>
        <br />
        <br />
        Licensee may not use any device, software or routine, including but not
        limited to any viruses, trojan horses, worms, time bombs or other
        malicious code, intended to damage or interfere with the proper working
        of the Products or to surreptitiously intercept or expropriate any
        system, data or information from the Products. Licensee may not take any
        action which imposes an unreasonable or disproportionately large load on
        our infrastructure, including but not limited to denial of service
        attacks, "spam" or any other such unsolicited overload technique.
        <br />
        <br />
        <strong>16. GOVERNING LAW, ARBITRATION AND JURISDICTION</strong>
        <br />
        <br />
        You recognize that, although the Internet can be accessed from anywhere
        in the world, the Software is located in the Commonwealth of
        Pennsylvania, United States of America, and that when you access the
        Software, you are doing so in the Commonwealth of Pennsylvania, United
        States of America. These Terms of Use shall be governed by and construed
        in accordance with the internal substantive laws of the Commonwealth of
        Pennsylvania without giving effect to any principles of conflict of law
        and the federal laws of the United States of America. ANY CONTROVERSY OR
        CLAIM ARISING OUT OF OR RELATING TO THESE TERMS OF USE, OR THE BREACH
        THEREOF, BROUGHT BY YOU SHALL BE SETTLED BY ARBITRATION ADMINISTERED BY
        THE AMERICAN ARBITRATION ASSOCIATION UNDER ITS COMMERCIAL ARBITRATION
        RULES, AND JUDGMENT ON THE AWARD RENDERED BY THE ARBITRATOR(S) MAY BE
        ENTERED IN ANY COURT HAVING JURISDICTION THEREOF. LICENSEE FURTHER
        AGREES THAT ANY ARBITRATION ACTION BROUGHT BY YOU ARISING OUT OF OR
        RELATING TO THESE TERMS OF USE OR THE PRODUCT SHALL BE FILED ONLY IN
        ALLEGHENY COUNTY, PENNSYLVANIA, AND YOU HEREBY CONSENT AND SUBMIT TO THE
        PERSONAL JURISDICTION AND VENUE IN ALLEGHENY COUNTY, PENNSYLVANIA.
        <br />
        <br />
        <strong>17. MISCELLANEOUS</strong>
        <br />
        <br />
        Headings are for reference purposes only. Careform LLC failure to
        exercise or enforce any right or provision of the Terms of Use shall not
        constitute a waiver of such right or provision. If any provision of
        these Terms of Use shall be unlawful, void, or for any reason
        unenforceable, then that provision shall be deemed severable from these
        Terms of Use and shall not affect the validity and enforceability of any
        remaining provisions. Recognizing the global nature of the Internet, you
        agree to comply with all local rules regarding online conduct and
        acceptable materials. Specifically, you agree to comply with all
        applicable laws regarding the transmission of technical or other data
        exported from the United States or the country in which you reside.
        These Terms of Use, and any web pages or other documents incorporated by
        reference, set forth the entire understanding and agreement between us
        with respect to the subject matter hereof.
        <br />
        <br />
        <strong>18. CONTACT</strong>
        <br />
        <br />
        Should Licensee have any questions concerning this Agreement, or if
        Licensee desires to contact Careform LLC for any reason, please contact
        us in writing:
        <br />
        <br />
        ConnectiveRx
        <br />
        6000 Park Lane
        <br />
        Pittsburgh, PA 15275
        <br />
        <br />
        Licensee should print a copy of these Terms of Use for your records.
        Please note, however, that these Terms of Use may be updated and amended
        by us from time to time as provided herein.
        <br />
        <br />
        June 4, 2020
        <br />
        <br />
        <h4 className="text-center">EXHIBIT A</h4>
        <h2 className="text-center">
          <strong>ADDITIONAL TERMS</strong>
        </h2>
        <br />
        <br />
        <strong>A. BENEFIT INVESTIGATION</strong>
        <br />
        <br />
        If and to the extent the Product includes benefit investigation services
        provided by Careform LLC, the Licensee and the [clinic] entity
        employing/engaging Licensee hereby authorize Careform LLC to act as an
        agent of Licensee and entity in conducting such benefit investigation
        services, including but not limited to contacting provider offices on
        behalf of Licensee and entity to confirm applicable patient eligibility.
        <br />
        <br />
        <strong>B. ELECTRONIC SIGNATURES</strong>
        <br />
        <br />
        To the extent applicable, you will comply with, U.S. 21 C.F.R. Part 11,
        and the Health Insurance Portability and Accountability Act (the
        “HIPAA”), including without limitation, preventing unauthorized access
        to the data as well as the accounts containing eSignature capabilities
        by maintaining physical security of the computers and ensuring that you
        and your personnel maintain the confidentiality of their passwords, and
        to allow only those individuals authorized and qualified to sign
        prescriptions to have access to accounts with eSignature capabilities
        and to have procedures and safeguards in place to prevent unauthorized
        access to an eSignature enabled account. You will also develop and
        implement policies and procedures to initially at the time of employment
        or at the time such person is granted access to accounts with eSignature
        capabilities, and periodically thereafter, to verify such person’s
        identity, to determine that such persons have the appropriate training
        and education to complete the tasks related to such accounts, to review
        password assignments and usage, and to conduct authority checks to
        verify that only authorized persons can use, access, sign, or alter a
        record within accounts with eSignature capabilities, and will also
        enforce any violation of policies regarding access to accounts with
        eSignature capabilities as well as the use of eSignatures. You shall
        also comply with our instructions for data entry into the system, as
        provided by us from time to time through the Product (including any
        third party electronic signature functionality we utilize, such as
        DocuSign). You will also certify to the Federal Food and Drug
        Administration (“FDA”) that the electronic signatures of your personnel
        using the Product are the legally binding equivalent of handwritten
        signatures, and that such personnel attest to the accuracy and
        completeness of the data entered. You also agree to ensure access to
        your source records and documentation supporting 21 CFR Part 11
        compliance, during and after the term of this Agreement, by Careform LLC
        representatives and/or FDA or other government entities for auditing
        purposes.
      </ModalBody>
    );
  }
}

export default TermsModalBody;

import React from 'react';
import { Col, Label, Input, FormGroup, Button } from 'reactstrap';
import NavBar from '../parts/NavBar';

class Verify extends React.Component {
  render() {
    return (
      <div>
        <NavBar />
        <div className="container mt-6 mb-6">
          <div className="row">
            <div className="main col-md-9">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-xs-12">
                  <div className="card mb-3">
                    <div className="card-body">
                      <h5 className="card-title">Card Title</h5>
                      <p className="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <FormGroup tag="fieldset" row>
                <legend className="col-form-label col-sm-2">
                  Radio Buttons
                </legend>
                <Col sm={10}>
                  <FormGroup className="verify-radio" check>
                    <Label check>
                      <Input
                        type="radio"
                        name="radio2"
                        value="option1"
                        checked={true}
                      />{' '}
                      Option one is this and that—be sure to include why it's
                      great
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="radio2" /> Option two can be
                      something else and selecting it will deselect option one
                    </Label>
                  </FormGroup>
                  <FormGroup check disabled>
                    <Label check>
                      <Input type="radio" name="radio2" disabled /> Option three
                      is disabled
                    </Label>
                  </FormGroup>
                </Col>
              </FormGroup>
              <Button>Submit</Button>
            </div>
            <div className="sidebar col-3">
              <h2>Carson, Bill D.</h2>
              <h3>Case Details</h3>
              <dl>
                <dt>Gender</dt>
                <dd>Male</dd>
              </dl>
              <dl>
                <dt>DOB</dt>
                <dd>09-04-1988</dd>
              </dl>
              <hr />
              <dl>
                <dt>Status</dt>
                <dd>Open</dd>
              </dl>
              <dl>
                <dt>PMB Case ID</dt>
                <dd>1234567890</dd>
              </dl>
              <dl>
                <dt>Reply By</dt>
                <dd>06/26/2018 1:00</dd>
              </dl>
              <hr />
              <dl>
                <dt>Drug</dt>
                <dd>PROVASIUM® 20mg Tablet</dd>
              </dl>
              <hr />
              <h3>Attach Document</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Verify;

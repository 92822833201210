import React from 'react';
import { isFalsey } from '../../../utilities/JsHelpers';
import { withRouter, RouteComponentProps } from 'react-router';
import { EnrollmentInfo } from '../../../model/EnrollmentInfo';
import ConfirmationDialogue from '../ConfirmationDialogue';
import { IPinPageStateParams } from '../../pages/PinPage';

interface ICoverageResultsNextButtonProps extends RouteComponentProps {
  resultsExist: boolean;
  enrollmentInfo: EnrollmentInfo;
  coverageSelectionId: string;
  enrollmentAttemptId: string;
}

interface ICoverageResultsNextButtonState {
  showAcceptShareModal: boolean;
}

const exportPermissionText =
  'I acknowledge/attest that coverage results in this portal constitute the patient’s record. I am willing to share the coverage results from this record with Vertex Guidance & Patient Support for program management purposes.';
const blankHeader = '';

class CoverageResultsNextButton extends React.Component<
  ICoverageResultsNextButtonProps,
  ICoverageResultsNextButtonState
> {
  constructor(props: ICoverageResultsNextButtonProps) {
    super(props);
    this.state = {
      showAcceptShareModal: false
    };
  }

  redirectToManualProcessPage = () => {
    const {
      enrollmentInfo: { patientEmail, prescriberId },
      enrollmentAttemptId,
      history
    } = this.props;

    history.replace('/manual-process', {
      patientEmail,
      enrollmentAttemptId,
      prescriberId
    });
  };

  maybeShowAcceptShareModal = () => {
    const { resultsExist } = this.props;
    if (!resultsExist) {
      this.redirectToManualProcessPage();
    } else {
      this.showAcceptShareModal();
    }
  };

  showAcceptShareModal = () => {
    this.setState({ showAcceptShareModal: true });
  };

  redirectToPinEntry = (canShareBenefitsInfo: boolean) => {
    const {
      enrollmentInfo: { patientEmail, prescriberId },
      enrollmentAttemptId,
      coverageSelectionId,
      history
    } = this.props;

    const pinPageState: IPinPageStateParams = {
      enrollmentAttemptId,
      prescriberId,
      patientEmail,
      coverageSelectionId,
      canShareBenefitsInfo,
      updateEnrollmentInfo: null
    };

    history.replace({
      pathname: `/Enrollment/${enrollmentAttemptId}/SignaturePin`,
      state: pinPageState
    });
  };

  render() {
    const { resultsExist, coverageSelectionId } = this.props;
    const { showAcceptShareModal } = this.state;
    return (
      <>
        {showAcceptShareModal && (
          <ConfirmationDialogue
            headerText={blankHeader}
            bodyText={exportPermissionText}
            confirmText="Yes"
            denyText="No"
            onSelection={this.redirectToPinEntry}
          />
        )}
        <div className="buttons col-12 text-right">
          <button
            className="btn btn-primary"
            type="submit"
            disabled={resultsExist && isFalsey(coverageSelectionId)}
            onClick={this.maybeShowAcceptShareModal}
          >
            Next
          </button>
        </div>
      </>
    );
  }
}

export default withRouter(CoverageResultsNextButton);
